import React from "react";
import FolderContainer from "./FolderContainer";
import {
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {useSelector} from "react-redux";

const AccountingFolderContainer = ({children}) => {
	const history = useHistory();
	const location = useLocation();

	const user = useSelector(state => state.current.user);

	const treatClient = user.accountType === "client";
	if (treatClient) return <FolderContainer>{children}</FolderContainer>;
	return (
		<FolderContainer
			tabs={["Billing", "Payroll", "Adjustments"]}
			onTab={v =>
				history.push(
					`/accounting/${
						v === "Billing"
							? "billing"
							: v === "Payroll"
							? "payroll"
							: "adjustment"
					}`
				)
			}
			selectedTab={
				location.pathname.includes("payroll")
					? "Payroll"
					: location.pathname.includes("billing")
					? "Billing"
					: "Adjustments"
			}
		>
			{children}
		</FolderContainer>
	);
};

export default AccountingFolderContainer;
