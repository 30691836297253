import React from "react";
import { Button, Form, Grid, Image, Message } from "semantic-ui-react";
import Logo from "../../assets/logos/full.png";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../../Theme.js";

const SignupSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().required("Required"),
});
class Login extends React.Component {
  render() {
    return (
      <Formik
        validationSchema={SignupSchema}
        initialValues={{ password: "", confirmPassword: "" }}
        onSubmit={(values, actions) => {
          if (!values.code) {
            actions.setSubmitting(false);
            actions.setFieldError(
              "general",
              "Enter the code you recieved in your text message"
            );
          } else if (values.password !== values.confirmPassword) {
            actions.setSubmitting(false);
            actions.setFieldError("general", "Passwords must match.");
          } else
            axios
              .patch(`${process.env.REACT_APP_API_URL}/root/password`, {
                password: values.password,
                code: values.code,
              })
              .then((res) => {
                actions.setSubmitting(false);

                alert("Your password has been reset");
                this.props.history.push("/");
              })
              .catch((err) => {
                actions.setSubmitting(false);
                console.error(err);
                actions.setFieldError("general", "Please Try again");
              });
        }}
        render={(props) => (
          <Grid
            centered
            textAlign="center"
            style={{ height: "100vh", backgroundColor: "rgba(0,0,0,.7)" }}
            verticalAlign="middle"
          >
            <Grid.Column
              style={{
                maxWidth: 450,
                backgroundColor: "white",
                borderRadius: 10,
                boxShadow: "0 0 30px rgba(0,0,0,0.6)",
              }}
            >
              <Image
                style={{
                  margin: "0vh auto 1vh auto",
                  height: 200,
                }}
                src={Logo}
              />
              <Form size="large" onSubmit={props.handleSubmit}>
                <Message
                  style={{
                    border: "2px solid rgba(0,0,0,0.6)",
                    backgroundColor: Theme.PAGE_BACKGROUND,
                    marginBottom: "1vh",
                  }}
                >
                  <Form.Input
                    name="code"
                    autocomplete="off"
                    error={
                      props.errors.code && props.touched.code
                        ? props.errors.code
                        : false
                    }
                    onChange={props.handleChange}
                    onBlur={() => props.setFieldTouched("code")}
                    style={{ color: "blue" }}
                    fluid
                    type="code"
                    icon="talk"
                    iconPosition="left"
                    placeholder="Verification Code"
                  />
                  <Form.Input
                    name="password"
                    autocomplete="off"
                    error={
                      props.errors.password && props.touched.password
                        ? props.errors.password
                        : false
                    }
                    onChange={props.handleChange}
                    onBlur={() => props.setFieldTouched("firstName")}
                    style={{ color: "blue" }}
                    fluid
                    type="password"
                    icon="lock"
                    iconPosition="left"
                    placeholder="New Password"
                  />
                  <Form.Input
                    name="confirmPassword"
                    autocomplete="off"
                    error={
                      props.errors.confirmPassword &&
                      props.touched.confirmPassword
                        ? props.errors.confirmPassword
                        : false
                    }
                    onChange={props.handleChange}
                    onBlur={() => props.setFieldTouched("firstName")}
                    style={{ color: "blue" }}
                    fluid
                    type="password"
                    icon="lock"
                    iconPosition="left"
                    placeholder="Confirm Password"
                  />
                  {props.errors.general && (
                    <div
                      style={{
                        color: "blue",
                        margin: "-1vh auto .5vh auto",
                        textAlign: "center",
                      }}
                    >
                      {props.errors.general}
                    </div>
                  )}
                  <Button
                    type="submit"
                    disabled={props.isSubmitting}
                    loading={props.isSubmitting}
                    color="blue"
                    onClick={props.handleSubmit}
                    fluid
                    size="large"
                  >
                    Set New Password
                  </Button>
                </Message>
              </Form>
              <Message
                style={{
                  border: "2px solid rgba(0,0,0,0.6)",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: Theme.PAGE_BACKGROUND,
                }}
              >
                Make sure your password utilizes uppercase, lowercase letters
                and uses special characters or numbers
              </Message>
            </Grid.Column>
          </Grid>
        )}
      />
    );
  }
}

export default Login;
