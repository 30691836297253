import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Header,
  Grid,
  Tab,
  Modal,
  Popup,
  Loader,
} from "semantic-ui-react";
import {
  Table,
  Time,
  Adjustment,
  Row,
  FolderContainer,
} from "../../../globalComponents";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import Theme from "Theme";
import NotesModal from "globalComponents/NotesModal";
import { errorHandler, timezoneValueToLabel } from "globalResources/util";
import TotalsSection from "./TotalsSection";
import styled from "styled-components";
import { getTheme } from "Theme";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { calculateReportTotals } from "globalResources/helpers/reports";
import { useLocation } from "react-router-dom";
import { formatDollars } from "globalResources/helpers";
import AdjustmentModal from "../adjustments/AdjustmentModal";
import { addNotification } from "store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";
const theme = getTheme();

const getTypeIcon = (type, isIcon) => {
  switch (type) {
    case "deduction":
      return isIcon ? "user" : "red";
    case "bonus":
      return isIcon ? "user plus" : "green";
    case "credit":
      return isIcon ? "minus" : "blue";
    case "debit":
      return isIcon ? "plus" : "";
  }
};

const adjustmentHeaders = [
  {
    label: "Employee",
    render: (row) => (
      <div>{row.user && row.user.firstName + " " + row.user.lastName}</div>
    ),
  },
  {
    label: "Created By",
    render: (row) => <div>{row.createdBy || "Client"}</div>,
  },
  {
    label: "Amount",
    render: (row) => (
      <div>$ {formatDollars(parseFloat(row.amount / 100))} </div>
    ),
  },
  {
    label: (
      <Popup
        position="top center"
        content={
          <div>
            <Icon name="user plus" color="green" /> = bonus <br />
            <Icon name="user" color="red" /> = deduction <br />
            <Icon name="minus" color="blue" /> = credit <br />
            <Icon name="plus" color="red" /> = debit <br />
          </div>
        }
        trigger={<div>Type</div>}
      />
    ),
    width: "15%",
    centered: true,
    render: (row) => (
      <Icon name={getTypeIcon(row.type, true)} color={getTypeIcon(row.type)} />
    ),
  },
];

const UserOption = styled.div`
  background-color: ${(props) =>
    props.selected ? theme.BUTTON_COLOR_GREY : theme.MAIN_COLOR};
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  border: 2px solid white;
  :hover {
    opacity: 0.85;
  }
`;

const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.PAGE_BACKGROUND};
  padding: 20px;
  height: 100%;
  border-radius: 20px;
`;
const ViewReport = () => {
  const dispatch = useDispatch();
  const [pulling, setPulling] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [report, setReport] = useState(null);
  const [totals, setTotals] = useState(null);
  const [groups, setGroups] = useState(null);
  const [adjustment, setAdjustment] = useState(null);

  const permissions = useSelector((state) => state.current.permissions);
  const [state, setState] = useState({
    loading: false,
    timesheets: [],
    search: null,
    searchBy: "name",
    currUser: 0,
    selected: null,
    message: null,
    adjust: {},
    limit: 20,
    page: 1,
    sortBy: "end",
    direction: 1,
  });
  const currentUser = useSelector((state) => state.current.user);
  const treatClient = currentUser.accountType === "client";

  const { id } = useParams();

  useEffect(() => {
    if (id) fetchReport({ _id: id }, 50, 1);
  }, [id]);

  function pullEntries(id) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    setPulling(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}/report/pullEntries`, {
        id: id,
      })
      .then(async (res) => {
        setState({ ...state, message: "Succesfully pulled in time entries" });
      })
      .catch((err) => {
        let { message, action } = errorHandler(err);
        setState({ ...state, loading: false, message });
      })
      .finally(() => setPulling(false));
  }

  async function pullAdjustments() {
    setPulling(true);
    try {
      await axios.patch("/adjustments/pull", { reportId: report._id });
      dispatch(addNotification({ message: "Added in adjustments" }));
      fetchReport();
    } catch (err) {
      let { message } = await errorHandler(err);
      dispatch(
        addNotification({
          message: "Failed to add adjustments",
          type: notifTags.error,
        })
      );
    } finally {
      setPulling(false);
    }
  }

  function paginate(sheets) {
    const { page, limit, sortBy, direction } = state;
    var temp = [...sheets];
    temp.sort((a, b) =>
      direction == 1
        ? a[sortBy] > b[sortBy]
          ? 1
          : -1
        : a[sortBy] > b[sortBy]
        ? -1
        : 1
    );
    return temp.slice(limit * (page - 1), limit * (page - 1) + limit);
  }
  function getHeaders(user) {
    const getHours = (row) => {
      return (
        moment
          .duration(moment(row.end).diff(moment(row.start)))
          .asHours()
          .toFixed(2) - (row.subtractLunch && !user.overrideLunch ? 0.5 : 0)
      );
    };
    var names = [
      {
        label: "Employee",
        render: (row) => (
          <div>{row.user.firstName + " " + row.user.lastName}</div>
        ),
      },
      {
        label: "Updated By",
        render: (row) => (
          <div>
            {row.notes.length > 1 && row.notes[row.notes.length - 1].user}
          </div>
        ),
      },
      {
        label: "Position",
        width: "40%",
        render: (row) => <div>{row.position}</div>,
      },
    ];
    names = names.concat([
      {
        label: "Start Time",
        sortBy: "start",
        render: (row) => (
          <div>
            {row.start &&
              moment(row.start)
                .tz(report.client.timezone)
                .format("ddd. MM/DD/yy hh:mm A")}
          </div>
        ),
      },
      {
        label: "End Time",
        sortBy: "end",
        render: (row) => (
          <div>
            {row.end &&
              moment(row.end)
                .tz(report.client.timezone)
                .format("ddd. MM/DD/yy hh:mm A")}
          </div>
        ),
      },
      {
        label: "Hours",
        width: "40%",
        centered: true,
        render: (row) => (
          <div>
            {getHours(row).toFixed(2) +
              (row.subtractLunch && !user.overrideLunch ? "(-30m)" : "")}
            hrs
          </div>
        ),
      },
      {
        label: (
          <Popup
            position="left center"
            content={
              <div>
                <Header>How the time was added</Header>
                <br />
                <Icon name="mail" color="purple" /> = SMS <br />
                <Icon name="male" color="blue" /> = Manager <br />
                <Icon name="desktop" color="yellow" /> = Time Station <br />
                <Icon name="server" color="yellow" /> = Auto Clock-Out <br />
                <Icon name="mobile" color="green" /> = Mobile App <br />
                <Icon name="location arrow" color="green" /> = Location verified{" "}
                <br />
                <Icon name="cancel" color="red" /> = Location not verified
              </div>
            }
            trigger={<div>Type</div>}
          />
        ),
        width: "15%",
        centered: true,
        render: (row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row.type === "sms" || row.sms ? (
              <Icon color="purple" name="mail" />
            ) : row.type === "app" ? (
              <>
                <Icon color="green" name="mobile" />
                <Icon
                  color={row.gps ? "green" : "red"}
                  name={row.gps ? "location arrow" : "cancel"}
                />
              </>
            ) : row.type === "station" ? (
              <Icon color="yellow" name="desktop" />
            ) : row.type === "auto" ? (
              <Icon color="red" name="server" />
            ) : (
              <Icon color="blue" name="male" />
            )}
          </div>
        ),
      },
      {
        label: "Bill Rate",
        width: "30%",
        render: (row) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            ${formatDollars(row.billRate / 100)} / hr
          </div>
        ),
      },
    ]);
    if (!treatClient)
      names = names.concat([
        {
          label: "Pay Rate",
          width: "30%",
          render: (row) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              ${formatDollars(row.payRate / 100)} / hr
            </div>
          ),
        },
      ]);
    return names;
  }

  async function unBillReport() {
    try {
      await axios.patch(`/report/${report._id}/unbill`);
      fetchReport();
    } catch (err) {
      await errorHandler(err);
    }
  }

  function getAmount(index) {
    if (index === 0) {
      return {
        ...totals,
        billed: totals.billed,
        profit: totals.profit + totals.adjustments,
      };
    } else if (index === 1) {
      return {
        overtimeBilled: totals.overtimeBilled,
        overtimePaid: totals.overtimePaid,
        adjustments: totals.adjustments,
        billed: totals.billed,
        profit: totals.profit + totals.adjustments,
      };
    } else {
      return groups[index - 2].totals;
    }
  }

  function getPanes(groups) {
    var panes = [];
    for (let group of groups) {
      panes.push({
        menuItem: `${group.user.firstName} ${group.user.lastName}  (${
          group.position === "employee"
            ? group.times[0].position
            : group.position
        })`,
        id: group._id,
      });
    }
    return [{ menuItem: "All" }, { menuItem: "Adjustments" }].concat(panes);
  }
  function viewAll(groups) {
    var all = [];
    groups.forEach((group) => {
      group.times.forEach((time) => {
        time.user = group.user;
        all.push(time);
      });
    });
    return all;
  }

  const recalculateReport = async () => {
    try {
      await axios.patch(`/report/${id}/recalculate`);
      dispatch(addNotification({ message: "Recalculated report totals" }));
    } catch (err) {
      const { message } = await errorHandler(err);
      dispatch(
        addNotification({
          message: "Failed to recalculate report",
          type: notifTags.error,
        })
      );
    }
  };

  const onSort = (by, direction) => {
    report.timesheets.sort((a, b) =>
      a[by] > b[by] ? (direction ? 1 : 0) : direction ? 0 : 1
    );
    setState((old) => ({ ...old, sortBy: by, direction }));
  };

  function fetchReport(limit, page, sortBy, direction) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/report?id=${id}`, {
        params: {
          page: page || state.page,
          limit: limit || state.limit,
          sortBy,
          direction,
        },
      })
      .then((res) => {
        calculateReportTotals(res.data.report, (ans) => {
          setReport(ans.report);
          setGroups(ans.groups);
          setTotals(ans.totals);
        });
      })
      .catch((err) => {
        console.error(err);
        history.push("/accounting");
      });
  }

  const {
    loading,
    currUser,
    selected,
    message,
    page,
    limit,
    sortBy,
    direction,
  } = state;
  if (!report || !totals || !groups) return <Loader active size="huge" />;
  const sheets = report.timesheets;
  return (
    <FolderContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          verticalAlign: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              position: "absolute",
              top: "65px",
              right: "10px",
              height: 40,
            }}
            onClick={() =>
              history.push({
                pathname: "/accounting",
                state: location?.state,
              })
            }
          >
            <Icon name="left arrow" />
            Back
          </Button>
        </div>
        <Row style={{ justifyContent: "space-between" }}>
          <ClientInfo>
            <Header as="h2" style={{ fontSize: "24px" }}>
              {report?.client?.name} #{report?.number}
            </Header>
            <Header as="h4" style={{ margin: "0 0 10px 0" }}>
              {moment.tz(report?.start, report.client.timezone).format("ll")}-
              {moment.tz(report?.end, report.client.timezone).format("ll")}
            </Header>
            <Header as="h4" style={{ margin: 0, color: theme.MAIN_COLOR }}>
              Status:{" "}
              {report.status === "errored" ? (
                <div style={{ maxWidth: 400 }}>
                  <Header as="h4">Error: {report.error}</Header>
                </div>
              ) : (
                report.status.toUpperCase()
              )}
            </Header>
            <Header
              as="h4"
              style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
            >
              Timezone: (
              {report && timezoneValueToLabel(report.client.timezone)})
            </Header>
            {report && report.check.number && (
              <Header
                as="h2"
                style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
              >
                Check Number: {report && report.check.number}
              </Header>
            )}
            {report?.check?.timestamp && (
              <Header
                as="h2"
                style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
              >
                Check Payment Date:{" "}
                {moment
                  .tz(report.check.timestamp, report.client.timezone)
                  .format("MM/DD/YY - hh:mm A z")}
              </Header>
            )}
            {report && report.check.url && (
              <Header
                as="h2"
                style={{ margin: "0 0 10px 0", color: theme.MAIN_COLOR }}
              >
                Check Image:{" "}
                <a
                  href={report && report.check.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  Link
                </a>
              </Header>
            )}
            <Row
              style={{
                margin: "auto 0 50px 0",
                position: "absolute",
                top: "65px",
                right: "120px",
              }}
            >
              {report && (
                <NotesModal
                  notes={report.notes}
                  type="report"
                  id={report._id}
                />
              )}
              <Popup
                position="top center"
                content={
                  <Header>
                    Looks for and adds time entries that were added after this
                    report was generated
                  </Header>
                }
                trigger={
                  <Button
                    color="blue"
                    onClick={() => pullEntries(report._id)}
                    loading={pulling}
                    disabled={pulling}
                  >
                    <Icon name="list" />
                    Pull In Time Entries
                  </Button>
                }
              />
              <Popup
                position="top center"
                content={
                  <Header>
                    Looks for and adds any adjustments for this client
                  </Header>
                }
                trigger={
                  <Button
                    color="blue"
                    onClick={pullAdjustments}
                    loading={pulling}
                    disabled={pulling}
                  >
                    <Icon name="list" />
                    Pull In Adjustments
                  </Button>
                }
              />
              <Popup
                position="top center"
                content={
                  <Header>recalculates the totals saved on the report</Header>
                }
                trigger={
                  <Button
                    color="blue"
                    onClick={recalculateReport}
                    loading={loading}
                    disabled={loading}
                  >
                    <Icon name="calculator" />
                    Recalculate Report Totals
                  </Button>
                }
              />
              {report.status !== "billed" && report.status !== "paid" && (
                <Button color="blue" onClick={() => setAdjustment({})}>
                  <Icon name="calculator" />
                  Add Adjustment
                </Button>
              )}
              {(permissions.includes("resetReports") ||
                currentUser.accountType === "super") &&
                report.status !== "paid" && (
                  <Popup
                    position="top center"
                    content={
                      <Header>
                        Resets the report and time entry status back to
                        generated, restarting the approval and billing process
                      </Header>
                    }
                    trigger={
                      <Button
                        color="blue"
                        onClick={unBillReport}
                        loading={pulling}
                        disabled={pulling}
                      >
                        <Icon name="refresh" />
                        Reset Report
                      </Button>
                    }
                  />
                )}
            </Row>
          </ClientInfo>
          <TotalsSection
            getAmount={getAmount}
            setState={setState}
            state={state}
            report={report}
            currUser={currUser}
            refresh={fetchReport}
          />
        </Row>
        <Grid stretched>
          <Header
            as="h4"
            style={{
              color: "yellow",
              backgroundColor: theme.BUTTON_COLOR_GREY,
              padding: "3px",
              borderRadius: "20px",
              textAlign: "right",
              margin: 0,
            }}
          >
            {report.client.subtractLunch &&
              "Half an hour is being subtracted for lunch. To turn this off go to client settings"}
          </Header>
          <Grid.Row>
            <Grid.Column width={3}>
              <div
                style={{
                  maxHeight: 700,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {getPanes(sheets).map((pane, i) => (
                  <UserOption
                    onClick={() => setState({ ...state, currUser: i, page: 1 })}
                    selected={currUser === i}
                  >
                    {pane.menuItem}
                  </UserOption>
                ))}
              </div>
            </Grid.Column>
            <Grid.Column width={13}>
              {currUser !== 0 ? (
                currUser !== 1 ? (
                  <Table
                    loading={loading}
                    onClick={(row) =>
                      setState({
                        ...state,
                        selected: { ...row, client: report.client },
                      })
                    }
                    data={report ? paginate(sheets[currUser - 2].times) : []}
                    pagination={{
                      page,
                      limit,
                      total: sheets[currUser - 2].times.length,
                    }}
                    onPage={(page) =>
                      setState({ ...state, page: page }, () =>
                        fetchReport(limit, page, sortBy, direction)
                      )
                    }
                    onLimit={(limit) =>
                      setState({ ...state, limit: limit, page: 1 }, () =>
                        fetchReport(limit, page, sortBy, direction)
                      )
                    }
                    headers={getHeaders(false)}
                    onSort={onSort}
                    sortBy={sortBy}
                    sortDirection={direction}
                  />
                ) : (
                  <Table
                    loading={loading}
                    onClick={(row) =>
                      report.status !== "billed" &&
                      report.status !== "paid" &&
                      setAdjustment(row)
                    }
                    data={report ? paginate(report.adjustments) : []}
                    pagination={{
                      page,
                      limit,
                      total: report.adjustments.length,
                    }}
                    onPage={(page) =>
                      setState({ ...state, page: page }, () =>
                        fetchReport(limit, page, sortBy, direction)
                      )
                    }
                    onLimit={(limit) =>
                      setState({ ...state, limit: limit, page: 1 }, () =>
                        fetchReport(limit, page, sortBy, direction)
                      )
                    }
                    headers={adjustmentHeaders}
                    onSort={onSort}
                    sortBy={sortBy}
                    sortDirection={direction}
                  />
                )
              ) : (
                <Table
                  loading={loading}
                  onClick={(row) =>
                    setState({
                      ...state,
                      selected: { ...row, client: report.client },
                    })
                  }
                  data={report ? paginate(viewAll(sheets)) : []}
                  pagination={{ page, limit, total: viewAll(sheets).length }}
                  onPage={(page) =>
                    setState({ ...state, page: page }, () =>
                      fetchReport(limit, page, sortBy, direction)
                    )
                  }
                  onLimit={(limit) =>
                    setState({ ...state, limit: limit, page: 1 }, () =>
                      fetchReport(limit, page, sortBy, direction)
                    )
                  }
                  headers={getHeaders(true)}
                  onSort={onSort}
                  sortBy={sortBy}
                  sortDirection={direction}
                />
              )}
              <Time
                report={true}
                selected={selected}
                onClose={() => {
                  setState((old) => ({ ...old, selected: null }));
                  fetchReport();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={!!message}
          closeIcon
          onClose={() => {
            setState({ ...state, message: null });
            fetchReport();
          }}
          style={{
            maxWidth: 500,
            textAlign: "center",
            padding: "1%",
          }}
        >
          <Header
            as="h2"
            style={{ backgroundColor: Theme.PAGE_BACKGROUND, color: "white" }}
          >
            {message}
          </Header>
        </Modal>
        {adjustment && (
          <AdjustmentModal
            report={report}
            adjustment={adjustment}
            onClose={() => {
              setAdjustment(null);
              fetchReport();
            }}
          />
        )}
      </div>
    </FolderContainer>
  );
};
export default ViewReport;
