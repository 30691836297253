import { Button } from "globalComponents";
import moment from "moment";
import React, { useState } from "react";
import { Modal, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	padding: 10px;
	gap: 20px;
`;

const Title = styled.div`
	font-size: 26px;
	color: ${theme.MAIN_COLOR};
	font-weight: 800;
	text-align: center;
	margin-bottom: 10px;
`;

const Timer = styled.div``;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	margin-top: 20px;
`;

const ClockoutReason = ({ onOut, onClose, user }) => {
	const [reason, setReason] = useState(null);
	const timer = user.currentTimer;
	return (
		<Modal
			open={true}
			onClose={onClose}
			style={{ backgroundColor: theme.MANILLA }}
		>
			<Main>
				<Title>
					Why are you clocking {user.firstName + " " + user.lastName} out?
				</Title>

				<Timer>
					{timer.client.name} ({timer.position})
					{moment(timer.start).format(" lll")}
				</Timer>
				<TextArea
					placeholder="clockout reason..."
					style={{ width: "90%", height: "20vh", padding: "10px" }}
					value={reason}
					onChange={(e, v) => setReason(v.value)}
				/>
				<Row style={{ gap: "10px" }}>
					<Button color={theme.BUTTON_COLOR_GREY} onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={() => onOut(reason)}>Confirm Clockout</Button>
				</Row>
			</Main>
		</Modal>
	);
};

export default ClockoutReason;
