import React from "react";
import { Button, Form, Grid, Image, Message } from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Logo from "../../assets/logos/full.png";
import Theme from "../../Theme.js";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string().required("Required"),
});

const SignUp = (props) => (
  <Grid
    centered
    textAlign="center"
    style={{ height: "100vh", backgroundColor: Theme.PAGE_BACKGROUND }}
    verticalAlign="middle"
  >
    <Grid.Column
      style={{
        maxWidth: 450,
        backgroundColor: "white",
        borderRadius: 10,
        boxShadow: "0 0 30px black",
      }}
    >
      <Image
        style={{
          margin: "0vh auto 1vh auto",
          height: 150,
        }}
        src={Logo}
      />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          axios
            .post(`${process.env.REACT_APP_API_URL}/users`, {
              email: values.email,
              phone: values.phone,
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password,
            })
            .then((res) => {
              actions.setSubmitting(false);

              props.history.push("");
            })
            .catch((error) => actions.setFieldError("general", error));
        }}
        render={(props) => (
          <Form onSubmit={props.handleSubmit} size="large">
            <Message style={{ backgroundColor: Theme.PAGE_BACKGROUND }}>
              <Form.Input
                fluid
                error={
                  props.errors.firstName && props.touched.firstName
                    ? props.errors.firstName
                    : false
                }
                name="firstName"
                onChange={(event, { name, value }) => {
                  props.setFieldValue(name, value);
                }}
                style={{ color: "blue" }}
                icon="user"
                iconPosition="left"
                placeholder="First Name"
                onBlur={() => props.setFieldTouched("firstName")}
              />
              <Form.Input
                fluid
                error={
                  props.errors.lastName && props.touched.lastName
                    ? props.errors.lastName
                    : false
                }
                style={{ color: "blue" }}
                icon="user"
                name="lastName"
                onChange={(event, { name, value }) => {
                  props.setFieldValue(name, value);
                }}
                iconPosition="left"
                placeholder="Last Name"
                onBlur={() => props.setFieldTouched("lastName")}
              />
              <Form.Input
                style={{ color: "blue" }}
                fluid
                error={
                  props.errors.email && props.touched.email
                    ? props.errors.email
                    : false
                }
                name="email"
                onChange={(event, { name, value }) => {
                  props.setFieldValue(name, value);
                }}
                icon="envelope"
                iconPosition="left"
                placeholder="E-mail address"
                onBlur={() => props.setFieldTouched("email")}
              />
              <Form.Input
                style={{ color: "blue" }}
                fluid
                error={
                  props.errors.phone && props.touched.phone
                    ? props.errors.phone
                    : false
                }
                name="phone"
                onChange={(event, { name, value }) => {
                  props.setFieldValue(name, value);
                }}
                icon="phone"
                iconPosition="left"
                placeholder="Phone Number"
                onBlur={() => props.setFieldTouched("phone")}
              />
              <Form.Input
                fluid
                error={
                  props.errors.password && props.touched.password
                    ? props.errors.password
                    : false
                }
                name="password"
                onChange={(event, { name, value }) => {
                  props.setFieldValue(name, value);
                }}
                style={{ color: "blue" }}
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onBlur={() => props.setFieldTouched("password")}
              />
              <Form.Input
                fluid
                error={
                  props.errors.confirmPassword && props.touched.confirmPassword
                    ? props.errors.confirmPassword
                    : false
                }
                name="confirmPassword"
                onChange={(event, { name, value }) => {
                  props.setFieldValue(name, value);
                }}
                style={{ color: "blue" }}
                icon="lock"
                iconPosition="left"
                placeholder="Confirm Password"
                type="password"
                onBlur={() => props.setFieldTouched("confirmPassword")}
              />
              {props.errors.general && (
                <div
                  style={{
                    color: "blue",
                    margin: "-1vh auto .5vh auto",
                    textAlign: "center",
                  }}
                >
                  {props.errors.general}
                </div>
              )}
              <Button
                disabled={props.isSubmitting}
                type="submit"
                color="blue"
                fluid
                size="large"
              >
                Create Account
              </Button>
            </Message>
          </Form>
        )}
      />
    </Grid.Column>
  </Grid>
);

export default SignUp;
