import React from "react";
import {
  Button,
  Form,
  Grid,
  Modal,
  Header,
  List,
  Icon,
  Popup,
  Radio,
} from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../../../Theme.js";
import { states, days, getTimezones } from "../../../globalResources/constants";
import { Confirm } from "../../../globalComponents";
import theme from "../../../Theme.js";
import Employees from "./Employee.js";
import Managers from "./Managers.js";
import NotesModal from "globalComponents/NotesModal";
const initialState = {
  searchBy: "name",
  users: [],
  pwdManage: false,
  addUser: {},
  editUser: null,
  addError: "",
  billRate: 0,
  overtimePayRate: 0,
  overtimeBillRate: 0,
  payRate: 0,
};
class ClientModal extends React.Component {
  state = initialState;

  getIds(list) {
    var ids = [];
    list.forEach((item) => {
      ids.push(item._id);
    });
    return ids;
  }
  archiveClient = (props) => {
    this.setState({ submitting: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}/client/archive`, {
        id: this.props.selected._id,
      })
      .then((res) => {
        this.setState(initialState);
        this.props.onClose();
      })
      .catch((error) => props.setFieldError("general", error));
  };

  render() {
    const { pwdManage, confirm, confirmDisable } = this.state;
    const { selected, onClose } = this.props;
    var SignupSchema = Yup.object().shape({
      name: Yup.string().min(2, "Too Short!").required("Required"),
      password: !selected
        ? Yup.string().min(5, "Too Short!").required("Required")
        : Yup.string(),
      confirmPassword: !selected
        ? Yup.string().required("Required")
        : Yup.string(),
    });
    return (
      <Formik
        initialValues={{
          name: selected._id ? selected.name : "",
          description: selected._id ? selected.description : "",
          address: selected._id ? selected.address : "",
          city: selected._id ? selected.city : "",
          state: selected._id ? selected.state : "",
          contacts: selected._id ? selected.contacts : [],
          zipcode: selected._id ? selected.zipcode : "",
          employees: selected._id ? selected.employees : [],
          team: selected._id ? selected.team : [],
          managerTeam: selected._id ? selected.managerTeam : [],
          managers: selected._id ? selected.managers : [],
          timezone: selected._id ? selected.timezone : "",
          password: "",
          confirmPassword: "",
          reportDay: selected ? selected.reportDay || "none" : "",
          subtractLunch: selected ? selected.subtractLunch : false,
          payscale: selected ? selected.payscale || [] : [],
          archived: selected ? selected.isArchived : false,
        }}
        enableReinitialize={!selected.create}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (pwdManage && values.password !== values.confirmPassword) {
            actions.setFieldError("confirmPassword", "Passwords must match");
            return null;
          }
          axios[selected._id ? "patch" : "post"](
            `${process.env.REACT_APP_API_URL}/client`,
            {
              ...values,
              password: pwdManage ? values.password : null,
              id: selected._id,
            }
          )
            .then((res) => {
              actions.resetForm();
              this.props.onClose();
              this.setState({
                initialize: true,
                contacts: [],
                selectedUsers: [],
                open: false,
              });
            })
            .error((error) => {
              actions.setFieldError("general", "Error saving client");
            })
            .finally(() => actions.setSubmitting(false));
        }}
        render={(props) => (
          <Modal
            open={!!selected._id || !!selected.create}
            centered
            onOpen={() => this.setState({ open: true })}
            onClose={() => {
              if (props.dirty) this.setState({ confirm: true });
              else
                this.setState(
                  {
                    pwdManage: false,
                    initialize: true,
                    selectedUsers: [],
                    contacts: [],
                  },
                  onClose
                );
            }}
            closeIcon
            style={{
              textAlign: "center",
              backgroundColor: "white",
              padding: "1vh",
            }}
          >
            <Header as="h1"> {selected && selected.name} </Header>
            <Form
              style={{ padding: "2%" }}
              onSubmit={props.handleSubmit}
              size="large"
            >
              <Grid
                style={{
                  borderRadius: 10,
                  backgroundColor: Theme.PAGE_BACKGROUND,
                  padding: "1%",
                }}
              >
                <Header as="h3" style={{ color: "white", margin: "auto" }}>
                  Client Info
                </Header>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input
                      fluid
                      error={
                        props.errors.name && props.touched.name
                          ? props.errors.name
                          : false
                      }
                      name="name"
                      value={props.values.name}
                      onChange={(event, { name, value }) => {
                        props.setFieldValue(name, value);
                      }}
                      style={{ color: "blue" }}
                      icon="user"
                      iconPosition="left"
                      placeholder="Name"
                      onBlur={() => props.setFieldTouched("name")}
                    />
                    <Form.Input
                      fluid
                      error={
                        props.errors.description && props.touched.description
                          ? props.errors.description
                          : false
                      }
                      style={{ color: "blue" }}
                      icon="pencil"
                      value={props.values.description}
                      name="description"
                      onChange={(event, { name, value }) => {
                        props.setFieldValue(name, value);
                      }}
                      iconPosition="left"
                      placeholder="Short Description"
                      onBlur={() => props.setFieldTouched("description")}
                    />
                    <Form.Dropdown
                      selection
                      options={getTimezones()}
                      error={
                        props.errors.state && props.touched.state
                          ? props.errors.state
                          : false
                      }
                      search
                      name="timezone"
                      onChange={(event, { name, value }) => {
                        props.setFieldValue(name, value);
                      }}
                      value={props.values.timezone}
                      placeholder="Timezone"
                      onBlur={() => props.setFieldTouched("timezone")}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "25px auto",
                        padding: 10,
                        backgroundColor: "white",
                        borderRadius: "25px",
                        border: `.5px solid ${theme.PAGE_BACKGROUND}`,
                      }}
                    >
                      <Radio
                        checked={props.values.subtractLunch}
                        toggle
                        onChange={() =>
                          props.setFieldValue(
                            "subtractLunch",
                            !props.values.subtractLunch
                          )
                        }
                      />
                      <Header
                        as="h4"
                        style={{
                          color: props.values.subtractLunch
                            ? theme.MAIN_COLOR
                            : theme.PAGE_BACKGROUND,
                          margin: "auto",
                        }}
                      >
                        Subtract 30 Minutes for Lunch
                      </Header>
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      fluid
                      error={
                        props.errors.address && props.touched.address
                          ? props.errors.address
                          : false
                      }
                      name="address"
                      value={props.values.address}
                      onChange={(event, { name, value }) => {
                        props.setFieldValue(name, value);
                      }}
                      style={{ color: "blue" }}
                      icon="thumbtack"
                      iconPosition="left"
                      placeholder="Address"
                      onBlur={() => props.setFieldTouched("address")}
                    />
                    <Form.Input
                      error={
                        props.errors.city && props.touched.city
                          ? props.errors.city
                          : false
                      }
                      name="city"
                      value={props.values.city}
                      onChange={(event, { name, value }) => {
                        props.setFieldValue(name, value);
                      }}
                      style={{ color: "blue" }}
                      icon="building"
                      iconPosition="left"
                      placeholder="City"
                      onBlur={() => props.setFieldTouched("city")}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: "2%",
                      }}
                    >
                      <Form.Dropdown
                        selection
                        options={states}
                        error={
                          props.errors.state && props.touched.state
                            ? props.errors.state
                            : false
                        }
                        search
                        name="state"
                        onChange={(event, { name, value }) => {
                          props.setFieldValue(name, value);
                        }}
                        value={props.values.state}
                        placeholder="State"
                        onBlur={() => props.setFieldTouched("state")}
                      />
                      <Form.Input
                        error={
                          props.errors.zipcode && props.touched.zipcode
                            ? props.errors.zipcode
                            : false
                        }
                        name="zipcode"
                        value={props.values.zipcode}
                        onChange={(event, { name, value }) => {
                          props.setFieldValue(name, value);
                        }}
                        width={5}
                        style={{ color: "blue" }}
                        placeholder="Zipcode"
                        onBlur={() => props.setFieldTouched("zipcode")}
                      />
                    </div>
                    <Popup
                      flowing
                      content="Day that a weekly report is generated/the billing week ends"
                      trigger={
                        <Form.Dropdown
                          fluid
                          selection
                          options={days}
                          placeholder="End of Billing Week"
                          error={
                            props.errors.reportDay && props.touched.reportDay
                              ? props.errors.reportDay
                              : false
                          }
                          name="reportDay"
                          value={props.values.reportDay}
                          onChange={(event, { name, value }) => {
                            props.setFieldValue(name, value);
                          }}
                          onBlur={() => props.setFieldTouched("reportDay")}
                        />
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Employees props={props} />
                <Managers props={props} />
                <Confirm
                  message="Exit without saving changes?"
                  open={confirm}
                  onConfirm={() => this.setState({ confirm: false }, onClose)}
                  onDeny={() => this.setState({ confirm: false })}
                />{" "}
                <Confirm
                  message={`Are you sure you want to ${
                    props.values.archived ? "enable" : "disable"
                  } this client?`}
                  open={confirmDisable}
                  onConfirm={() => {
                    this.setState({
                      confirm: false,
                      confirmDisable: null,
                    });
                    this.archiveClient(props);
                  }}
                  onDeny={() => this.setState({ confirmDisable: null })}
                />
                <Grid.Row>
                  {selected && (
                    <NotesModal
                      notes={selected.notes}
                      type="client"
                      id={selected._id}
                    />
                  )}
                </Grid.Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                    width: "100%",
                  }}
                >
                  {!selected.create && (
                    <Button
                      fluid
                      onClick={() =>
                        this.setState({
                          confirmDisable: props.values.archived
                            ? "enable"
                            : "delete",
                        })
                      }
                      type="button"
                      icon
                    >
                      {props.values.archived
                        ? "Enable Client"
                        : "Disable Client"}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    fluid
                    disabled={props.isSubmitting}
                    color="blue"
                    size="large"
                  >
                    Save Changes
                  </Button>
                </div>
              </Grid>
            </Form>
          </Modal>
        )}
      />
    );
  }
}

export default ClientModal;
