import axios from "axios";
import {Row, InfiniteTable} from "globalComponents";
import {errorHandler} from "globalResources/util";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {addNotification} from "store/slices/uiSlice";
import styled from "styled-components";
import {getTheme} from "Theme";
import {notifTags} from "./Notification";
import SelectorSearch from "./SelectorSearch";
const theme = getTheme();

const Main = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ClearButton = styled.div`
	position: absolute;
	margin-right: -20px;
	right: 40px;
	z-index: 2;
	background: ${theme.BUTTON_COLOR_NEGATIVE};
	color: white;
	border-radius: 20px;
	padding: 4px 8px;
	margin-top: 5px;
	cursor: pointer;
`;

const headers = [
	{label: "Name", render: row => `${row.firstName} ${row.lastName}`},
];

const UserSelector = ({employees, setEmployees, client}) => {
	const dispatch = useDispatch();

	const [search, setSearch] = useState("");
	const [users, setUsers] = useState([]);
	const [groups, setGroups] = useState([]);
	const [clients, setClients] = useState([]);
	const fetchUsers = async () => {
		try {
			let res = await axios.get("/users", {
				params: {
					search,
					searchBy: "name",
					sort: "firstName",
					direction: 1,
					accountType: ["employee", "admin", "super"],
					client,
				},
			});
			let temp = res.data.users.map(u => ({
				label: u.firstName + " " + u.lastName + ` (${u.accountType} ${u.UID})`,
				value: u,
			}));
			setUsers(temp);
		} catch (err) {
			const {message} = await errorHandler(err);
		}
	};

	const getFullUsers = async (type, id) => {
		try {
			let res = await axios.get("/users/details", {params: {type, id}});

			setEmployees(old =>
				old.concat(
					res.data.filter(u => old.filter(ou => ou._id === u._id).length === 0)
				)
			);
		} catch (err) {
			const {message} = await errorHandler(err);
		}
	};

	const fetchGroups = async () => {
		try {
			let res = await axios.get("/group/suggestions");
			let temp = [];
			res.data.groups.map(
				group =>
					group.name !== "All" &&
					temp.push({label: group.name, value: group._id})
			);
			setGroups(temp);
		} catch (err) {
			const {message} = await errorHandler(err);
		}
	};

	const fetchClients = async () => {
		try {
			let res = await axios.get("/client/suggestions");
			let temp = [];
			res.data.map(
				client =>
					client.name !== "All" &&
					temp.push({
						label: client.name,
						value: client._id,
					})
			);
			setClients(temp);
		} catch (err) {
			const {message} = await errorHandler(err);
		}
	};

	function checkAdded(v) {
		for (let user of employees) {
			if (user._id === v._id) return true;
		}
		return false;
	}

	useEffect(() => {
		fetchUsers();
	}, [search]);

	useEffect(() => {
		if (!client) {
			fetchGroups();
			fetchClients();
		}
	}, [client]);

	return (
		<Main>
			<Row style={{gap: "4px"}}>
				<SelectorSearch
					label="Add User"
					onSearch={v => setSearch(v)}
					onSelect={v =>
						checkAdded(v)
							? dispatch(
									addNotification({
										message: "User already added",
										type: notifTags.error,
									})
							  )
							: setEmployees(old => old.concat([v]))
					}
					options={users}
					placeholder="Select an Employee"
				/>
				{!client && (
					<SelectorSearch
						label="Add via Group"
						onSelect={v => getFullUsers("groups", v)}
						options={groups}
						placeholder="Select a group"
					/>
				)}
				{!client && (
					<SelectorSearch
						label="Add via client"
						onSelect={v => getFullUsers("clients", v)}
						options={clients}
						placeholder="Select a client"
					/>
				)}
			</Row>
			<div style={{position: "relative", marginTop: "5px"}}>
				<ClearButton onClick={() => setEmployees([])}>
					Clear {employees.length} Selected Employees
				</ClearButton>
				<InfiniteTable
					height="400px"
					data={employees.sort((a, b) =>
						a.firstName + a.lastName > b.firstName + b.lastName ? 1 : -1
					)}
					headers={headers}
					onClick={row =>
						setEmployees(old => old.filter(u => u._id !== row._id))
					}
				/>
			</div>
		</Main>
	);
};
export default UserSelector;
