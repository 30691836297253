import React from "react";
import { Icon, Header, Grid, Tab, Modal, Popup } from "semantic-ui-react";
import { StandardTable, Time } from "../../../globalComponents";
import moment from "moment-timezone";
import Theme from "../../../Theme.js";
import { connect } from "react-redux";
import { formatDollars } from "globalResources/helpers";
class ViewReport extends React.Component {
  state = {
    loading: false,
    timesheets: [],
    search: null,
    searchBy: "name",
    currUser: 0,
    message: null,
    limit: 20,
    page: 1,
    sortBy: "end",
    direction: -1,
    totals: [],
    totalAmount: 0,
    selected: null,
  };

  paginate(sheets) {
    const { page, limit, sortBy, direction } = this.state;
    var temp = [...sheets];
    temp.sort((a, b) =>
      direction == 1
        ? a[sortBy] > b[sortBy]
          ? 1
          : -1
        : a[sortBy] > b[sortBy]
        ? -1
        : 1
    );
    return temp.slice(limit * (page - 1), limit * (page - 1) + limit);
  }
  getHeaders(user) {
    const getHours = (row) => {
      return (
        moment
          .duration(moment(row.end).diff(moment(row.start)))
          .asHours()
          .toFixed(2) - (row.subtractLunch && !user.overrideLunch ? 0.5 : 0)
      );
    };
    var names = !user
      ? [
          {
            label: "Updated By",
            renderCell: (row) => (
              <div>
                {row.notes.length > 1 && row.notes[row.notes.length - 1].user}
              </div>
            ),
          },
          {
            label: "Position",
            renderCell: (row) => <div>{row.position}</div>,
          },
          {
            label: "Client",
            renderCell: (row) => <div>{row.client && row.client.name}</div>,
          },
        ]
      : [
          {
            label: "User",
            renderCell: (row) => (
              <div>
                {row.user && row.user.firstName + " " + row.user.lastName}
              </div>
            ),
          },
          {
            label: "Client",
            renderCell: (row) => <div>{row.client && row.client.name}</div>,
          },
        ];
    names = names.concat([
      {
        label: "Start Time",
        sortName: "start",
        renderCell: (row) => (
          <div>
            {row.start &&
              moment.tz(row.start, row.client.timezone).format("lll")}
          </div>
        ),
      },
      {
        label: "End Time",
        sortName: "end",
        renderCell: (row) => (
          <div>
            {row.end && moment.tz(row.end, row.client.timezone).format("lll")}
          </div>
        ),
      },
      {
        label: "Hours",
        renderCell: (row) => (
          <div>
            {getHours(row).toFixed(2) +
              (row.subtractLunch && !user.overrideLunch ? " (-30m)" : "")}
            hrs
          </div>
        ),
      },
      {
        label: "Bill Amount",
        renderCell: (row) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            ${formatDollars(getHours(row) * (row.billRate / 100))}
            <div /> (${formatDollars(row.billRate / 100)}/hr)
          </div>
        ),
      },
      {
        label: (
          <Popup
            position="left center"
            content={
              <div>
                <Header>How the time was added</Header>
                <br />
                <Icon name="mail" color="purple" /> = SMS <br />
                <Icon name="male" color="blue" /> = Manager <br />
                <Icon name="desktop" color="yellow" /> = Time Station <br />
                <Icon name="server" color="yellow" /> = Auto Clock-Out <br />
                <Icon name="mobile" color="green" /> = Mobile App <br />
                <Icon name="location arrow" color="green" /> = Location verified{" "}
                <br />
                <Icon name="cancel" color="red" /> = Location not verified
              </div>
            }
            trigger={<div>Type</div>}
          />
        ),
        compact: true,
        renderCell: (row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {row.type === "sms" || row.sms ? (
              <Icon color="purple" name="mail" />
            ) : row.type === "app" ? (
              <>
                <Icon color="green" name="mobile" />
                <Icon
                  color={row.gps ? "green" : "red"}
                  name={row.gps ? "location arrow" : "cancel"}
                />
              </>
            ) : row.type === "station" ? (
              <Icon color="yellow" name="desktop" />
            ) : row.type === "auto" ? (
              <Icon color="red" name="server" />
            ) : (
              <Icon color="blue" name="male" />
            )}
          </div>
        ),
      },
    ]);

    if (this.props.user.accountType !== "client")
      names = names.concat([
        {
          label: "Employee Pay",
          renderCell: (row) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              ${formatDollars(getHours(row) * (row.payRate / 100))}
              <div /> (${(row.payRate / 100).toFixed(2)}/hr)
            </div>
          ),
        },
        {
          label: "Profit",
          renderCell: (row) => {
            var pay = (getHours(row) * (row.payRate / 100)).toFixed(2);
            var bill = (getHours(row) * (row.billRate / 100)).toFixed(2);
            return <Header as="h5"> ${(bill - pay).toFixed(2)} </Header>;
          },
        },
      ]);
    return names;
  }

  getPanes(groups) {
    var panes = [{ menuItem: "All" }];
    groups.forEach((group) =>
      panes.push({
        menuItem: `${group.user.firstName} ${group.user.lastName} `,
      })
    );
    return panes;
  }

  viewAll(groups) {
    var all = [];
    groups.forEach((group) => {
      group.times.forEach((time) => {
        time.user = group.user;
        all.push(time);
      });
    });
    return all;
  }

  //calculate all totals for the report and time entry
  getAmount(index) {
    const { totals } = this.props;
    if (index == 0) {
      var all = {
        hours: 0,
        overtimeHours: 0,
        paid: 0,
        billed: 0,
        overtimePaid: 0,
        overtimeBilled: 0,
        profit: 0,
      };
      totals.map((group) => {
        all.hours += group.total.hours;
        all.overtimeHours += group.total.overtimeHours;
        all.paid += group.total.paid;
        all.billed += group.total.billed;
        all.overtimePaid += group.total.overtimePaid;
        all.overtimeBilled += group.total.overtimeBilled;
      });
      all.profit = all.billed - all.paid;
      return all;
    } else return totals[index - 1].total;
  }

  onSort = (sort, direction) => {
    const { report } = this.props;
    report.sort((a, b) =>
      a[sort] > b[sort] ? (direction ? 1 : 0) : direction ? 0 : 1
    );
    this.setState({ sortBy: sort, direction });
  };

  render() {
    const {
      loading,
      currUser,
      message,
      page,
      limit,
      sortBy,
      direction,
      selected,
    } = this.state;
    const { report } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          verticalAlign: "center",
          padding: "1%",
          backgroundColor: Theme.PAGE_BACKGROUND,
        }}
      >
        <Header as="h4" style={{ color: "yellow", textAlign: "right" }}>
          Totals are not able to properly estimate overtime hours and lunch
          break subtractions
        </Header>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#1e2026",
            borderRadius: 10,
            minWidth: 300,
          }}
        >
          <Header
            as="h4"
            style={{
              color: "white",
              margin: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Total Hours: <div />
            {this.getAmount(currUser).hours.toFixed(2)} hours
          </Header>
          {this.getAmount(currUser).paid !== 0 && (
            <Header
              as="h4"
              style={{
                color: "white",
                margin: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Total Employee Paid: <div />$
              {(
                this.getAmount(currUser).paid +
                this.getAmount(currUser).overtimePaid
              ).toFixed(2)}
            </Header>
          )}
          <Header
            as="h4"
            style={{
              color: "white",
              margin: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Total Billed Amount:
            <div /> $
            {(
              this.getAmount(currUser).billed +
              this.getAmount(currUser).overtimeBilled
            ).toFixed(2)}
          </Header>
          {this.getAmount(currUser).paid !== 0 && (
            <Header
              as="h4"
              style={{
                color: "white",
                margin: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Total Profit: <div />${this.getAmount(currUser).profit.toFixed(2)}
            </Header>
          )}
        </div>

        <Grid stretched>
          <Grid.Row>
            <Grid.Column width={3}>
              {" "}
              <div
                style={{
                  maxHeight: 700,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Tab
                  onTabChange={(e, data) => {
                    this.setState({ currUser: data.activeIndex });
                  }}
                  grid={{ tabWidth: 16 }}
                  menu={{ vertical: true, inverted: true }}
                  panes={this.getPanes(report)}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={13}>
              {currUser !== 0 ? (
                <StandardTable
                  loading={loading}
                  onSelect={(row) => this.setState({ selected: row })}
                  data={report ? this.paginate(report[currUser - 1].times) : []}
                  total={report[currUser - 1].times.length}
                  page={page}
                  limit={limit}
                  onPage={(page) =>
                    this.setState({ page: page }, this.props.refresh)
                  }
                  onLimit={(limit) =>
                    this.setState({ limit: limit, page: 1 }, this.props.refresh)
                  }
                  headers={this.getHeaders(false)}
                  onSort={this.onSort}
                  sort={sortBy}
                  direction={direction}
                />
              ) : (
                <StandardTable
                  loading={loading}
                  onSelect={(row) => this.setState({ selected: row })}
                  data={report ? this.paginate(this.viewAll(report)) : []}
                  total={this.viewAll(report).length}
                  page={page}
                  limit={limit}
                  onPage={(page) =>
                    this.setState({ page: page }, this.props.refresh)
                  }
                  onLimit={(limit) =>
                    this.setState({ limit: limit, page: 1 }, this.props.refresh)
                  }
                  headers={this.getHeaders(true)}
                  onSort={this.onSort}
                  sort={sortBy}
                  direction={direction}
                />
              )}
              <Time
                report={true}
                selected={selected}
                onClose={() => {
                  this.setState({ selected: null }, this.props.refresh);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={!!message}
          closeIcon
          onClose={() => {
            this.setState({ message: null }, this.props.refresh);
          }}
          style={{
            maxWidth: 500,
            textAlign: "center",
            padding: "1%",
          }}
        >
          <Header
            as="h2"
            style={{ backgroundColor: Theme.PAGE_BACKGROUND, color: "white" }}
          >
            {message}
          </Header>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.current });
export default connect(mapStateToProps, null)(ViewReport);
