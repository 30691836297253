import React from "react";
import {
	Button,
	Form,
	Grid,
	Image,
	Message,
	Confirm,
	Modal,
} from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Logo from "../assets/logos/full.png";
import cookie from "js-cookie";
import Theme from "../Theme.js";

const SettingsSchema = Yup.object().shape({
	firstName: Yup.string().min(2, "Too Short!").required("Required"),
	lastName: Yup.string().min(2, "Too Short!").required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
});

class Settings extends React.Component {
	render() {
		const { open, onClose, data } = this.props;
		return (
			<div>
				<Modal
					open={open}
					onClose={onClose}
					closeIcon
					style={{
						textAlign: "center",
					}}
				>
					<Grid
						centered
						textAlign="center"
						verticalAlign="middle"
						style={{ width: "100%", margin: "auto" }}
					>
						<Grid.Column
							style={{
								borderRadius: 10,
							}}
						>
							<Formik
								enableReinitialize={true}
								initialValues={{
									firstName: data.firstName,
									lastName: data.lastName,
									password: "",
									confirmPassword: "",
								}}
								validationSchema={SettingsSchema}
								onSubmit={(values, actions) => {
									actions.setSubmitting(true);
									if (values.password && !values.currentPassword) {
										return actions.setFieldError(
											"general",
											"Need current password"
										);
									} else if (
										values.password &&
										values.password !== values.confirmPassword
									) {
										return actions.setFieldError(
											"general",
											"Passwords Must Match"
										);
									}
									axios.defaults.headers.common[
										"Authorization"
									] = `Bearer ${cookie.get("token")}`;
									axios
										.post(`${process.env.REACT_APP_API_URL}/users/settings`, {
											email: values.email,
											phone: values.phone,
											firstName: values.firstName,
											lastName: values.lastName,
											currentPassword: values.currentPassword,
											password: values.password,
											self: true,
										})
										.then(res => {
											actions.setSubmitting(false);
											this.setState({ open: false });
										})
										.catch(error => actions.setFieldError("general", error));
								}}
								render={props =>
									props.initialValues.email === data.email ? (
										<Form onSubmit={props.handleSubmit} size="large">
											<Message
												style={{ backgroundColor: Theme.PAGE_BACKGROUND }}
											>
												<Form.Input
													fluid
													error={
														props.errors.firstName && props.touched.firstName
															? props.errors.firstName
															: false
													}
													value={props.values.firstName}
													name="firstName"
													onChange={(event, { name, value }) => {
														props.setFieldValue(name, value);
													}}
													style={{ color: "red" }}
													icon="user"
													iconPosition="left"
													placeholder={data.firstName || "First Name"}
													onBlur={() => props.setFieldTouched("firstName")}
												/>
												<Form.Input
													fluid
													error={
														props.errors.lastName && props.touched.lastName
															? props.errors.lastName
															: false
													}
													style={{ color: "red" }}
													icon="user"
													name="lastName"
													value={props.values.lastName}
													onChange={(event, { name, value }) => {
														props.setFieldValue(name, value);
													}}
													iconPosition="left"
													placeholder={data.lastName || "Last Name"}
													onBlur={() => props.setFieldTouched("lastName")}
												/>

												<div
													style={{
														display: data.pwdManage ? "flex" : "hidden",
														marginBottom: "5%",
													}}
												>
													<Form.Input
														fluid
														error={
															props.errors.currentPassword &&
															props.touched.currentPassword
																? props.errors.currentPassword
																: false
														}
														name="currentPassword"
														onChange={(event, { name, value }) => {
															props.setFieldValue(name, value);
														}}
														style={{ color: "red" }}
														icon="lock"
														iconPosition="left"
														placeholder="Current Password"
														type="password"
														onBlur={() =>
															props.setFieldTouched("currentPassword")
														}
													/>
													<Form.Input
														fluid
														error={
															props.errors.password && props.touched.password
																? props.errors.password
																: false
														}
														name="password"
														onChange={(event, { name, value }) => {
															props.setFieldValue(name, value);
														}}
														style={{ color: "red" }}
														icon="lock"
														iconPosition="left"
														placeholder="New Password"
														type="password"
														onBlur={() => props.setFieldTouched("password")}
													/>
													<Form.Input
														fluid
														error={
															props.errors.confirmPassword &&
															props.touched.confirmPassword
																? props.errors.confirmPassword
																: false
														}
														name="confirmPassword"
														onChange={(event, { name, value }) => {
															props.setFieldValue(name, value);
														}}
														style={{ color: "red" }}
														icon="lock"
														iconPosition="left"
														placeholder="Confirm Password"
														type="password"
														onBlur={() =>
															props.setFieldTouched("confirmPassword")
														}
													/>
												</div>
												{props.errors.general && (
													<div
														style={{
															color: "red",
															margin: "-1vh auto .5vh auto",
															textAlign: "center",
														}}
													>
														{props.errors.general}
													</div>
												)}
												<Button
													disabled={props.isSubmitting}
													type="submit"
													color="blue"
													fluid
													size="large"
												>
													Save Settings
												</Button>
											</Message>
										</Form>
									) : null
								}
							/>
							<Confirm
								open={data.open}
								content="Changes saved confirm to go back to the home screen or cancel to change more settings."
								onCancel={() => {
									this.fetchData();
									this.setState({ open: false });
								}}
								onConfirm={() => this.props.history.push("")}
							/>
						</Grid.Column>
					</Grid>
				</Modal>
			</div>
		);
	}
}

export default Settings;
