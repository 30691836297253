import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
`;

const Checkbox = styled.input``;

const ToggleButton = ({ label, onChange, value }) => {
  return (
    <Main>
      <Label>{label}</Label>
      <Checkbox
        type="checkbox"
        onChange={(e) => onChange(e.currentTarget.value)}
        checked={value}
      />
    </Main>
  );
};
export default ToggleButton;
