import axios from "axios";
import {
  Button,
  Column,
  EditButton,
  AccountingFolderContainer,
  Row,
  SectionTitle,
  Table,
} from "globalComponents";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import { roundTwoDecimal } from "globalResources/util";
import { useDispatch } from "react-redux";
import { addNotification } from "store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";
import { formatDollars } from "globalResources/helpers";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  gap: 10px;
`;

const ManageButton = styled(Button)`
  width: 200px;
  margin-left: auto;
`;

const reportHeaders = [
  {
    label: "Date",
    sortBy: "end",
    render: (row) =>
      `${moment.utc(row.start).format("ll")} - ${moment
        .utc(row.end)
        .format("ll")}`,
  },
  { label: "Status", render: (row) => <div>{row.status}</div> },
  { label: "# of Employees", render: (row) => <div>{row.lines.length}</div> },
  {
    label: "total",
    render: (row) => <div>${formatDollars(row.totals?.pay / 100)}</div>,
  },
];

const Payroll = () => {
  const history = useHistory();
  const [payrolls, setPayrolls] = useState([]);
  const [payrollJobs, setPayrollJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
  });
  const [sort, setSort] = useState({ sort: "end", direction: -1 });
  const dispatch = useDispatch();

  const fetchPayrollHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/accounting/payroll/report/list", {
        params: { page: pagination.page, limit: pagination.limit, ...sort },
      });
      setPayrolls(res.data.reports);
      setPagination((old) => ({ ...old, total: res.data?.total }));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const fetchPayrollJobs = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/accounting/payroll/job/list", {
        params: { page: pagination.page, limit: pagination.limit },
      });
      setPayrollJobs(res.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const deleteJob = async (id) => {
    try {
      await axios.delete(`/accounting/payroll/job/${id}`);
      fetchPayrollJobs();
    } catch (err) {
      dispatch(
        addNotification({
          message: "Failed to update job",
          type: notifTags.error,
        })
      );
    }
  };

  const toggleIsActive = async (id, isActive) => {
    try {
      await axios.patch(`/accounting/payroll/job/${id}`, { isActive });
      fetchPayrollJobs();
    } catch (err) {
      dispatch(
        addNotification({
          message: "Failed to delete job",
          type: notifTags.error,
        })
      );
    }
  };

  useEffect(() => {
    fetchPayrollHistory();
    fetchPayrollJobs();
  }, [pagination.limit, pagination.page, sort]);

  const jobHeaders = [
    {
      label: "Next Run",
      render: (row) => `${moment(row.nextRunAt).format("ll")}`,
    },
    {
      label: "Active",
      render: (row) => (
        <FontAwesomeIcon icon={row.isActive ? faCheckSquare : faXmarkSquare} />
      ),
    },
    {
      label: "Actions",
      render: (row) => (
        <Row>
          <EditButton onClick={() => toggleIsActive(row._id, !row.isActive)}>
            {row.isActive ? "Disable" : "Enable"}
          </EditButton>
          <EditButton onClick={() => deleteJob(row._id)}>Delete</EditButton>
        </Row>
      ),
    },
  ];
  return (
    <AccountingFolderContainer>
      <Main>
        <ManageButton onClick={() => history.push("/accounting/payroll/run")}>
          Run Payroll
        </ManageButton>
        <Row style={{ gap: "10px" }}>
          <Column style={{ width: "100%", textAlign: "center" }}>
            <SectionTitle>Payroll History</SectionTitle>
            <Table
              data={payrolls}
              headers={reportHeaders}
              onClick={(row) => history.push(`/accounting/payroll/${row._id}`)}
              pagination={pagination}
              onLimit={(limit) => setPagination((old) => ({ ...old, limit }))}
              onPage={(page) => setPagination((old) => ({ ...old, page }))}
              loading={loading}
              sortBy={sort.sort}
              sortDirection={sort.direction}
              onSort={(sort, direction) => setSort({ sort, direction })}
            />
          </Column>
          <Column style={{ width: "100%", textAlign: "center" }}>
            <SectionTitle>Payroll Jobs</SectionTitle>
            <Table data={payrollJobs} headers={jobHeaders} />
          </Column>
        </Row>
      </Main>
    </AccountingFolderContainer>
  );
};
export default Payroll;
