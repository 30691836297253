import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import cookie from "js-cookie";
import { Icon, Form } from "semantic-ui-react";
import styled from "styled-components";
import { getTheme } from "Theme";
import { getButtonColor } from "Theme";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "store/slices/userSlice";
import { addNotification } from "../../store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";

const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.SECONDARY_COLOR};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const Clock = styled.div`
  display: flex;
  flex-direction: column;
  border:
  gap: 4px;
  background: white;
  padding: 5px;
`;

const Error = styled.h1`
  color: ${theme.NEGATIVE};
  font-size: 14px;
  font-weight: 500;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justifycontent: center;
  text-align: center;
  gap: 20px;
  padding: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TimeLabel = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-right: 8px;
  width: 25%;
`;

const Time = styled.span`
  font-size: 14px;
`;

const Client = styled.span`
  font-size: 14px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
  color: white;
  padding: 8px;
  border-bottom: 2px solid ${theme.BLACK};
  background: ${theme.MAIN_COLOR};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin: 0;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background: ${(props) => getButtonColor(props.color)};
  color: ${theme.BUTTON_TEXT};
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => props.disabled && 0.7};
  :hover {
    opacity: 0.8;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px auto;
`;

const TimerManager = () => {
  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);
  const [employers, setEmployers] = useState([]);
  const [clientTimer, setClientTimer] = useState({});

  const dispatch = useDispatch();
  const user = useSelector((state) => state.current.user);
  const [duration, setDuration] = useState(0);

  const startTimer = () => {
    if (!client) setError("Select a client");
    else
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/startTimer`, {
          start: moment().utc(),
          client: client.id,
          position: client.position,
          id: user._id || cookie.get("id"),
        })
        .then((res) => {
          dispatch(
            addNotification({
              type: notifTags.success,
              message: "Clocked In!",
            })
          );

          dispatch(setUser({ user: res.data.user }));
        })
        .catch((err) => {
          dispatch(
            addNotification({
              type: notifTags.error,
              message: "Clock in failed!",
            })
          );
        });
  };

  const endTimer = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/endTimer`, {
        start: moment(user.currentTimer.start).utc(),
        end: moment().utc(),
        id: user._id,
        clientId: user.currentTimer.client,
      })
      .then((res) => {
        dispatch(setUser({ user: res.data.user }));
        dispatch(
          addNotification({
            type: notifTags.success,
            message: "Clocked out!",
          })
        );
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: notifTags.error,
            message: "Clock out failed!",
          })
        );
      });
  };

  const cancelTimer = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/cancelTimer`, {
        id: user._id,
      })
      .then((res) => {
        dispatch(setUser({ user: res.data.user }));
        dispatch(
          addNotification({
            type: notifTags.success,
            message: "Timer Cancelled",
          })
        );
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: notifTags.error,
            message: "Failed to cancel timer",
          })
        );
      });
  };

  const fetchEmployers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`, {
        params: { employee: true },
      })
      .then(async (res) => {
        if (!res.data || res.data.length < 1) {
          setEmployers([]);
        } else {
          const clients = res.data;
          var temp = [];
          clients.sort((a, b) => (a.name > b.name ? 1 : -1));
          clients.forEach((client) => {
            client.employees.forEach((e) => {
              if (e.user === user._id) {
                if (client._id === user.currentTimer?.client)
                  setClientTimer(client);
                temp.push({
                  text: `${client.name} (${e.position})`,
                  value:
                    client.name === "All"
                      ? null
                      : { id: client._id, position: e.position },
                  data: client,
                });
              }
            });
          });
          setEmployers(temp);
          setError(null);
        }
      });
  };

  useEffect(() => {
    fetchEmployers();
    if (user.currentTimer)
      setDuration(
        moment
          .duration(
            moment
              .tz(clientTimer.timezone)
              .diff(moment.tz(user.currentTimer.start, clientTimer.timezone))
          )
          .asHours()
          .toFixed(2)
      );
  }, [user]);

  useEffect(() => {
    setTimeout(
      () =>
        user.currentTimer &&
        setDuration(
          moment
            .duration(
              moment
                .tz(clientTimer.timezone)
                .diff(moment.tz(user.currentTimer.start, clientTimer.timezone))
            )
            .asHours()
            .toFixed(2)
        ),
      60000
    );
  }, [duration]);

  return (
    <Main>
      <Header as="h1" style={{ textAlign: "center", fontSize: "24px" }}>
        Your Timecard
      </Header>
      {user.smsClockinDisabled == false && (
        <>
          {!user.currentTimer || !clientTimer.timezone ? (
            <FormContainer>
              <Form.Dropdown
                onChange={(e, v) => {
                  setClient(v.value);
                }}
                selection
                search
                options={employers.filter((opt) => opt.text !== "All")}
                placeholder={
                  !user._id ? "Select a User First" : "Select Client"
                }
                value={client}
                style={{ width: "100%" }}
                name="timer-client"
                disabled={!user._id}
              />
              <Button color="blue" onClick={startTimer} disabled={!client}>
                <Icon name="clock" />
                Clock In
              </Button>
            </FormContainer>
          ) : (
            <Clock>
              <Row>
                <TimeLabel>Time In: </TimeLabel>
                <Time>
                  {moment(user.currentTimer.start)
                    .tz(clientTimer.timezone)
                    .format("lll")}
                </Time>
              </Row>
              <Row>
                <TimeLabel>Duration: </TimeLabel>
                <Time>
                  {duration}
                  hrs
                </Time>
              </Row>
              <Row>
                <TimeLabel>Client: </TimeLabel>
                <Client>
                  {clientTimer.name} ({user.currentTimer.position})
                </Client>
              </Row>
            </Clock>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
              width: "auto",
            }}
          >
            {user.currentTimer && (
              <ButtonRow>
                <Button color="blue" onClick={endTimer}>
                  <Icon name="clock" />
                  Clock Out
                </Button>
                <Button color="grey" onClick={cancelTimer}>
                  Cancel Timer
                </Button>
              </ButtonRow>
            )}
          </div>
        </>
      )}

      {error && <Error>{error}</Error>}
    </Main>
  );
};

export default TimerManager;
