import React, { useEffect, useState } from "react";
import { Button, Header, Loader } from "semantic-ui-react";
import axios from "axios";
import { Confirm, DatePicker, SelectorSearch, Modal, FormRow } from ".";
import cookie from "js-cookie";
import moment from "moment-timezone";
import NotesModal from "./NotesModal.js";
import theme from "../Theme.js";
import { errorHandler, timezoneValueToLabel } from "globalResources/util.js";
import styled from "styled-components";
import Input from "./Input.jsx";
import { useSelector } from "react-redux";
import { getClientEmployeeOptions } from "api/client";
import Selector from "./Selector";
import { ToggleButton } from "../globalComponents";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/slices/uiSlice";
import { notifTags } from "./Notification";
import { formatDollars } from "globalResources/helpers";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const initialForm = {
  user: "",
  client: "",
  payRate: 0,
  position: undefined,
  billRate: 0,
  overtimePayRate: 0,
  overtimeBillRate: 0,
  start: "",
  end: "",
  timezone: "US/Central",
  hours: "0",
  subtractLunch: false,
};

const TimeModal = ({ selected, onClose, report }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({
    ...initialForm,
    timezone: "",
    payRate: "",
    billRate: "",
    overtimePayRate: "",
    overtimeBillRate: "",
    position: "",
  });
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const [clientSearch, setClientSearch] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [customPay, setCustomPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [positions, setPositions] = useState([]);
  const [byHours, setByHours] = useState(false);

  //TODO: replace this with permissions in redux let canEditPayRate = checkPermission("editPayRate");

  const canEditPayRate = useSelector(
    (state) =>
      state.current.permissions.includes("editPayRates") ||
      state.current.user?.accountType === "super"
  );

  useEffect(() => {
    if (selected && selected._id)
      setForm({
        user: selected.user?._id,
        client: selected.client?._id,
        start: moment
          .tz(selected.start, selected.client?.timezone)
          .format("YYYY-MM-DDTHH:mm"),
        end: moment
          .tz(selected.end, selected.client?.timezone)
          .format("YYYY-MM-DDTHH:mm"),
        timezone: selected.client?.timezone,
        position: selected.position,
        payRate: formatDollars(selected.payRate / 100),
        billRate: formatDollars(selected.billRate / 100),
        overtimePayRate: formatDollars(selected.overtimePayRate / 100),
        overtimeBillRate: formatDollars(selected.overtimeBillRate / 100),
        subtractLunch: !!selected.subtractLunch,
      });
    else setForm(initialForm);
  }, [selected]);
  useEffect(() => {
    if (selected) fetchUsers();
  }, [form.client]);

  const fetchClients = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`, {
        params: { search: clientSearch },
      })
      .then(async (res) => {
        if (res.data.length < 1) {
          setClients([]);
        } else {
          const clients = res.data;
          var temp = [];
          clients.forEach((client) => {
            if (client.name !== "All")
              temp.push({
                label: client.name,
                value: client._id,
                timezone: client.timezone,
              });
          });
          setClients(temp);
        }
      })
      .catch((err) => {
        setLoading(false);
        const { message, action } = errorHandler(err);
        setError(message);
      });
  };
  const deleteTime = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/timesheet/remove`, {
        id: selected._id,
      })
      .then((res) => {
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        const { message, action } = errorHandler(err);
        setError(message);
      });
  };

  const fetchUsers = async () => {
    setLoading(true);
    setUsers(await getClientEmployeeOptions(form.client));
    setLoading(false);
  };

  const fetchPositions = async (clientId, userId) => {
    try {
      let res = await axios.get("/employee/positions", {
        params: { clientId: form.client, userId: form.user },
      });
      let temp = res.data.map((e) => ({
        label: e.position,
        employee: e,
        value: e.position,
      }));
      setPositions(temp);
      for (let p of temp) {
        if (p.value === form.position) {
          setFieldValue("position", p.value);
          return;
        }
      }
      setFieldValue("position", temp[0]?.value);
    } catch (e) {}
  };

  function unBill(id) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/timesheet/unbill`, {
        id,
      })
      .then((res) => console.log());
  }
  useEffect(() => {
    setLoading(true);
    fetchUsers();
    fetchClients();
  }, []);
  useEffect(() => {
    fetchPositions();
  }, [form.client]);
  const onSubmit = () => {
    if (!selected || !form.user) {
      dispatch(
        addNotification({
          type: notifTags.error,
          message: "Must select an employee",
        })
      );
      return;
    }
    axios[selected._id ? "patch" : "post"](
      `${process.env.REACT_APP_API_URL}/timesheet`,
      {
        client: report ? null : form.client,
        start: moment.tz(form.start, "YYYY-MM-DDThh:mmA", form.timezone).utc(),
        end: byHours
          ? moment
              .tz(form.start, "YYYY-MM-DDThh:mmA", form.timezone)
              .utc()
              .add(parseFloat(form.hours), "hours")
          : moment.tz(form.end, "YYYY-MM-DDThh:mmA ", form.timezone).utc(),
        createdBy: cookie.get("id"),
        id: selected._id,
        payRate: form.payRate * 100,
        billRate: form.billRate * 100,
        overtimePayRate: form.overtimePayRate * 100,
        overtimeBillRate: form.overtimeBillRate * 100,
        position: selected._id ? form.position : form.user.position,
        user: selected._id ? undefined : form.user.user._id,
        subtractLunch: form.subtractLunch,
      }
    )
      .then((res) => {
        setError("");
        dispatch(
          addNotification({
            type: notifTags.success,
            message: "Time entry added",
          })
        );
        onClose();
        setCustomPay(null);
      })
      .catch(async (err) => {
        const { message, action } = await errorHandler(err);
        setError(message);
        dispatch(
          addNotification({
            type: notifTags.error,
            message,
          })
        );
      })
      .finally(() => setLoading(false));
  };

  const setFieldValue = (name, value) => {
    setForm((old) => ({ ...old, [name]: value }));
  };
  useEffect(() => {
    if (!selected) setByHours(false);
  }, [selected]);
  if (!selected) return null;

  return (
    <Modal
      onClose={() => {
        setError("");
        setCustomPay(null);
        onClose();
      }}
      centered
      closeIcon
      style={{
        textAlign: "center",
        backgroundColor: "white",
        padding: "2vh",
        maxWidth: 700,
      }}
    >
      {loading ? (
        <Loader active size="large" />
      ) : (
        <Main>
          <Header as="h1" style={{ color: theme.MAIN_COLOR }}>
            {!selected._id ? "Add Time Entry" : "Edit Time Entry"}
          </Header>
          <Header
            as="h3"
            style={{
              color: selected._id && selected.flagged ? "red" : "black",
            }}
          >
            {(selected._id &&
              selected.flagged &&
              !selected.gps &&
              "Missing GPS location") ||
              (selected._id &&
                !selected.flagged &&
                !selected.gps &&
                "Added Via Time Station")}
          </Header>
          <FormRow>
            {!report && (
              <SelectorSearch
                label="Client"
                error={errors.client}
                onSelect={(v) => {
                  setFieldValue("client", v);
                  if (v) {
                    let temp = clients.find((c) => c.value === v);
                    setFieldValue("timezone", temp.timezone);
                  }
                  setClientSearch("");
                  fetchPositions();
                }}
                onSearchChange={(v) => {
                  setClientSearch(v.target.value);
                  fetchClients();
                }}
                value={form.client}
                options={clients}
              />
            )}
            {selected._id ? (
              <Selector
                label="Position"
                error={errors.position}
                onSelect={(v) => {
                  setFieldValue("position", v);

                  let emp = positions.find((e) => e.value === v);
                  setFieldValue(
                    "payRate",
                    formatDollars(emp.employee.payRate / 100)
                  );
                  setFieldValue(
                    "billRate",
                    formatDollars(emp.employee.billRate / 100)
                  );
                  setFieldValue(
                    "overtimePayRate",
                    formatDollars(emp.employee.overtimePayRate / 100)
                  );
                  setFieldValue(
                    "overtimeBillRate",
                    formatDollars(emp.employee.overtimeBillRate / 100)
                  );
                }}
                value={form.position}
                options={positions}
              />
            ) : (
              <SelectorSearch
                label="Employee"
                error={errors.user}
                placeholder="Select a client first"
                onSelect={(v) => {
                  setFieldValue("user", v);
                  setSearch("");
                }}
                onSearchChange={(v) => {
                  setSearch(v.target.value);
                  fetchUsers();
                }}
                value={form.user}
                options={users}
              />
            )}
          </FormRow>
          <FormRow>
            <Header
              as="h3"
              style={{
                color: theme.MAIN_COLOR,
                margin: "auto 0",
                width: "300px",
                flexDirection: "column",
                display: "flex",
              }}
            >
              Timezone: {timezoneValueToLabel(form.timezone)}
              <ToggleButton
                label="Subtract Lunch Break"
                onChange={() =>
                  setFieldValue("subtractLunch", !form.subtractLunch)
                }
                value={form.subtractLunch}
              />
            </Header>
            <Button
              style={{ marginBottom: "-10px", height: "50px" }}
              color="blue"
              onClick={() => setByHours(!byHours)}
            >
              Time Out By Hours
            </Button>
          </FormRow>
          <FormRow>
            <DatePicker
              label="Time In"
              error={errors.start}
              onChange={(v) => {
                setFieldValue("start", v);
              }}
              onFirst={(v) => !form.end && setFieldValue("end", v)}
              value={form.start}
            />
            {byHours ? (
              <Input
                label="Time Out"
                placeholder="hours"
                type="number"
                error={errors.end}
                onChange={(e) => setFieldValue("hours", e.currentTarget.value)}
              />
            ) : (
              <DatePicker
                label="Time Out"
                error={errors.end}
                min={form.start}
                onChange={(v) => setFieldValue("end", v)}
                value={form.end}
              />
            )}
          </FormRow>
          {!canEditPayRate ? null : !customPay ? (
            <Button
              style={{
                margin: "auto",
              }}
              type="button"
              onClick={() => setCustomPay(true)}
            >
              {selected.create ? "Set Custom Pay Rate" : " View Pay Rate"}
            </Button>
          ) : (
            <>
              <FormRow>
                <Input
                  label="Pay Rate"
                  name="payRate"
                  style={{ color: "blue" }}
                  onBlur={() => {
                    setFieldValue(
                      "payRate",
                      formatDollars(parseFloat(form.payRate))
                    );
                    setFieldValue(
                      "overtimePayRate",
                      formatDollars(parseFloat(form.payRate * 1.5))
                    );
                  }}
                  error={errors.payRate}
                  onChange={(value) =>
                    setFieldValue("payRate", parseFloat(value.target.value))
                  }
                  value={form.payRate}
                  type="number"
                  step=".01"
                />
                <Input
                  label="Bill Rate"
                  name="billRate"
                  style={{ color: "blue" }}
                  onBlur={() => {
                    setFieldValue(
                      "billRate",
                      parseFloat(form.billRate).toFixed(2)
                    );
                    setFieldValue(
                      "overtimeBillRate",
                      parseFloat(form.billRate * 1.5).toFixed(2)
                    );
                  }}
                  error={errors.billRate}
                  onChange={(value) =>
                    setFieldValue("billRate", value.target.value)
                  }
                  value={form.billRate}
                  type="number"
                  step=".01"
                />
              </FormRow>
              <FormRow>
                <Input
                  label="Ovetime Pay Rate"
                  name="overtimePayRate"
                  style={{ color: "blue" }}
                  onBlur={() => {
                    setFieldValue(
                      "overtimePayRate",
                      parseFloat(form.overtimePayRate).toFixed(2)
                    );
                  }}
                  error={errors.overtimePayRate}
                  onChange={(value) =>
                    setFieldValue(
                      "overtimePayRate",
                      parseFloat(value.target.value)
                    )
                  }
                  value={form.overtimePayRate}
                  type="number"
                  step=".01"
                />
                <Input
                  label="Ovetime Bill Rate"
                  name="overtimeBillRate"
                  style={{ color: "blue" }}
                  onBlur={() => {
                    setFieldValue(
                      "overtimeBillRate",
                      parseFloat(form.overtimeBillRate).toFixed(2)
                    );
                  }}
                  error={errors.overtimeBillRate}
                  onChange={(value) =>
                    setFieldValue("overtimeBillRate", value.target.value)
                  }
                  value={form.overtimeBillRate}
                  type="number"
                  step=".01"
                />
              </FormRow>
            </>
          )}
          <FormRow>
            {errors.general && (
              <div
                style={{
                  color: "red",
                  margin: "-1vh auto .5vh auto",
                  textAlign: "center",
                }}
              >
                {errors.general}
              </div>
            )}
            {
              <Header style={{ margin: "auto" }} color="red" textAlign="center">
                {error}
              </Header>
            }
          </FormRow>
          {selected && (
            <NotesModal
              notes={selected.notes}
              type="timesheet"
              id={selected._id}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              type="button"
              fluid
              disabled={loading}
              color="red"
              onClick={selected.create ? onClose : deleteTime}
            >
              {selected.create ? "Cancel" : "Delete Time Entry"}
            </Button>
            <Button fluid disabled={loading} color="blue" onClick={onSubmit}>
              {selected._id ? "Edit Time Entry" : "Add Time Entry"}
            </Button>
          </div>
          <Confirm
            open={confirm}
            message={"Leave without saving changes?"}
            onConfirm={() => {
              setConfirm(false);
              onClose();
            }}
            onDeny={() => setConfirm(false)}
          />
        </Main>
      )}
    </Modal>
  );
};

export default TimeModal;
