import React from "react";
import Login from "./pages/user/Login.jsx";
import Home from "./pages/home/Home.jsx";
import SignUp from "./pages/user/SignUp.js";
import PasswordReset from "./pages/user/PasswordReset";
import NewPassword from "./pages/user/NewPassword";
import Users from "./pages/users";
import Groups from "./pages/groups";
import Timesheets from "./pages/timesheets/";
import Clients from "./pages/clients";
import Analytics from "./pages/analytics/Analytics.js";
import PhoneReset from "./pages/user/PhoneReset.js";
import TimeStation from "./pages/timesheets/TimeStation.js";
import Invoice from "./pages/invoice";
import Bugs from "./pages/bugs/index.jsx";
import BugDetails from "./pages/bugs/BugDetails";
import Logout from "pages/home/Logout.js";
import Faq from "./pages/tutorial/Faq";
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
} from "react-router-dom";
import cookie from "js-cookie";
import axios from "axios";
import {clearCookies} from "globalResources/helpers/index.js";
import {NavigationBar} from "./globalComponents";
import {setUser} from "store/slices/userSlice.js";
import HandleLoginLink from "pages/user/HandleLoginLink.jsx";
import BatchEntries from "pages/timesheets/BatchEntries.js";
import ExternalTimeStation from "pages/external/ExternalTimeStation.jsx";
import ClientDetails from "pages/clients/ClientDetails.jsx";
import RateEditor from "pages/timesheets/components/RateEditor.jsx";
import TimeEntryImport from "pages/timesheets/TimeEntryImport.jsx";
import ViewReport from "pages/accounting/components/ViewReport.js";
import Notification from "globalComponents/Notification.jsx";
import UserDetails from "pages/users/UserDetails.jsx";
import GroupDetails from "pages/groups/GroupDetails.jsx";
import UserImport from "pages/users/UserImport.jsx";
import DownloadPage from "pages/accounting/DownloadPage.jsx";
import Payroll from "pages/accounting/payroll/index.jsx";
import PayrollManagement from "pages/accounting/payroll/PayrollManagement.jsx";
import ViewPayrollReport from "pages/accounting/payroll/ViewPayrollReport.jsx";
import ReportList from "pages/accounting/components/ReportList.js";
import Adjustment from "pages/accounting/adjustments";

class App extends React.Component {
	render() {
		let token = cookie.get("token");
		if (!token) {
			clearCookies(true);
			setUser({});
		}

		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;

		axios.defaults.baseURL = process.env.REACT_APP_API_URL;
		return (
			<Router>
				<Notification />
				{token && <NavigationBar />}
				{token ? (
					<Switch>
						<Route path="/bugs" component={Bugs} />
						<Route path="/bug/:id/details" component={BugDetails} />
						<Route path="/faq" component={Faq} />
						<Route path="/invoice" component={Invoice} />
						<Route exact path="/home" component={Home} />
						<Route exact path="/users" component={Users} />
						<Route path="/user/:id/details" component={UserDetails} />
						<Route path="/users/import" component={UserImport} />
						<Route path="/groups" component={Groups} />
						<Route path="/group/:id/details" component={GroupDetails} />
						<Route exact path="/timesheets" component={Timesheets} />
						<Route path="/clients" component={Clients} />
						<Route path="/client/:id/details" component={ClientDetails} />
						<Route path="/accounting/report/:id" component={ViewReport} />
						<Route path="/accounting/adjustment/:id" component={ViewReport} />
						<Route
							path="/accounting/payroll/run"
							component={PayrollManagement}
						/>
						<Route
							exact
							path="/accounting/payroll/:id"
							component={ViewPayrollReport}
						/>
						<Route path="/accounting/adjustment" component={Adjustment} />
						<Route exact path="/accounting/payroll" component={Payroll} />
						<Route path="/accounting/billing" component={ReportList} />
						<Redirect exact path="/accounting" to="/accounting/billing" />
						<Route path="/analytics" component={Analytics} />
						<Route path="/timesheets/timestation" component={TimeStation} />
						<Route path="/timesheets/batch" component={BatchEntries} />
						<Route path="/timesheets/import" component={TimeEntryImport} />
						<Route path="/timesheets/rate-editor" component={RateEditor} />
						<Route path="/timestation" component={ExternalTimeStation} />
						<Route path="/login/link/:token" component={HandleLoginLink} />
						<Route path="/report/link" component={DownloadPage} />
						<Route path="/logout" component={Logout} />
						<Redirect to="/home" />
					</Switch>
				) : (
					<Switch>
						<Route path="/faq" component={Faq} />
						<Route path="/invoice" component={Invoice} />
						<Route path="/login" exact component={Login} />
						<Route path="/signup" component={SignUp} />
						<Route path="/password" component={PasswordReset} />
						<Route path="/phoneReset" component={PhoneReset} />
						<Route path="/user/password" component={NewPassword} />
						<Route path="/login/link/:token" component={HandleLoginLink} />
						<Route path="/timestation" component={ExternalTimeStation} />
						<Route path="/report/link" component={DownloadPage} />
						<Redirect to="/login" />
						{
							// if we dont have a token and they aren't on onboarding or login we //
							// redirect them to login
						}
					</Switch>
				)}
			</Router>
		);
	}
}

export default App;
