import axios from "axios";
import {
	Button,
	FolderContainer,
	SearchBar,
	Table,
	ToggleButton,
} from "globalComponents";
import GroupEdit from "pages/admin/forms/GroupEdit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Group from "../admin/forms/Group";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const headers = [
	{
		label: "Name",
		sortBy: "name",
		render: row => <div>{row.name}</div>,
	},
	{ label: "Description", render: row => <div>{row.description}</div> },
];
const Main = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 5px;
`;

const CreateButton = styled(Button)`
	margin-left: auto;
	width: fit-content;
	position: absolute;
	right: 10px;
	font-weight: 600;
`;

const Groups = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const currentUser = useSelector(state => state.current.user);

	const cachedFilters = location?.state?.cachedFilters
		? location?.state?.cachedFilters
		: null;

	const [search, setSearch] = useState(
		cachedFilters ? cachedFilters.search : ""
	);
	const [groups, setGroups] = useState([]);
	const [selected, setSelected] = useState();
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState(
		cachedFilters
			? cachedFilters.pagination
			: {
					page: 1,
					limit: 20,
					total: 0,
			  }
	);
	const [sort, setSort] = useState({ sort: "name", direction: 1 });
	const [showDisabled, setShowDisabled] = useState(
		cachedFilters ? cachedFilters.showDisabled : false
	);

	useEffect(() => {
		if (!selected) {
			setLoading(true);
			axios
				.get("/groups", {
					params: { search, ...sort, ...pagination, isArchived: showDisabled },
				})
				.then(res => {
					setGroups(res.data.groups);
					setPagination(old => ({ ...old, total: res.data.total }));
				})
				.finally(() => setLoading(false));
		}
	}, [search, pagination.page, pagination.limit, selected, sort, showDisabled]);

	const getSelected = id => {
		setLoading(true);
		axios
			.get("/group", { params: { id } })
			.then(res => setSelected({ ...res.data.group, team: res.data.team }))
			.finally(() => setLoading(false));
	};

	return (
		<Main>
			<CreateButton onClick={() => setSelected({})}>
				+ Create Group
			</CreateButton>
			<FolderContainer title="Groups">
				<SearchBar
					search={search}
					onSearch={setSearch}
					style={{ margin: "10px auto", width: "50%" }}
				/>{" "}
				<ToggleButton
					label="Show Disabled"
					onChange={() => setShowDisabled(!showDisabled)}
					value={showDisabled}
				/>
				<Table
					data={groups}
					loading={loading}
					headers={headers}
					pagination={pagination}
					onClick={row => {
						history.push({
							pathname: `/group/${row._id}/details`,
							state: { cachedFilters: { showDisabled, search, pagination } },
						});
					}}
					onPage={page => setPagination(old => ({ ...old, page }))}
					onLimit={limit => {
						setPagination(old => ({ ...old, limit }));
					}}
					sortBy={sort.sort}
					sortDirection={sort.direction}
					onSort={(name, direction) => setSort({ sort: name, direction })}
				/>
			</FolderContainer>
			<GroupEdit selected={selected} onClose={() => setSelected(null)} />
		</Main>
	);
};
export default Groups;
