import React from "react";
import { Button, Form, Grid, Image, Message } from "semantic-ui-react";
import Logo from "../../assets/logos/full.png";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../../Theme.js";

class PhoneReset extends React.Component {
  render() {
    return (
      <Formik
        initialValues={{ phone: "" }}
        onSubmit={(values, actions) => {
          axios
            .patch(`${process.env.REACT_APP_API_URL}/root/reset`, {
              phone: values.phone,
            })
            .then((res) => {
              actions.setSubmitting(false);

              //set token
              alert("Enter the verification code on the next page");
              this.props.history.push("/user/password");
            })
            .catch((error) => {
              actions.setFieldError("general", "error resetting password");
              actions.setSubmitting(false);
            });
        }}
        render={(props) => (
          <Grid
            centered
            textAlign="center"
            style={{ height: "100vh", backgroundColor: "rgba(0,0,0,.7)" }}
            verticalAlign="middle"
          >
            <Grid.Column
              style={{
                maxWidth: 450,
                backgroundColor: "white",
                borderRadius: 10,
                boxShadow: "0 0 30px rgba(0,0,0,0.6)",
              }}
            >
              <Image
                style={{
                  margin: "0vh auto 1vh auto",
                  height: 200,
                }}
                src={Logo}
              />
              <Form size="large" onSubmit={props.handleSubmit}>
                <Message
                  style={{
                    border: "2px solid rgba(0,0,0,0.6)",
                    backgroundColor: Theme.PAGE_BACKGROUND,
                    marginBottom: "1vh",
                  }}
                >
                  <Form.Input
                    name="phone"
                    error={
                      props.errors.phone && props.touched.phone
                        ? props.errors.phone
                        : false
                    }
                    onChange={props.handleChange}
                    onBlur={() => props.setFieldTouched("firstName")}
                    style={{ color: "blue" }}
                    fluid
                    type="phone"
                    icon="phone"
                    iconPosition="left"
                    placeholder="Phone Number"
                  />
                  {props.errors.general && (
                    <div
                      style={{
                        color: "blue",
                        margin: "-1vh auto .5vh auto",
                        textAlign: "center",
                      }}
                    >
                      {props.errors.general}
                    </div>
                  )}
                  <Button
                    type="submit"
                    disabled={props.isSubmitting}
                    loading={props.isSubmitting}
                    color="blue"
                    onClick={props.handleSubmit}
                    fluid
                    size="large"
                  >
                    Request Verification Code
                  </Button>
                </Message>
                <Message
                  style={{
                    border: "2px solid rgba(0,0,0,0.6)",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: Theme.PAGE_BACKGROUND,
                  }}
                >
                  You will recieve a text message with a verification code
                  shortly.
                </Message>
              </Form>
            </Grid.Column>
          </Grid>
        )}
      />
    );
  }
}

export default PhoneReset;
