import React, { Component } from "react";
import {
  Icon,
  Table,
  Header,
  Loader,
  Menu,
  Select,
  Popup,
} from "semantic-ui-react";

class StandardTable extends Component {
  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.setState({ openSettings: false });
        }
      } catch (error) {}
    }
  };

  getPages = () => {
    const { page, total, limit, onPage } = this.props;
    const totalPages = Math.ceil(total / limit);
    const pageGroup = parseInt(page / 6);
    var pages = [
      <Menu.Item
        key={1}
        name={"1"}
        onClick={(e, { name }) => onPage(name)}
        as="a"
        disabled={1 === page}
      >
        1
      </Menu.Item>,
    ];
    // add last page
    if (total > 1 && totalPages > 1)
      pages[totalPages] = (
        <Menu.Item
          key={totalPages}
          name={totalPages.toString()}
          onClick={(e, { name }) => onPage(name)}
          as="a"
          disabled={totalPages === page}
        >
          {totalPages}
        </Menu.Item>
      );

    //add in ... before page group
    if (pageGroup > 0)
      pages[1] = (
        <Menu.Item
          onClick={(e, { name }) => onPage(pageGroup * 6 - 1)}
          as="a"
          disabled={pageGroup * 6 - 1 === page}
        >
          ...
        </Menu.Item>
      );
    //add in ... after page group
    if (totalPages > 6 && totalPages > pageGroup * 6 + 5)
      pages[pageGroup * 6 + 6] = (
        <Menu.Item
          onClick={(e) => onPage(pageGroup * 6 + 6)}
          as="a"
          disabled={pageGroup * 6 + 6 === page}
        >
          ...
        </Menu.Item>
      );
    if (totalPages > 1)
      for (
        var i = pageGroup * 6;
        i < pageGroup * 6 + 6 && i < totalPages;
        i++
      ) {
        if (i > 1)
          pages[i] = (
            <Menu.Item
              key={i}
              name={i.toString()}
              onClick={(e, { name }) => onPage(name)}
              as="a"
              disabled={i.toString() === page}
            >
              {i}
            </Menu.Item>
          );
      }
    return pages;
  };
  render() {
    const {
      headers,
      data,
      loading,
      containerStyle,
      onSelect,
      page,
      total,
      limit,
      onPage,
      onLimit,
      onSort,
      sort,
      direction,
    } = this.props;
    return (
      <div
        style={
          containerStyle || {
            width: "100%",
            margin: "2% auto auto auto",
            overflowX: "scroll",
          }
        }
      >
        {loading ? (
          <div
            style={{
              height: 200,
            }}
          >
            <Loader
              active
              centered
              size="large"
              inverted
              style={{ color: "white" }}
            >
              Loading
            </Loader>
          </div>
        ) : (
          <Table selectable celled unstackable inverted striped>
            <Table.Header>
              <Table.Row>
                {headers.map((header, index) => {
                  return (
                    <Table.HeaderCell key={index} style={{ fontSize: 14 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {header.sortName ? (
                          <div
                            style={{
                              paddingRight: 5,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                                fontSize: 18,
                                margin: -5,
                                color:
                                  sort === header.sortName && direction === 1
                                    ? "red"
                                    : "white",
                              }}
                              type="button"
                              onClick={() => onSort(header.sortName, 1)}
                            >
                              <Icon name="caret up" />
                            </button>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                                fontSize: 18,
                                margin: -5,
                                color:
                                  sort === header.sortName && direction === -1
                                    ? "red"
                                    : "white",
                              }}
                              type="button"
                              onClick={() => onSort(header.sortName, -1)}
                            >
                              <Icon name="caret down" />
                            </button>
                          </div>
                        ) : null}
                        {header.label}
                      </div>
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.length < 1 ? (
                <Header as="h2" color="red">
                  No Results Found
                </Header>
              ) : (
                data.map((row, index) => {
                  return (
                    <Table.Row
                      key={`row-${index}`}
                      onClick={(e) => onSelect(row, e)}
                    >
                      {headers.map((header, index) => {
                        return (
                          <Table.Cell
                            key={`cell-${index}`}
                            collapsing={header.compact}
                            style={{ fontSize: 12 }}
                          >
                            {header.renderCell(row)}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={headers.length}>
                  <Menu floated="right" pagination>
                    <Menu.Item
                      as="a"
                      icon
                      disabled={page === 1}
                      onClick={() => onPage(page - 1)}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    {this.getPages()}
                    <Menu.Item
                      as="a"
                      icon
                      disabled={page >= Math.ceil(total / limit)}
                      onClick={() => onPage(page + 1)}
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                  <Popup
                    content="Number of rows"
                    trigger={
                      <Select
                        compact
                        value={limit}
                        onChange={(e, d) => onLimit(d.value)}
                        options={[
                          { value: 10, text: "10" },
                          { value: 20, text: "20" },
                          { value: 50, text: "50" },
                          { value: 100, text: "100" },
                          { value: 500, text: "500" },
                          { value: 1000, text: "1000" },
                        ]}
                      />
                    }
                  />
                  <Header
                    as="h4"
                    style={{ color: "white" }}
                  >{`Page ${page} of ${Math.ceil(total / limit)}`}</Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </div>
    );
  }
}

export default StandardTable;
