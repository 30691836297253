import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Modal from "./Modal";
import Button from "./Button";
import { errorHandler, isImage } from "../globalResources/util";
import FileUploader from "./FileUploader";
import styled from "styled-components";
import moment from "moment";
import { getTheme } from "Theme";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/slices/uiSlice";

const theme = getTheme();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 0px 30px;
  align-items: center;
  align-content: center;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  overflow: hidden;
  width: 640px;
  padding: 5px;
  gap: 15px;
`;

const Image = styled.img`
  width: 200px;
  object-fit: contain;
`;

const Preview = styled.a`
  font-size: 18px;
  font-weight: 800;
  color: ${theme.BUTTON_COLOR};
  margin: 10px auto;
  padding: 10px 50px;
  background-color: ${theme.INPUT_BACKGROUND};
  border-radius: 20px;
`;

const SubjectInput = styled.input`
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 5px;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
  user-select: auto;
  border-radius: 5px;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 4px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
`;

const FileUploadButton = styled.div`
  width: 100%;
`;

const SubmitButton = styled.div`
  width: 100%;
`;

const BodyInput = styled.textarea`
  margin: 5px;
  font-size: 18px;
  min-height: 300px;
  min-width: 100%;
  font-weight: 500;
  resize: vertical;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
  border-radius: 5px;
`;

const Previews = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.h2`
  color: red;
`;

const BugReport = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [subjectValue, setSubjectValue] = useState("");
  const [bodyInput, setBodyInput] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    if (!bodyInput) {
      setLoading(false);
    } else
      axios
        .post(`${process.env.REACT_APP_API_URL}/bug`, {
          description: bodyInput,
          files,
          subject: subjectValue,
        })
        .then(async (res) => {
          //on success notification
          dispatch(
            addNotification({
              type: "success",
              message: "Bug report created successfully",
            })
          );
          setLoading(false);
          history.push("/bugs");
        })
        .catch(async (error) => {
          setLoading(false);
          //on error notification
          const { message } = await errorHandler(error);
          dispatch(
            addNotification({
              type: "danger",
              message,
            })
          );
        });

    setSubjectValue("");
    setBodyInput("");
    setFiles([]);
    onClose();
  };

  if (!open) return null;

  return (
    <Modal onClose={onClose}>
      <Container>
        <Title>Report A Bug</Title>
        <Label id="subject">Subject</Label>
        <SubjectInput
          onChange={(e) => setSubjectValue(e.target.value)}
          htmlFor="subject"
          value={subjectValue}
        />
        <Label id="body">Describe your Issue below</Label>
        <BodyInput
          onChange={(e) => setBodyInput(e.target.value)}
          htmlFor="body"
          value={bodyInput}
        />

        {files.length > 0 && (
          <React.Fragment>
            <Previews>
              {files.map((file) => {
                if (!isImage(file)) {
                  let temp = file.split("/");
                  return (
                    <Preview href={file} target="_blank">
                      {temp[temp.length - 1]}
                    </Preview>
                  );
                }
                return null;
              })}
            </Previews>

            <Images>
              {files.map((file) => {
                if (isImage(file)) {
                  return <Image src={file} />;
                }
                return null;
              })}
            </Images>
          </React.Fragment>
        )}
        <ButtonRow>
          <FileUploadButton>
            <FileUploader
              label="Upload Screenshots"
              namePrefix={`bugreport/${moment().format("MM/DD/YYYY-hh:mm")}`}
              onUrl={(url) => setFiles((old) => old.concat([url]))}
            />
          </FileUploadButton>
          <SubmitButton>
            <Button style={{ height: "35.5px" }} onClick={onSubmit}>
              Submit Bug Report
            </Button>
          </SubmitButton>
        </ButtonRow>
        <br />
      </Container>
    </Modal>
  );
};

export default BugReport;
