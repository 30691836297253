import { PayrollFrequency, imgExtensions, vidExtensions } from "./constants";
import { clearCookies, formatDollars } from "./helpers";
import CSVIcon from "../assets/fileIcons/CSV.svg";
import PDFIcon from "../assets/fileIcons/PDF.svg";
import TXTIcon from "../assets/fileIcons/TXT.svg";
import VIDIcon from "../assets/fileIcons/VIDEO.svg";
import moment from "moment";
export const errorHandler = async (error) => {
  let message = "";
  let action = "";
  console.log(error.response);
  if (error.response) {
    switch (error.response.status) {
      case 400:
        message = "Provided values are invalid";
        action = "checkInput";
        break;
      case 401:
        //logout
        await clearCookies(
          error.response.config?.url?.includes("timestation"),
          error.response.config?.url?.includes("timestation")
        );
        window.location.replace("/home");
        break;
      case 403:
        message =
          error.response.data ||
          "You do not have permission to perform this action";
        break;
      case 404:
        message = error.response.data || "Not Found";
        break;
      case 405:
        message = error.response.data;
        break;
      default:
        message = error.response.data;
        break;
    }
    return { message, action, status: error.response.status };
  } else return { message, action };
};

export const getClientPermissions = () => {
  return [
    {
      label: "Ability to edit/create users at their locations",
      value: "users",
    },
    { label: "Ability to see Reports section and invoices", value: "reports" },
    { label: "Ability to approve reports", value: "approve" },
    { label: "Ability to view the analytics section", value: "analytics" },
    { label: "Ability to edit time entries", value: "timeentries" },
  ];
};

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function formatRate(rate) {
  return `$${formatDollars(rate / 100)}`;
}

export const isImage = (file) => {
  const type = file.substring(file.lastIndexOf(".") + 1).toUpperCase();
  if (imgExtensions.includes(type)) return true;
  return false;
};

export const getFileIcon = (file) => {
  if (file.includes(".csv") || file.includes(".xl")) return CSVIcon;
  if (file.includes(".pdf")) return PDFIcon;
  if (vidExtensions.includes(file.split(".")[1])) return VIDIcon;
  if (isImage(file)) return file;
  return TXTIcon;
};

export const roundTwoDecimal = (number) => {
  return Math.round(number * 100) / 100;
};

export const determineNextRun = (frequency, currentDate) => {
  const date = moment(currentDate);

  switch (frequency) {
    case PayrollFrequency.DAILY:
      return date.format("L");
    case PayrollFrequency.WEEKLY:
      return (
        date.clone().subtract(6, "day").format("L") + " - " + date.format("L")
      );
    case PayrollFrequency.BI_MONTHLY:
      if (parseInt(date.format("D")) > 15) {
        return (
          date.clone().set("date", 16).format("L") +
          " - " +
          date.clone().endOf("month").format("L")
        );
      } else {
        return (
          date.clone().set("date", 1).format("L") +
          " - " +
          date.clone().set("date", 15).format("L")
        );
      }
    case PayrollFrequency.MONTHLY:
      return (
        date.clone().subtract(1, "month").add(1, "day").format("L") +
        " - " +
        date.format("L")
      );
    default:
      return date.format("L");
  }
};

export const sortUsers = (a, b) => {
  if (!a.user.firstName) return 1;
  if (a.user.firstName.toLowerCase() === b.user.firstName.toLowerCase()) {
    if (a.user.lastName.toLowerCase() === b.user.lastName.toLowerCase())
      return a.user._id > b.user._id ? 1 : -1;
    return a.user.lastName.toLowerCase() > b.user.lastName.toLowerCase()
      ? 1
      : -1;
  } else
    return a.user.firstName.toLowerCase() > b.user.firstName.toLowerCase()
      ? 1
      : -1;
};

export const adjustmentSignByType = (type) => {
  switch (type) {
    case "credit":
      return -1;
    case "deduction":
      return -1;
    default:
      return 1;
  }
};

export const timezoneValueToLabel = (timezone) => {
  switch (timezone) {
    case "America/Chicago":
      return "Eastern";
    case "America/Louisville":
      return "Central";
    case "America/Denver":
      return "Mountain";
    case "America/Los_Angeles":
      return "Pacific";
    default:
      return timezone;
  }
};
