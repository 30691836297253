import React from "react";
import { FolderContainer } from "globalComponents";
import BugList from "./components/BugList";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bugs = () => {
  return (
    <Main>
      <FolderContainer title="Bugs">
        <BugList />
      </FolderContainer>
    </Main>
  );
};

export default Bugs;
