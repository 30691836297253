import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 4px;
`;

const Error = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.NEGATIVE};
`;

const TextInput = styled.input`
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
  user-select: auto;
  border-radius: 5px;
`;

const Input = (props) => {
  const { label, type, error, onEnter } = props;

  const handleKeyPress = (event) => {
    switch (event.key) {
      case "Enter":
        onEnter && onEnter(event);
        return;
      default:
        return;
    }
  };

  return (
    <Main>
      <Label>{label}</Label>
      <TextInput
        onKeyDown={handleKeyPress}
        type={type === "currency" ? "string" : type}
        error={!!error}
        {...props}
      />
      <Error>{error}</Error>
    </Main>
  );
};
export default Input;
