import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  AccountingFolderContainer,
  Button,
  FilterBar,
  ButtonRow,
  Table,
  DatePicker,
} from "globalComponents";
import { notifTags } from "globalComponents/Notification";
import { formatDollars } from "globalResources/helpers";
import { errorHandler } from "globalResources/util";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { addNotification } from "store/slices/uiSlice";
import styled from "styled-components";
import AdjustmentModal from "./AdjustmentModal";
import ExportCSV from "globalComponents/ExportCSV";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const columns = [
  { label: "Client", render: (row) => row.client?.name || "N/A" },
  { label: "Title", render: (row) => row.title },
  {
    label: "Date",
    sortBy: "createdAt",
    render: (row) => moment.utc(row.createdAt).format("ll"),
  },
  {
    label: "Amount",
    render: (row) => "$" + formatDollars(row.amount / 100),
  },
  { label: "Created by", render: (row) => row.createdBy },
  { label: "Type", render: (row) => row.type },
  {
    label: "Reported",
    render: (row) => (
      <FontAwesomeIcon
        icon={row.report ? faCheckCircle : faXmarkCircle}
        color={row.report ? "green" : "red"}
      />
    ),
  },
];

const Adjustments = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [adjustments, setAdjustments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [daterange, setDaterange] = useState({ start: "", end: "" });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
  });
  const [sort, setSort] = useState({ sortBy: "createdAt", direction: -1 });
  const [adjustment, setAdjustment] = useState(null);

  async function getAdjustments() {
    setLoading(true);
    try {
      let res = await axios.get("/adjustments", {
        params: { ...sort, ...pagination, ...daterange },
      });
      setAdjustments(res.data.adjustments);
      setPagination((old) => ({ ...old, total: res.data.total }));
    } catch (err) {
      const { message } = await errorHandler(err);

      dispatch(
        addNotification({
          message: "Failed to get adjustments",
          type: notifTags.error,
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function getAdjustment(id) {
    setLoading(true);
    try {
      let res = await axios.get(`/adjustment/${id}`);
      setAdjustment(res.data);
    } catch (err) {
      const { message } = await errorHandler(err);

      dispatch(
        addNotification({
          message: "Failed to get adjustment",
          type: notifTags.error,
        })
      );
    } finally {
      setLoading(false);
    }
  }
  const formatForCSV = () => {
    let formatted = [];
    adjustments.map((row) => {
      formatted.push({
        client: row.client?.name,
        title: row.title,
        date: moment.utc(row.createdAt).format("ll"),
        amount: formatDollars(row.amount / 100),
        createdBy: row.createdBy,
        type: row.type,
        reported: !!row.report,
      });
    });
    return formatted;
  };

  useEffect(() => {
    getAdjustments();
  }, [sort, pagination.limit, pagination.page, daterange]);
  return (
    <AccountingFolderContainer>
      <Main>
        <ButtonRow>
          <Button
            margin="10px 10px 10px auto"
            width="200px"
            onClick={() => setAdjustment({})}
          >
            Create Adjustment
          </Button>
        </ButtonRow>
        <FilterBar>
          <DatePicker
            type="date"
            label="Start"
            width="20%"
            max={moment(daterange.end).format("YYYY-MM-DD")}
            onChange={(v) => {
              setDaterange((old) => ({ ...old, start: v }));
            }}
            onFirst={(v) =>
              !daterange.end &&
              setDaterange((old) => ({
                ...old,
                end: moment(v).add(6, "days").format("YYYY-MM-DD"),
              }))
            }
            value={moment(daterange.start).format("YYYY-MM-DD")}
          />{" "}
          <DatePicker
            type="date"
            label="End"
            width="20%"
            min={moment(daterange.start).format("YYYY-MM-DD")}
            onChange={(v) => setDaterange((old) => ({ ...old, end: v }))}
            value={moment(daterange.end).format("YYYY-MM-DD")}
          />
          <ExportCSV data={formatForCSV()} filename="timesheetsTable" />
        </FilterBar>
        <Table
          data={adjustments}
          headers={columns}
          loading={loading}
          pagination={pagination}
          sortBy={sort.sortBy}
          sortDirection={sort.direction}
          onLimit={(num) => setPagination((old) => ({ ...old, limit: num }))}
          onPage={(num) => setPagination((old) => ({ ...old, page: num }))}
          onSort={(sortBy, direction) => setSort({ sortBy, direction })}
          onClick={(row) => getAdjustment(row._id)}
        />
      </Main>
      {adjustment && (
        <AdjustmentModal
          adjustment={{ ...adjustment, client: adjustment.client?._id }}
          onClose={() => {
            getAdjustments();
            setAdjustment(null);
          }}
        />
      )}
    </AccountingFolderContainer>
  );
};
export default Adjustments;
