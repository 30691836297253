import React, { useEffect, useState } from "react";
import { Button, Header } from "semantic-ui-react";

import moment from "moment-timezone";
import NotesModal from "globalComponents/NotesModal";
import theme from "Theme";
import styled from "styled-components";
import { Input, Confirm, DatePicker, Modal, FormRow } from "globalComponents";
import { useSelector } from "react-redux";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const initialForm = {
  UID: "",
  client: "",
  payRate: 0,
  position: undefined,
  billRate: 0,
  overtimePayRate: 0,
  overtimeBillRate: 0,
  start: "",
  end: "",
};

const TimeImportEdit = ({
  selected,
  onClose,
  report,
  deleteTime,
  onSubmit,
}) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({
    ...initialForm,
    timezone: "",
    payRate: "",
    billRate: "",
    overtimePayRate: "",
    overtimeBillRate: "",
    position: "",
  });
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const [clientSearch, setClientSearch] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [customPay, setCustomPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [positions, setPositions] = useState([]);

  //TODO: replace this with permissions in redux let canEditPayRate = checkPermission("editPayRate");

  const canEditPayRate = useSelector((state) =>
    state.current.permissions.includes("editPayRates")
  );

  useEffect(() => {
    if (selected)
      setForm({
        UID: selected.UID,
        client: selected.client,
        start: moment(selected.start).format("YYYY-MM-DDTHH:mm"),
        end: moment(selected.end).format("YYYY-MM-DDTHH:mm"),
        position: selected.position,
        payRate: selected.payRate
          ? (selected.payRate / 100).toFixed(2)
          : undefined,
        billRate: selected.billRate
          ? (selected.billRate / 100).toFixed(2)
          : undefined,
      });
    else setForm(initialForm);
  }, [selected]);

  const setFieldValue = (name, value) => {
    setForm((old) => ({ ...old, [name]: value }));
  };

  if (!selected) return null;

  return (
    <Modal
      onClose={() => {
        setError("");
        setCustomPay(null);
        onClose();
      }}
      centered
      closeIcon
      style={{
        textAlign: "center",
        backgroundColor: "white",
        padding: "2vh",
        maxWidth: 700,
      }}
    >
      <Main>
        <Header as="h1" style={{ color: theme.MAIN_COLOR }}>
          {!selected._id ? "Add Time Entry" : "Edit Time Entry"}
        </Header>
        <Header
          as="h3"
          style={{
            color: selected._id && selected.flagged ? "red" : "black",
          }}
        >
          {(selected._id &&
            selected.flagged &&
            !selected.gps &&
            "Missing GPS location") ||
            (selected._id &&
              !selected.flagged &&
              !selected.gps &&
              "Added Via Time Station")}
        </Header>
        <FormRow>
          {!report && (
            <Input
              label="Client"
              error={errors.client}
              onChange={(v) => {
                setFieldValue("client", v.currentTarget.value);
              }}
              value={form.client}
            />
          )}
        </FormRow>
        <FormRow>
          <Input
            label="Employee UID"
            error={errors.UID}
            placeholder="Select a client first"
            onChange={(v) => {
              setFieldValue("UID", v.currentTarget.value);
            }}
            value={form.UID}
          />
          <Input
            label="Position"
            error={errors.position}
            onChange={(v) => {
              setFieldValue("position", v.currentTarget.value);
            }}
            value={form.position}
          />
        </FormRow>
        <FormRow>
          <DatePicker
            label="Time In"
            error={errors.start}
            onChange={(v) => {
              setFieldValue("start", v);
            }}
            onFirst={(v) => !form.end && setFieldValue("end", v)}
            value={form.start}
          />
          <DatePicker
            label="Time Out"
            error={errors.end}
            min={form.start}
            onChange={(v) => setFieldValue("end", v)}
            value={form.end}
          />
        </FormRow>
        {selected.payRate && (
          <>
            <FormRow
              style={{ display: selected.create && !customPay && "none" }}
            >
              <Input
                label="Pay Rate"
                name="payRate"
                style={{ color: "blue" }}
                onBlur={() => {
                  setFieldValue("payRate", parseFloat(form.payRate).toFixed(2));
                  setFieldValue(
                    "overtimePayRate",
                    parseFloat(form.payRate * 1.5).toFixed(2)
                  );
                }}
                error={errors.payRate}
                onChange={(value) =>
                  setFieldValue("payRate", parseFloat(value.target.value))
                }
                value={form.payRate}
                type="number"
                step=".01"
              />
              <Input
                label="Bill Rate"
                name="billRate"
                style={{ color: "blue" }}
                onBlur={() => {
                  setFieldValue(
                    "billRate",
                    parseFloat(form.billRate).toFixed(2)
                  );
                  setFieldValue(
                    "overtimeBillRate",
                    parseFloat(form.billRate * 1.5).toFixed(2)
                  );
                }}
                error={errors.billRate}
                onChange={(value) =>
                  setFieldValue("billRate", value.target.value)
                }
                value={form.billRate}
                type="number"
                step=".01"
              />
            </FormRow>
            <FormRow>
              <Input
                label="Ovetime Pay Rate"
                name="overtimePayRate"
                style={{ color: "blue" }}
                onBlur={() => {
                  setFieldValue(
                    "overtimePayRate",
                    parseFloat(form.overtimePayRate).toFixed(2)
                  );
                }}
                error={errors.overtimePayRate}
                onChange={(value) =>
                  setFieldValue(
                    "overtimePayRate",
                    parseFloat(value.target.value)
                  )
                }
                value={form.overtimePayRate}
                type="number"
                step=".01"
              />
              <Input
                label="Ovetime Bill Rate"
                name="overtimeBillRate"
                style={{ color: "blue" }}
                onBlur={() => {
                  setFieldValue(
                    "overtimeBillRate",
                    parseFloat(form.overtimeBillRate).toFixed(2)
                  );
                }}
                error={errors.overtimeBillRate}
                onChange={(value) =>
                  setFieldValue("overtimeBillRate", value.target.value)
                }
                value={form.overtimeBillRate}
                type="number"
                step=".01"
              />
            </FormRow>
          </>
        )}
        <FormRow>
          {errors.general && (
            <div
              style={{
                color: "red",
                margin: "-1vh auto .5vh auto",
                textAlign: "center",
              }}
            >
              {errors.general}
            </div>
          )}
          {
            <Header style={{ margin: "auto" }} color="red" textAlign="center">
              {error}
            </Header>
          }
        </FormRow>
        {selected && (
          <NotesModal
            notes={selected.notes}
            type="timesheet"
            id={selected._id}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Button
            type="button"
            fluid
            disabled={loading}
            color="red"
            onClick={deleteTime}
          >
            Delete Time Entry
          </Button>
          <Button
            fluid
            disabled={loading}
            color="blue"
            onClick={() => onSubmit(form)}
          >
            Edit Time Entry
          </Button>
        </div>
        <Confirm
          open={confirm}
          message={"Leave without saving changes?"}
          onConfirm={() => {
            setConfirm(false);
            onClose();
          }}
          onDeny={() => setConfirm(false)}
        />
      </Main>
    </Modal>
  );
};

export default TimeImportEdit;
