import moment from "moment";
import React, { Component, useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => props.width || "100%"};
  min-width: 100px;
`;
const Container = styled.div`
  display: flex;
  border: 2px solid ${theme.INPUT_BORDER};
  background: ${theme.INPUT_BACKGROUND};
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  user-select: auto;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
`;

const DateTime = styled.input`
  border: none;
  outline: none;
  background: none;
  width: 100%;
  color: ${theme.INPUT_TEXT};
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 4px;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 80%;
  margin-left: 1%;
`;

const DatePicker = (props) => {
  const {
    onChange,
    error,
    placeholder,
    label,
    fontSize,
    labelSize,
    labelColor,
    type,
    onKeyUp,
    min,
    max,
    width,
    onFirst,
  } = props;
  const [first, setFirst] = useState(false);

  const handleChange = (e) => {
    if (onFirst && !first) {
      let date = moment(e.target.value).format("yyyy");
      if (parseInt(date) >= 1000) {
        onFirst(e.target.value);
        setFirst(true);
      }
    }
    onChange(e.target.value, e);
  };

  return (
    <Main width={width}>
      <Label fontSize={fontSize} labelColor={labelColor} labelSize={labelSize}>
        {label}
      </Label>
      <Container>
        <DateTime
          {...props}
          min={min}
          max={max}
          type={type || "datetime-local"}
          onChange={(e) => handleChange(e)}
          error={!!error}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        />
      </Container>
      {!!error && <ErrorText>{error || "error"}</ErrorText>}
    </Main>
  );
};

export default DatePicker;
