import React from "react";
import { Button, Form, Grid, Image, Message, Icon } from "semantic-ui-react";
import Logo from "../../assets/logos/full.png";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../../Theme.js";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
class PasswordReset extends React.Component {
  render() {
    return (
      <Formik
        validationSchema={SignupSchema}
        initialValues={{ email: "" }}
        onSubmit={(values, actions) => {
          axios
            .patch(`${process.env.REACT_APP_API_URL}/root/reset`, {
              email: values.email,
            })
            .then((res) => {
              actions.setSubmitting(false);

              //set token
              alert(
                "Check your email and insert the code you recieve on the next page. Make sure to check your spam and promotions folders if its not in your inbox."
              );
              this.props.history.push("/user/password");
            })
            .catch((error) => {
              actions.setFieldError("general", "Error reseting password");
              actions.setSubmitting(false);
            });
        }}
        render={(props) => (
          <Grid
            centered
            textAlign="center"
            style={{ height: "100vh", backgroundColor: "rgba(0,0,0,.7)" }}
            verticalAlign="middle"
          >
            <Grid.Column
              style={{
                maxWidth: 450,
                backgroundColor: "white",
                borderRadius: 10,
                boxShadow: "0 0 30px rgba(0,0,0,0.6)",
              }}
            >
              <Image
                style={{
                  margin: "0vh auto 1vh auto",
                  height: 200,
                }}
                src={Logo}
              />
              <Form size="large" onSubmit={props.handleSubmit}>
                <Message
                  style={{
                    border: "2px solid rgba(0,0,0,0.6)",
                    backgroundColor: Theme.PAGE_BACKGROUND,
                    marginBottom: "1vh",
                  }}
                >
                  <a
                    style={{
                      color: "blue",
                      fontSize: 20,
                      textAlign: "center",
                      marginLeft: "40%",
                    }}
                    href="phoneReset"
                  >
                    Use Phone Number <Icon name="right arrow" />
                  </a>
                  <Form.Input
                    name="email"
                    error={
                      props.errors.email && props.touched.email
                        ? props.errors.email
                        : false
                    }
                    onChange={props.handleChange}
                    onBlur={() => props.setFieldTouched("firstName")}
                    style={{ color: "blue", marginTop: 10 }}
                    fluid
                    type="email"
                    icon="envelope"
                    iconPosition="left"
                    placeholder="E-mail address"
                  />
                  {props.errors.general && (
                    <div
                      style={{
                        color: "blue",
                        margin: "-1vh auto .5vh auto",
                        textAlign: "center",
                      }}
                    >
                      {props.errors.general}
                    </div>
                  )}
                  <Button
                    type="submit"
                    disabled={props.isSubmitting}
                    loading={props.isSubmitting}
                    color="blue"
                    onClick={props.handleSubmit}
                    fluid
                    size="large"
                  >
                    Request Reset
                  </Button>
                </Message>
                <Message
                  style={{
                    border: "2px solid rgba(0,0,0,0.6)",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: Theme.PAGE_BACKGROUND,
                  }}
                >
                  You will recieve an email with a link to set a new password.
                  <br />
                  The email may take a few minutes to send.
                </Message>
              </Form>
            </Grid.Column>
          </Grid>
        )}
      />
    );
  }
}

export default PasswordReset;
