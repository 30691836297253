import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
import {
  faChevronDown,
  faBars,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BugReport from "./BugReport.jsx";
import axios from "axios";
import cookie from "js-cookie";
import { clearCookies } from "globalResources/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setAccounts, setUser } from "store/slices/userSlice";
import { errorHandler } from "globalResources/util";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  background: ${theme.PAGE_BACKGROUND};
  top: 0;
  z-index: 90;
  padding-bottom: 5px;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.MAIN_COLOR};
  height: 35px;
  color: ${theme.TEXT};
  align-items: center;
  padding: 0 10vw;
  @media (max-width: 1100px) {
    padding: 0 5px 0 0;
  }
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  height: 28px;
  border-bottom: 1.5px solid ${theme.BORDER};
  align-items: center;
  margin: 0 10vw;
  gap: 8px;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 50px;
`;

const Profile = styled.img`
  height: 28px;
  width: 28px;
  border: none;
  margin: 7.5px;
  border-radius: 100%;
  background-color: ${theme.ICONS};
`;

const Name = styled.div`
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin: auto auto auto 4px;
  cursor: pointer;
  color: ${theme.TEXT};
  padding: 4px;
  :hover {
    background: ${theme.SECONDARY_COLOR};
    border-radius: 100px;
  }
`;

const Route = styled(Link)`
  color: ${(props) => (props.current ? theme.MAIN_COLOR : theme.TEXT)};
  text-decoration: none;
  font-size: 16px;
  padding: 0 5px;
  font-weight: 520;
  :hover {
    color: ${theme.MAIN_COLOR};
  }
`;

const SMS = styled.div`
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin: auto auto auto 4px;
  cursor: pointer;
  color: ${theme.MANILLA};
  padding: 4px;
  margin: auto 0 0 auto;
`;

const Clickable = styled.div`
  cursor: pointer;
  font-size: 16px;
  :hover {
    background: ${theme.SECONDARY_COLOR};
    border-radius: 100px;
  }
`;

const SettingsButton = styled(FontAwesomeIcon)`
  font-size: 26px;
  color: ${theme.TEXT};
  margin: auto 6px;
`;

const Drawer = styled.div`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
  gap: 10px;
  position: absolute;
  z-index: 99;
  background: ${theme.MANILLA};
  width: 250px;
  height: 102vh;
  right: -4px;
  top: 35px;
  padding: 10px;
  border-left: 2px solid black;
`;

const Title = styled.div`
  display: flex;
  font-size: 22px;
  font-weight: 800;
  color: ${theme.TEXT};
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 35px;
  position: absolute;
  width: 50%;
  left: 25%;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${theme.TEXT};
  margin: 8px 0px;
`;

const SwitchUser = styled.div`
  position: fixed;
  top: 35px;
  left: 0;
  width: 300px;
  background: white;
  box-shadow: 10px 5px 10px ${theme.SHADOW};
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
`;

const routes = [
  {
    name: "Dashboard",
    link: "/home",
  },
  {
    name: "Users",
    link: "/users",
    permission: "manageUsers",
    clientPermission: "users",
  },
  { name: "Groups", link: "/groups", permission: "manageGroups" },
  {
    name: "Time Entries",
    link: "/timesheets",
    permission: "manageUserTimeSheets",
    clientPermission: "timeentries",
  },
  {
    name: "Clients",
    link: "/clients",
    permission: "manageClients",
  },
  {
    name: "Accounting",
    link: "/accounting",
    permission: "manageReports",
    clientPermission: "reports",
  },
  {
    name: "Analytics",
    link: "/analytics",
    permission: "viewAnalytics",
    clientPermission: "analytics",
  },
  {
    name: "Bugs",
    link: "/bugs",
  },
];

const NavigationBar = () => {
  const [bug, setBug] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.current.user);
  const accounts = useSelector((state) => state.current.accounts);
  const permissions = useSelector((state) => state.current.permissions);
  const location = useLocation();

  const ref = useRef(null);
  const drawerRef = useRef(null);

  useEffect(() => {
    axios
      .get(`/user/current`)
      .then((res) => {
        dispatch(setUser({ ...res.data }));
        getAccounts();
      })
      .catch((err) => {
        let { message, action } = errorHandler(err);
      });
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setAccountModalOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef]);

  const checkPermission = (route) => {
    if (!route.permission || user.accountType === "super") {
      return true;
    } else if (user.accountType === "client") {
      if (!route.clientPermission) return false;
      else if (route.clientPermission === "all") return true;
      return permissions.includes(route.clientPermission);
    } else return permissions.includes(route.permission);
  };

  const getAccounts = () => {
    axios.get("/root/users").then((res) => {
      dispatch(setAccounts(res.data.users));
    });
  };

  const switchAccounts = (userId, accountId) => {
    axios.patch("/root/switch", { userId, accountId }).then(async (res) => {
      dispatch(setUser(res.data.user));
      await clearCookies();
      //set token
      cookie.set("token", res.data.token, { expires: 0.9 }); //set cookie to expire in one day
      cookie.set("type", "user", { expires: 0.9 });
      cookie.set(
        "name",
        res.data.user.firstName + " " + res.data.user.lastName
      );
      cookie.set("id", res.data.user._id);
      axios.defaults.headers.common["Authorization"] = res.data.token;
      window.location.reload();
    });
  };

  return (
    <Main>
      <TopBar>
        <Logo src="/tcs.svg" alt="" />
        <Profile src="/default.jpg" />
        <Name onClick={() => setAccountModalOpen(!accountModalOpen)}>
          {user.firstName + " " + user.lastName}
          <FontAwesomeIcon
            size="s"
            color={theme.TEXT}
            icon={faChevronDown}
            style={{ marginLeft: "6px" }}
          />
        </Name>
        {accountModalOpen && (
          <SwitchUser ref={ref}>
            {accounts.map((account) => (
              <Name
                onClick={() =>
                  switchAccounts(account.user._id, account.account._id)
                }
              >
                {account.account.name} ({account.user.accountType})
              </Name>
            ))}
          </SwitchUser>
        )}
        {user.smsClockinDisabled === false && <SMS>SMS: 615-235-5338</SMS>}
        <Clickable>
          <SettingsButton icon={faBell} />
        </Clickable>
        <Clickable onClick={() => setDrawerOpen(!drawerOpen)}>
          <SettingsButton icon={faBars} />
        </Clickable>
      </TopBar>
      <BottomBar>
        {routes.map(
          (route, index) =>
            checkPermission(route) && (
              <Route
                to={route.link}
                current={location.pathname.includes(route.link)}
              >
                {route.name}
              </Route>
            )
        )}
        <Clickable style={{ marginLeft: "auto" }}>
          <Route to="/faq" style={{ color: theme.MAIN_COLOR }}>
            Tutorial Page
          </Route>
        </Clickable>
        <Clickable onClick={() => setBug(true)}>
          <Route style={{ color: theme.NEGATIVE }}>Report a Bug</Route>
        </Clickable>
      </BottomBar>
      <BugReport open={bug} onClose={() => setBug(false)} />

      <Drawer ref={drawerRef} active={drawerOpen}>
        {routes.map(
          (route, index) =>
            checkPermission(route) && (
              <Route
                to={route.link}
                current={window.location.pathname.includes(route.link)}
              >
                {route.name}
              </Route>
            )
        )}
        <Divider />
        <Clickable
          onClick={async () => {
            await cookie.remove("token");
            window.location.reload();
          }}
          style={{ marginLeft: "auto" }}
        >
          <Route>Logout</Route>
        </Clickable>

        <Clickable style={{ marginLeft: "auto" }}>
          <Route to="/faq" style={{ color: theme.MAIN_COLOR }}>
            Tutorial Page
          </Route>
        </Clickable>
        <Clickable onClick={() => setBug(true)} style={{ marginLeft: "auto" }}>
          <Route style={{ color: theme.NEGATIVE }}>Report a Bug</Route>
        </Clickable>
      </Drawer>
    </Main>
  );
};

export default NavigationBar;
