import axios from "axios";
import { Button, FormHeader, Input, Modal } from "globalComponents";
import { errorHandler } from "globalResources/util";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addNotification } from "store/slices/uiSlice";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CreateClientModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState({ name: "" });

  const onSubmit = async () => {
    try {
      const res = await axios.post(`/client`, {
        ...form,
      });
      history.push(`/client/${res.data.client._id}/details`);
      dispatch(
        addNotification({
          type: "success",
          message: "Created client successfully",
        })
      );
    } catch (err) {
      const { message } = await errorHandler(err);
      dispatch(addNotification({ type: "error", message }));
    }
  };

  return (
    <Modal onClose={onClose}>
      <Main>
        <FormHeader>Create New Client</FormHeader>
        <Input
          label="Name"
          value={form.name}
          onChange={(e) => setForm((old) => ({ ...old, name: e.target.value }))}
        />
        <Button onClick={onSubmit}>Create New Client</Button>
      </Main>
    </Modal>
  );
};
export default CreateClientModal;
