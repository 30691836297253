import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || "100%"};
  min-width: 100px;
`;
const Option = styled.option`
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 4px;
`;

const Input = styled.select`
  border: none;
  outline: none;
  background: none;
  color: ${theme.INPUT_TEXT};
  border: 2px solid ${theme.INPUT_BORDER};
  background: ${theme.INPUT_BACKGROUND};
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border: 2px solid
    ${(props) => (props.error ? theme.NEGATIVE : theme.INPUT_BORDER)};
  user-select: auto;
  border-radius: 5px;
`;
const Selector = ({
  options = [],
  width,
  onSelect,
  label,
  value,
  style = {},
}) => {
  return (
    <Main width={width} style={style}>
      <Label for="selection"> {label}</Label>
      <Input
        value={value}
        name="selection"
        onChange={(e) => onSelect(e.currentTarget.value)}
      >
        {options.map((option) => (
          <Option value={option.value}>{option.label}</Option>
        ))}
      </Input>
    </Main>
  );
};
export default Selector;
