import axios from "axios";
import { InfiniteTable } from "globalComponents";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div``;
const Title = styled.h3`
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
  padding-bottom: 8px;
  padding-left: 8px;
`;

const headers = [
  { label: "Group Name", render: (row) => <div>{row.name}</div> },
];

const GroupList = ({ groupIds, className }) => {
  const history = useHistory();
  const [groups, setGroups] = useState([]);

  const getGroupSuggestions = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/group/suggestions`
      );
      setGroups(
        res.data.groups
          .filter((group) => groupIds.includes(group._id.toString()))
          .map((group) => ({ name: group.name, id: group._id }))
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getGroupSuggestions();
  }, []);

  return (
    <Wrapper className={className}>
      <Link to="/groups">
        <Title>Groups ({groups.length})</Title>
      </Link>
      <InfiniteTable
        height="300px"
        headers={headers}
        data={groups}
        onClick={(row) => history.push(`/group/${row.id}/details`)}
      />
    </Wrapper>
  );
};

export default GroupList;
