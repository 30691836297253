import React from "react";
import {
  Button,
  Form,
  Header,
  Grid,
  Icon,
  Popup,
  TextArea,
} from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { DatePicker, Modal } from "../../../globalComponents";
import cookie from "js-cookie";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { errorHandler } from "globalResources/util.js";
import styled from "styled-components";
const SignupSchema = Yup.object().shape({
  client: Yup.string().required("Required"),
  start: Yup.date().required("Required"),
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

class Report extends React.Component {
  state = { users: [], clients: [], open: false, loading: false };

  fetchClients = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`, {
        params: { search: this.state.search, searchBy: this.state.searchBy },
      })
      .then(async (res) => {
        if (res.data.length < 1) {
          this.setState({
            clients: [],
          });
        } else {
          const clients = res.data;
          var temp = [];
          clients.forEach((client) => {
            if (client.name !== "All")
              temp.push({
                text: client.name,
                value: client._id,
                raw: client,
              });
          });
          this.setState({
            clients: temp,
          });
        }
      })
      .catch((err) => this.setState({ loading: false, error: "error" }));
  };

  componentDidMount() {
    this.fetchClients();
  }
  render() {
    const { clients } = this.state;
    const { selected, onClose } = this.props;
    if (!selected) return null;
    return (
      <Modal
        open={!!selected._id || !!selected.create}
        onClose={() => onClose()}
      >
        <Main>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Popup
              flowing
              trigger={
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Header as="h1" style={{ marginTop: 0 }}>
                    {selected._id ? "Report Details" : "Create Report"}
                  </Header>
                  <Icon
                    style={{ margin: "0 auto 0 auto" }}
                    name="help"
                    color="blue"
                    size="small"
                    circular
                  />
                </div>
              }
            >
              {selected._id ? (
                <Header.Subheader>
                  Created by:{" "}
                  {selected.createdBy &&
                    selected.createdBy.firstName +
                      " " +
                      selected.createdBy.lastName}
                  <br />
                  Created: {moment(selected.createdAt).format("lll")}
                  <br /> Updated: {moment(selected.updatedAt).format("lll")}
                  <br /> Status: {selected.status}{" "}
                </Header.Subheader>
              ) : (
                <Header.Subheader>
                  Reports are generated for a week long pay period starting at
                  "Billing Start Date" below.
                  <br /> Example for a start Date of (5-13): Monday (5-13) -
                  Sunday (5-19)
                </Header.Subheader>
              )}
            </Popup>
          </div>
          <Formik
            initialValues={{
              client: selected._id ? selected.client._id : "",
              start: selected._id
                ? moment(selected.start).format("YYYY-MM-DD")
                : "",
              notes: selected._id ? selected.notes : "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, actions) => {
              const selectedClient = clients.find(
                (client) => client.value === values.client
              );
              axios
                .post(`/report`, {
                  id: selected._id,
                  client: values.client,
                  notes: values.notes,
                  start: moment(values.start, "YYYY-MM-DD")
                    .tz(selectedClient.raw.timezone)
                    .startOf("day"),
                })
                .then((res) => {
                  this.setState({ open: false });
                  this.props.onClose(res.data.report);
                })
                .catch((error) => {
                  const { message, action } = errorHandler(error);
                })
                .finally(() => actions.setSubmitting(false));
            }}
            render={(props) => (
              <Form onSubmit={props.handleSubmit} size="large">
                <Grid
                  style={{
                    borderRadius: 10,
                    padding: "1%",
                    maxWidth: 600,
                    margin: "auto",
                  }}
                >
                  <Grid.Row columns={2}>
                    <Grid.Column centered style={{ margin: "auto" }}>
                      <Header as="h3" style={{ margin: "auto" }}>
                        Client
                      </Header>
                      <Form.Dropdown
                        onChange={(e, v) =>
                          props.setFieldValue("client", v.value)
                        }
                        value={props.values.client}
                        selection
                        search
                        options={clients}
                        name="client"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Header as="h3" style={{ margin: "auto" }}>
                        Billing Start Date
                      </Header>

                      <DatePicker
                        type="date"
                        onChange={(v) => props.setFieldValue("start", v)}
                        value={moment(props.values.start).format("YYYY-MM-DD")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ padding: "2%" }}>
                    <Header as="h3" style={{ margin: "auto" }}>
                      Notes
                    </Header>
                    <TextArea
                      value={props.values.notes}
                      onChange={(e, d) => props.setFieldValue("notes", d.value)}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    {props.errors.general && (
                      <div
                        style={{
                          color: "blue",
                          margin: "-1vh auto .5vh auto",
                          textAlign: "center",
                        }}
                      >
                        {props.errors.general}
                      </div>
                    )}
                    <Button
                      style={{ margin: "auto 10% auto 10%" }}
                      fluid
                      disabled={props.isSubmitting}
                      color="blue"
                      type="submit"
                    >
                      {selected.create ? "Create Report" : "Edit Report"}
                    </Button>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          />
        </Main>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
export default connect(mapStateToProps, null)(Report);
