import { getClientEmployeeOptions, getClientOptions } from "api/client";
import axios from "axios";
import {
  DatePicker,
  FormRow,
  SelectorSearch,
  Button,
  Error,
  CurvedHeader,
} from "globalComponents";
import { errorHandler } from "globalResources/util";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/slices/uiSlice";
import { notifTags } from "./Notification";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 65px;
  right: 10px;
  @media (max-width: 800px) {
    top: 40px;
  }
`;

const ClockInModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({ start: moment() });
  const [clientOptions, setClientOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [search, setSearch] = useState({ client: "", employee: "" });
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchClients();
  }, []);

  const setFieldValue = (name, value) => {
    setForm((old) => ({ ...old, [name]: value }));
  };

  const fetchClients = async () => {
    setClientOptions(await getClientOptions(search.client));
  };

  const fetchTeam = async (client) => {
    setEmployeeOptions(await getClientEmployeeOptions(client, search.user));
  };

  const onSubmit = async () => {
    let timezone = clientOptions.find((c) => c.value === form.client).timezone;
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/user/currentTimer`, {
        userId: form.employee.user._id,
        position: form.employee.position,
        client: form.client,
        start: moment.tz(form.start, "YYYY-MM-DDThh:mmA", timezone).utc(),
      });
      dispatch(
        addNotification({
          type: notifTags.success,
          message: "Clocked in successfully!",
        })
      );
    } catch (err) {
      const { message } = errorHandler(err);
      setError(message);
      dispatch(
        addNotification({
          type: notifTags.error,
          message,
        })
      );
    }
    setOpen(false);
  };

  if (!open)
    return (
      <ButtonContainer>
        <Button onClick={() => setOpen(true)}>Clock Someone In</Button>
      </ButtonContainer>
    );
  return (
    <Modal
      onClose={() => {
        setOpen(false);
        onClose();
      }}
    >
      <Main>
        <CurvedHeader>Manual Employee Clock in</CurvedHeader>
        <FormRow>
          <SelectorSearch
            label="Client"
            onSelect={(v) => {
              setFieldValue("client", v);
              setSearch((old) => ({ ...old, client: "" }));
              fetchTeam(v);
            }}
            onSearchChange={(v) => {
              setSearch((old) => ({ ...old, client: v.target.value }));
              fetchClients();
            }}
            value={form.client}
            options={clientOptions}
          />

          <SelectorSearch
            label="Employee"
            placeholder="Select a client first"
            onSelect={(v) => {
              setFieldValue("employee", v);
              setSearch("");
            }}
            onSearchChange={(v) => {
              setSearch((old) => ({ ...old, employee: v.target.value }));
              fetchTeam();
            }}
            value={form.employee}
            options={employeeOptions}
          />
        </FormRow>
        <DatePicker
          label="Time In"
          onChange={(v) => {
            setFieldValue("start", v);
          }}
          value={form.start}
        />
        <Error>{error}</Error>
        <Button onClick={onSubmit} style={{ margin: "10px" }}>
          {" "}
          Clock Employee In
        </Button>
      </Main>
    </Modal>
  );
};
export default ClockInModal;
