import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  List,
  Icon,
  Popup,
} from "semantic-ui-react";
import cookie from "js-cookie";
import axios from "axios";

const Managers = ({ props }) => {
  const [searchBy, setSearchBy] = useState("name");
  const [search, setSearch] = useState();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        params: {
          search: search,
          limit: "100",
          searchBy: searchBy,
          accountType: "client",
        },
      })
      .then(async (res) => {
        if (res.data.users.length < 1) setUsers([]);
        else setUsers(res.data.users);
      });
  }, [search, searchBy]);

  const checkmanagers = (managers, check) => {
    var found = false;
    managers.forEach((cur) => {
      if (cur.user === check) {
        found = true;
        return null;
      }
    });
    return found;
  };

  return (
    <Grid>
      <Header
        as="h3"
        style={{
          borderTop: "2px solid white",
          color: "white",
          margin: "auto",
          width: "100%",
          paddingTop: "1%",
        }}
      >
        Managers
      </Header>
      <Grid.Row
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Form.Dropdown
          selection
          onChange={(event, { name, value }) => {
            setSearchBy(value);
          }}
          defaultValue="name"
          options={[
            { text: "Name", value: "name" },
            { text: "Email", value: "email" },
            { text: "Phone Number", value: "number" },
          ]}
          placeholder="Search By"
        />
        <Form.Input
          onChange={(event, { name, value }) => {
            setSearch(value);
          }}
          style={{ color: "blue", paddingLeft: 10 }}
          icon="search"
          iconPosition="left"
          placeholder={`Search by ${searchBy}`}
        />
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column
          style={{
            border: "2px solid #333333",
            borderRadius: 10,
            paddingTop: "1vh",
            backgroundColor: "white",
          }}
        >
          <Header as="h3">Users</Header>
          <List
            divided
            style={{
              height: "25vh",
              overflowY: "scroll",
            }}
          >
            {users.map((user, index) => {
              if (checkmanagers(props.values.managers, user._id)) return null;
              return (
                <Popup
                  key={index}
                  content={
                    <div>
                      <Header as="h5">
                        Email: {user.email}
                        <br />
                        Phone: {user.phone}
                      </Header>
                    </div>
                  }
                  trigger={
                    <List.Item>
                      <List.Content>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Header as="h5">
                            {searchBy === "name"
                              ? user.firstName + " " + user.lastName
                              : user[searchBy]}
                          </Header>
                          <Button
                            onClick={() => {
                              props.setFieldValue(
                                "managers",
                                props.values.managers.concat([
                                  { user: user._id },
                                ])
                              );
                              props.setFieldValue(
                                "managerTeam",
                                props.values.managerTeam.concat([{ user }])
                              );
                            }}
                            type="button"
                            icon
                          >
                            <Icon name="add" />
                          </Button>
                        </div>
                      </List.Content>
                    </List.Item>
                  }
                />
              );
            })}
          </List>
        </Grid.Column>
        <Grid.Column
          style={{
            border: "2px solid #333333",
            borderRadius: 10,
            paddingTop: "1vh",
            backgroundColor: "white",
          }}
        >
          <Header as="h3">Selected Users</Header>
          <List
            divided
            style={{
              height: "25vh",
              overflowY: "scroll",
            }}
          >
            {props.values.managerTeam.map((cur, index) => {
              if (!cur.user) return null;
              return (
                <Popup
                  wide="very"
                  style={{ width: "15vw" }}
                  position="bottom left"
                  key={index}
                  content={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Header as="h6">
                          {cur.user.email}
                          <br />
                          {cur.user.phone}
                        </Header>
                      </div>
                    </div>
                  }
                  trigger={
                    <List.Item>
                      <List.Content>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Header as="h5">
                            {searchBy === "name"
                              ? cur.user.firstName + " " + cur.user.lastName
                              : cur.user[searchBy]}
                          </Header>
                          <Button
                            style={{ margin: " 0" }}
                            onClick={() => {
                              props.setFieldValue(
                                "managers",
                                props.values.managers.filter(
                                  (manager) => manager.user !== cur.user._id
                                )
                              );
                              props.setFieldValue(
                                "managerTeam",
                                props.values.managerTeam.filter(
                                  (managers) =>
                                    managers.user._id !== cur.user._id
                                )
                              );
                            }}
                            type="button"
                            icon
                          >
                            <Icon name="delete" />
                          </Button>
                        </div>
                      </List.Content>
                    </List.Item>
                  }
                />
              );
            })}
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {props.errors.general && (
          <div
            style={{
              color: "blue",
              margin: "-1vh auto .5vh auto",
              textAlign: "center",
            }}
          >
            {props.errors.general}
          </div>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default Managers;
