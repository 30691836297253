import jsCookie from "js-cookie";

export const clearCookies = async (noToken, timestation) => {
  if (!noToken) await jsCookie.remove("token");
  await jsCookie.remove("type");
  await jsCookie.remove("name");
  await jsCookie.remove("id");
  if (timestation) await jsCookie.remove("timestationToken");
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const formatDollars = (amount = 0) => {
  return amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
