import CSVUploader from "globalComponents/CSVUploader";
import moment from "moment";
import React from "react";
import TimeImportEdit from "./components/TimeImportEdit";
import { useState } from "react";
import styled from "styled-components";
import { Row, Time, Table, Button, FormRow } from "../../globalComponents";
import axios from "axios";
import { getTheme } from "Theme";
import { errorHandler } from "globalResources/util";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";
const theme = getTheme();
const Title = styled.h2`
  margin-right: auto;
  margin-bottom: 0px;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-height: 90vh;
`;

const ButtonRow = styled.div`
  margin: auto auto 0 auto;
  display: flex;
  gap: 20px;
  width: 80%;
`;

const TimeEntryImport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState(null);
  const [entries, setEntries] = useState([]);
  const [selected, setSelected] = useState(null);

  const onParse = (rows) => {
    rows[0] = rows[0].map((header) => {
      header = header.toLowerCase().replace(" ", "");
      if (header === "uid") header = "UID";
      return header;
    });
    let parsedEntries = [];
    for (let i = 1; i < rows.length; i++) {
      let entry = {};
      let date;
      rows[0].forEach((header, j) => {
        if (header !== "UID") header = header;
        if (header === "UID" && !rows[i][j]) return;
        if (!rows[i][j]) {
          entry[header] = null;
        } else if (header === "date") {
          date = rows[i][j];
        } else if (["start", "end"].includes(header)) {
          entry[header] = moment.tz(
            date + " " + rows[i][j],
            "MM/DD/YYYY hh:mma",
            "eastern"
          );
        } else if (header.includes("rate")) {
          entry[header] = rows[i][j] * 100 || null;
        } else entry[header] = rows[i][j];
      });
      if (Object.values(entry).filter((val) => !!val).length > 0)
        parsedEntries.push(entry);
    }
    rows = rows[0].filter((header) => header !== "date");
    setHeaders(
      rows.map((header) => ({
        label: header,
        render: (row) => (
          <div>
            {["start", "end"].includes(header)
              ? row[header]?.format("MM/DD/YYYY hh:mma")
              : header.includes("rate")
              ? `$${(row[header] / 100).toFixed(2)}`
              : row[header]}
          </div>
        ),
      }))
    );
    setEntries((old) => parsedEntries.concat(old));
  };

  const onSubmit = (timeEntry) => {
    let temp = entries;
    temp[selected.index] = {
      ...temp[selected.index],
      ...timeEntry,
      start: moment.tz(timeEntry.start, "YYYY-MM-DDThh:mmA ", "eastern"),
      end: moment.tz(timeEntry.end, "YYYY-MM-DDThh:mmA ", "eastern"),
      billRate: timeEntry?.billRate * 100,
      payRate: timeEntry?.payRate * 100,
      overtimeBillRate: timeEntry?.overtimeBillRate * 100,
      overtimePayRate: timeEntry?.overtimePayRate * 100,
    };
    dispatch(
      addNotification({
        type: notifTags.success,
        message: "Submit Successful",
      })
    );
    setEntries(temp);
    setSelected(null);
  };

  const deleteTime = () => {
    let temp = entries;
    temp.splice(selected.index, 1);
    setEntries(temp);
    setSelected(null);
  };

  const checkError = () => {
    for (let header of headers) {
      if (header.label === "Error") return true;
    }
    return false;
  };

  const onImport = async (test) => {
    try {
      let { data } = await axios.post("/timesheet/import", {
        times: entries,
        test,
      });
      data.entries.length > 0 &&
        alert(
          `Successfully imported ${data.entries.length} time entries!` +
            (data.errored.length > 0
              ? `${data.errored.length} entries had errors please correct them.`
              : "")
        );
      if (data.errored.length > 0) {
        if (!checkError())
          setHeaders((old) =>
            old.concat({
              label: "Error",
              render: (row) => <div style={{ color: "red" }}>{row.error}</div>,
            })
          );
        let newRows = [];
        for (let errEntry of data.errored) {
          newRows.push({ ...entries[errEntry.index], error: errEntry.message });
        }
        setEntries(newRows);
      } else setEntries([]);
    } catch (err) {
      const { message } = errorHandler(err);
      dispatch({ type: notifTags.error, message });
    }
  };

  return (
    <Main>
      <FormRow style={{ alignItems: "flex-end", justifyContent: "right" }}>
        <Title>Import Time Entries</Title>
        {entries.length > 0 && (
          <Button
            width="100px"
            color={theme.BUTTON_COLOR_NEGATIVE}
            onClick={() => {
              setEntries([]);
              setHeaders([]);
            }}
          >
            Clear
          </Button>
        )}
        <CSVUploader label="Upload Your CSV" onParse={onParse} />
      </FormRow>
      <Table
        data={entries}
        loading={loading}
        headers={headers}
        onClick={(row, index) => setSelected({ ...row, index } || {})}
      />

      <ButtonRow>
        <Button
          disabled={entries.length === 0}
          style={{ background: theme.BUTTON_COLOR_GREY }}
          onClick={() => onImport(true)}
        >
          Test Import
        </Button>
        <Button disabled={entries.length === 0} onClick={onImport}>
          Save Entries
        </Button>
      </ButtonRow>
      <TimeImportEdit
        selected={selected}
        onClose={() => {
          setSelected(null);
        }}
        onSubmit={onSubmit}
        deleteTime={deleteTime}
      />
      {
        //add a submit button that calls onImport()
      }
    </Main>
  );
};
export default TimeEntryImport;
