import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getTheme, resetButtonStyles } from "Theme";
import {
  faCheck,
  faExclamation,
  faInfo,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeNotification } from "store/slices/uiSlice";

const theme = getTheme();

const Wrapper = styled.div`
  position: fixed;
  top: 40px;
  right: 24px;
  background-color: ${(props) => props.bg};
  width: 340px;
  padding: 24px 20px;
  border-radius: 6px;
  z-index: 101;
  color: ${theme.BUTTON_TEXT};
  font-size: 1.1rem;
`;
const InnerWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
`;
const IconWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: grid;
  place-items: center;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${(props) => props.color};
`;
const CloseBtn = styled.button`
  ${resetButtonStyles()};
  color: ${theme.BUTTON_TEXT};
  font-size: 1.4rem;
  position: absolute;
  top: -18px;
  right: -8px;
  cursor: pointer;
`;
const Message = styled.p``;

export const notifTags = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};
const notifReference = {
  [notifTags.success]: { icon: faCheck, color: theme.SUCCESS },
  [notifTags.error]: { icon: faXmark, color: theme.ERROR },
  [notifTags.warning]: { icon: faExclamation, color: theme.WARNING },
  [notifTags.info]: { icon: faInfo, color: theme.INFO },
};

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.ui.notifications);

  useEffect(() => {
    if (notifications.length > 0) {
      const { time = 4000 } = notifications[0];
      const timer = setTimeout(() => {
        dispatch(removeNotification());
      }, time);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [notifications]);

  if (notifications.length === 0) {
    return null;
  }

  const { type = "info", message } = notifications[0];

  if (message) {
    const notifColor = notifReference[type].color;
    const icon = notifReference[type].icon;

    return (
      <Wrapper bg={notifColor}>
        <InnerWrap>
          <CloseBtn onClick={() => dispatch(removeNotification())}>
            <FontAwesomeIcon icon={faXmark} />
          </CloseBtn>

          <IconWrap>
            <Icon color={notifColor} icon={icon} />
          </IconWrap>
          <Message>{message}</Message>
        </InnerWrap>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default Notification;
