import axios from "axios";
import {
  Button,
  FormColumn,
  FormHeader,
  FormRow,
  InfiniteTable,
  Input,
  Modal,
  Row,
} from "globalComponents";
import { errorHandler } from "globalResources/util";
import React from "react";
import { useState } from "react";
import { TextArea } from "semantic-ui-react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

const ContactList = ({ client, updateDetails }) => {
  const [contact, setContact] = useState();

  const update = (contact, action, index) => {
    let temp = client;
    if (action === "add") temp.contacts.push(contact);
    else if (action === "edit")
      temp.contacts.forEach((c, idx) => {
        if (c._id === contact._id) temp.contacts[idx] = contact;
      });
    else temp.contacts = temp.contacts.filter((e) => e.user !== contact.user);

    axios
      .patch("/client", { contacts: temp.contacts, id: temp._id })
      .then((res) => updateDetails(res.data))
      .catch(async (error) => {
        const { message } = await errorHandler(error);
      });
  };

  const headers = [
    {
      label: "Name",
      sortBy: "name",
      render: (row) => <div>{row.name}</div>,
    },
    {
      label: "Email",
      render: (row) => <div>{row.email}</div>,
    },
    {
      label: "Phone",
      width: "200px",
      render: (row) => <div>{row.phone}</div>,
    },
    {
      label: "",
      width: "100px",
      render: (row) =>
        row.user && (
          <Button
            color={theme.NEGATIVE}
            onClick={() => update({ user: row.user._id }, "remove")}
          >
            X
          </Button>
        ),
    },
  ];

  return (
    <Main>
      <Row style={{ justifyContent: "space-between", padding: "10px 0" }}>
        <Title>Contacts ({client.contacts.length})</Title>

        <Button onClick={() => setContact({})}>Add Contact</Button>
      </Row>
      <InfiniteTable
        height="400px"
        data={client.contacts}
        headers={headers}
        onClick={(row) => setContact(row)}
      />
      {contact && (
        <Modal onClose={() => setContact()}>
          <FormColumn>
            <FormHeader>Client Contact Form</FormHeader>
            <FormRow>
              <FormColumn>
                <Input
                  label="Name"
                  value={contact.name}
                  onChange={(e) =>
                    setContact((old) => ({ ...old, name: e.target.value }))
                  }
                />
                <Input
                  label="Email"
                  value={contact.email}
                  onChange={(e) =>
                    setContact((old) => ({ ...old, email: e.target.value }))
                  }
                />
              </FormColumn>
              <FormColumn>
                <Input
                  label="Phone"
                  value={contact.phone}
                  onChange={(e) =>
                    setContact((old) => ({ ...old, phone: e.target.value }))
                  }
                />
              </FormColumn>
            </FormRow>
            <FormHeader>Note</FormHeader>
            <TextArea
              style={{ height: "10vh" }}
              value={contact.note}
              onChange={(e) =>
                setContact((old) => ({ ...old, note: e.target.value }))
              }
            />
            <Button
              onClick={() => update(contact, contact._id ? "edit" : "add")}
            >
              Save Contact
            </Button>
          </FormColumn>
        </Modal>
      )}
    </Main>
  );
};
export default ContactList;
