import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Button, Row, Time } from "globalComponents";
import Notes from "globalComponents/Notes";
import { errorHandler } from "globalResources/util";
import UserModal from "pages/admin/forms/UserModal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addNotification } from "store/slices/uiSlice";
import styled from "styled-components";
import { getTheme } from "Theme";
import ClientList from "./ClientList";
import GroupList from "./GroupList";
import UserTimeEntries from "./UserTimeEntries";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import UserMessageModal from "globalComponents/UserMessageModal";

const theme = getTheme();
const Wrapper = styled.div`
  display: flex;
  padding: 0px 5vw;
  gap: 12px;
`;
const Sidebar = styled.div`
  flex: 0 0 330px;
  border-radius: 15px;
  padding: 10px;
`;
const Title = styled.h1`
  margin-bottom: 16px;
`;
const SectionTitle = styled.h3`
  margin: 0px;
`;
const EditBtn = styled.button`
  display: flex;
  border: none;
  background: none;
  color: ${theme.BUTTON_COLOR};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-left: auto;
`;

const Container = styled.div`
  border-top: 2px solid ${theme.BORDER};
  padding: 10px;
  margin-bottom: 12px;
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
`;
const InfoValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
`;

const Content = styled.div`
  flex: 1;
`;
const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
const BackBtn = styled(Button)`
  flex-basis: fit-content;
`;
const UpperWrap = styled.div`
  display: flex;
  gap: 12px;
  padding-bottom: 24px;
`;
const StyledClientList = styled(ClientList)`
  flex: 1;
`;
const StyledGroupList = styled(GroupList)`
  flex: 1;
`;
const PinWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const ShowPinIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const UserDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [showPin, setShowPin] = useState(false);

  const getUser = () => {
    axios
      .get("/user", { params: { id } })
      .then((res) => setUserInfo(res.data))
      .catch(async (e) => {
        const { message } = await errorHandler(e);
        dispatch(addNotification({ type: "error", message }));
        history.push("/users");
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  if (userInfo) {
    const { user } = userInfo;
    const {
      firstName,
      lastName,
      email,
      phone,
      accountType,
      employeeType,
      language,
      pin,
      notes,
      _id,
      clients,
      groups,
      accountingTimezone,
      externalId,
      addressLine1,
      addressLine2,
      state,
      zipcode,
    } = userInfo.user;
    const hiddenPin = pin
      ? pin
          .toString()
          .split("")
          .map((char) => "*")
          .join("")
      : null;

    return (
      <Wrapper>
        <Sidebar>
          <Title>{`${firstName} ${lastName}`}</Title>
          <Row>
            <SectionTitle>Contact</SectionTitle>
            <EditBtn onClick={() => setModalOpen(true)}>Edit</EditBtn>
          </Row>
          <Container>
            <Info>
              Email: <InfoValue>{email ? email : "-"}</InfoValue>
            </Info>
            <Info>
              Phone: <InfoValue>{phone ? phone : "-"}</InfoValue>
            </Info>
            <Info>
              Address: <InfoValue>{addressLine1}</InfoValue>
            </Info>
            <Info>
              Address Line 2:
              <InfoValue>{addressLine2}</InfoValue>
            </Info>
            <Info>
              State: <InfoValue>{state}</InfoValue>
            </Info>
            <Info>
              Zipcode: <InfoValue>{zipcode}</InfoValue>
            </Info>
          </Container>
          <SectionTitle>Account</SectionTitle>
          <Container>
            <Info>
              Account Type:{" "}
              <InfoValue capitalize>
                {accountType ? accountType : "-"}
              </InfoValue>
            </Info>
            <Info>
              Employment Type:{" "}
              <InfoValue>{employeeType ? employeeType : "-"}</InfoValue>
            </Info>
            <Info>
              Language:{" "}
              <InfoValue capitalize>{language ? language : "-"}</InfoValue>
            </Info>
            <Info>
              PIN:{" "}
              <PinWrap>
                <InfoValue
                  style={
                    !showPin
                      ? { fontSize: "18px", transform: "translateY(3px)" }
                      : undefined
                  }
                >
                  {pin && !showPin ? hiddenPin : pin ? pin : "-"}
                </InfoValue>
                {pin && (
                  <ShowPinIcon
                    onClick={() => setShowPin(!showPin)}
                    icon={showPin ? faEyeSlash : faEye}
                  />
                )}
              </PinWrap>
            </Info>
            <Info>
              External ID: <InfoValue capitalize>{externalId}</InfoValue>
            </Info>
            <Info>
              Accounting Timezone:{" "}
              <InfoValue capitalize>{accountingTimezone}</InfoValue>
            </Info>
          </Container>
          <Notes notes={notes} type="user" id={_id} />
        </Sidebar>
        <Content>
          <BtnWrap>
            <BackBtn onClick={() => setMessageModalOpen(true)}>
              Notify User
            </BackBtn>
            <BackBtn
              color={theme.BUTTON_COLOR_GREY}
              onClick={() =>
                history.push({ pathname: "/users", state: location?.state })
              }
            >
              Back to Users
            </BackBtn>
          </BtnWrap>
          <UpperWrap>
            <StyledClientList clientIds={clients} />
            <StyledGroupList groupIds={groups.map((group) => group._id)} />
          </UpperWrap>
          <UserTimeEntries
            userId={_id}
            setSelected={setSelectedEntry}
            selected={selectedEntry}
          />
        </Content>
        {messageModalOpen && (
          <UserMessageModal
            onClose={() => setMessageModalOpen(false)}
            userId={user._id}
          />
        )}
        {modalOpen && (
          <UserModal
            selected={{
              ...user,
              groups: user.groups.map((g) => g?._id),
              rates: userInfo.rates,
            }}
            onClose={() => {
              setModalOpen(false);
              getUser();
            }}
          />
        )}

        <Time
          selected={selectedEntry}
          onClose={() => {
            setSelectedEntry(null);
          }}
        />
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default UserDetails;
