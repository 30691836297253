import { useState, useRef } from "react";
import Theme from "Theme.js";
import { Icon, Header, Button } from "semantic-ui-react";
import { useWindowDimensions, useEventListener } from "hooks";

function ProfileButton(props) {
  const [openSettings, setOpenSettings] = useState(false);
  const { picture, containerStyle, options } = props;
  const { width, height } = useWindowDimensions();
  const menuRef = useRef(null);

  const handleClick = (e) => {
    if (e.target) {
      try {
        if (menuRef.current.contains(e.target)) {
          return;
        } else {
          setOpenSettings(false);
        }
      } catch (error) {
        console.log("err: ", error);
      }
    }
  };

  useEventListener("mousedown", handleClick);

  return (
    <div
      style={
        containerStyle || {
          margin: "auto",
          height: "100%",
          width: "auto",
        }
      }
    >
      <button
        onClick={() => setOpenSettings(!openSettings)}
        style={{
          margin: "auto",
          borderRadius: 100,
          outline: "none",
          border: "none",
          background: "none",
          cursor: "pointer",
          paddingTop: "1.5vh",
        }}
      >
        <Icon
          style={{
            color: Theme.MAIN_COLOR,
            zIndex: 90,
          }}
          size={width > 1000 ? "big" : "large"}
          name="cog"
        >
          <div
            ref={menuRef}
            style={
              openSettings && options
                ? {
                    position: "relative",
                    borderRadius: 5,
                    display: "block",
                    right: "10vw",
                    flexDirection: "column",
                    boxShadow: "1px 1px 10px black",
                    backgroundColor: "white",
                    width: "12vw",
                    zIndex: 99,
                    border: `4px solid ${Theme.BLACK}`,
                  }
                : { display: "none" }
            }
          >
            {options &&
              options.map((option, index) => {
                return (
                  <Button
                    key={index}
                    onClick={option.onClick}
                    color="blue"
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      borderRadius: "0",
                      cursor: "pointer",
                      display: "flex",
                      padding: "5%",
                      borderBottom:
                        index + 1 < options.length
                          ? `4px solid ${Theme.BLACK}`
                          : "none",
                    }}
                  >
                    <Header
                      style={{
                        margin: "auto",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "1vw",
                      }}
                    >
                      {option.label}
                    </Header>
                  </Button>
                );
              })}
          </div>
        </Icon>
      </button>
    </div>
  );
}

export default ProfileButton;
