import { Column, Row } from "globalComponents";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import TimerManager from "./TimerManager";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices/userSlice";
import styled from "styled-components";
import { getTheme } from "Theme";
import NotesModal from "globalComponents/NotesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { roundTwoDecimal } from "globalResources/util";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const theme = getTheme();

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px auto;
  height: 700px;
  background-image: linear-gradient(
    ${theme.SECONDARY_COLOR} 80%,
    transparent 102%
  );
  overflow-y: auto;
  padding: 4px 4px 8px 4px;
  @media (max-width: 900px) {
    height: 400px;
  }
`;
const TimeEntry = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px;
  margin: 1px 0 2px 0;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  background-color: white;
  font-weight: 800;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const SectionHeader = styled.h2`
  text-align: center;
`;

const WeekHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin: 10px;
  font-weight: 600;
`;

const WeekArrow = styled.div`
  cursor: pointer;
`;

const Total = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Date = styled.div``;

const Data = styled.div`
  font-size: 12px;
  width: 30%;
  @media (max-width: 900px) {
    font-size: 10px;
  }
`;

const UserClockins = () => {
  const [card, setCard] = useState({
    timesheets: [],
    start: "",
    end: "",
    totals: { hours: 0, overtimeHours: 0, pay: 0 },
  });
  const [week, setWeek] = useState(0);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`/user/current`)
      .then((res) => {
        let permissions = res.data.permissions.map((perm) => perm.name);
        localStorage.setItem("permissions", JSON.stringify(permissions));
        dispatch(setUser({ ...res.data }));
      })
      .catch((err) => {
        console.error("current error: ", err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/accounting/user/card", { params: { week } })
      .then((res) => {
        setCard(res.data);
      })
      .catch((err) => console.error("error: ", err))
      .finally(() => setLoading(false));
  }, [week]);

  return (
    <>
      <Grid.Column
        style={{
          maxWidth: "100vw",
          padding: "10px 10px",
        }}
      >
        <TimerManager />
        <SectionHeader>Weekly Timesheet</SectionHeader>
        {card.totals ? (
          <Row style={{ justifyContent: "space-between" }}>
            <Column style={{ margin: "0px 10px" }}>
              <Total>Hours: {roundTwoDecimal(card.totals.hours)} </Total>
              {!!card.totals.overtimeHours && (
                <Total>
                  Overtime: {roundTwoDecimal(card.totals.overtimeHours)}
                </Total>
              )}
            </Column>
            {/* <Column>
            <Total>Gross Pay: ${card.totals.pay / 100}</Total>
          </Column> */}
          </Row>
        ) : (
          <Total>No time entries this week</Total>
        )}

        <WeekHeader>
          <WeekArrow onClick={() => setWeek(week + 1)}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </WeekArrow>
          <Date>
            {card.start ? (
              card.start + " - " + card.end
            ) : (
              <Skeleton
                width="200px"
                height="20px"
                baseColor="#ffffff"
                highlightColor="rgba(217,230,255.95)"
                borderRadius="7px"
              />
            )}
          </Date>
          <WeekArrow disabled={week === 0} onClick={() => setWeek(week - 1)}>
            <FontAwesomeIcon icon={faArrowRight} />
          </WeekArrow>
        </WeekHeader>
        <List>
          {loading && (
            <Skeleton
              count={1}
              wrapper={TimeEntry}
              baseColor="#ffffff"
              highlightColor="rgba(217,230,255.95)"
              borderRadius="0px"
            />
          )}

          {card.timesheets?.map((time) => (
            <TimeEntry onClick={() => setSelected(time)}>
              <Data>{moment(time.start).format("dddd MM/DD")}</Data>
              <Data>
                {moment(time.start).format("hh:mm A ") +
                  moment(time.end).format("- hh:mm A")}
              </Data>
              <Data>
                {roundTwoDecimal(
                  moment(time.end).diff(moment(time.start), "minutes") / 60
                )}{" "}
                hours {time.subtractLunch && "(-30m)"}
              </Data>
              <Popup
                content={""}
                trigger={
                  <Data>
                    {time.billed ? (
                      <Icon color="green" name="file" />
                    ) : time.errored ? (
                      <Icon color="blue" name="delete" />
                    ) : time.reported ? (
                      <Icon color="green" name="checkmark" />
                    ) : (
                      <Icon color="grey" name="minus" />
                    )}
                  </Data>
                }
              />
            </TimeEntry>
          ))}
        </List>
      </Grid.Column>
      {selected && (
        <NotesModal
          noButton={true}
          notes={selected.notes}
          type="timesheet"
          id={selected._id}
          onClose={() => setSelected(null)}
        />
      )}
    </>
  );
};

export default UserClockins;
