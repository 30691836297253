import React, { useState } from "react";
import axios from "axios";
import { Button } from "globalComponents";
import FileUploader from "globalComponents/FileUploader";
import { errorHandler } from "globalResources/util";
import { imgExtensions } from "globalResources/constants";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getTheme } from "Theme";
import moment from "moment";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.MANILLA};
  padding: 10px 35px;
  margin-top: auto;
  width: 100%;
  gap: 10px;
`;

const TextArea = styled.textarea`
  border: solid;
  border-radius: 5px;
  border-color: ${theme.INPUT_BORDER};
  min-height: 100px;
`;

const Preview = styled.a`
  font-size: 18px;
  font-weight: 800;
  color: ${theme.BUTTON_COLOR};
  border-radius: 20px;
  overflow: scroll;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 640px;
  padding: 5px;
  gap: 15px;
`;

const Image = styled.img`
  width: 200px;
  object-fit: contain;
`;

const Container = styled.div`
  background-color: ${theme.SECONDARY_COLOR};
  border-radius: 10px;
  padding: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const FileUploadButton = styled.div`
  width: 50%;
  margin: auto;
`;

const NoteButton = styled.div`
  width: 50%;
  margin: auto;
`;

const BugNoteInput = ({ fetchBug }) => {
  const [inputValue, setInputValue] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState();
  const { id } = useParams();

  const isImage = (file) => {
    const type = file.substring(file.lastIndexOf(".") + 1).toUpperCase();
    if (imgExtensions.includes(type)) return true;
    return false;
  };

  const addNoteHandler = () => {
    axios
      .patch(`/bug/${id}/note`, { note: inputValue, files })
      .catch((err) => {
        const { message } = errorHandler(err);
        setError(message);
      })
      .then(() => {
        fetchBug();
        setInputValue("");
      });
  };

  return (
    <Main>
      <TextArea
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      ></TextArea>{" "}
      {files.length > 0 ? (
        <Container>
          <Preview>
            {files.map((file) => {
              if (!isImage(file)) {
                let temp = file.split("/");
                return (
                  <Preview href={file} target="_blank">
                    {temp[temp.length - 1]}
                  </Preview>
                );
              }
              return null;
            })}
          </Preview>
          <Images>
            {files.map((file) => {
              if (isImage(file)) {
                return <Image src={file} />;
              }
              return null;
            })}
          </Images>
        </Container>
      ) : null}
      <ButtonRow>
        <FileUploadButton>
          <FileUploader
            hidePreview={true}
            label="Upload Files"
            namePrefix={`bugreport/${moment().format("MM/DD/YYYY-hh:mm")}-`}
            onUrl={(url) => setFiles((old) => old.concat([url]))}
          />
        </FileUploadButton>

        <NoteButton>
          <Button style={{ height: "35.5px" }} onClick={addNoteHandler}>
            Add Note
          </Button>
        </NoteButton>
      </ButtonRow>
    </Main>
  );
};

export default BugNoteInput;
