import React, { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import { TextArea } from "semantic-ui-react";
import Button from "./Button";
import axios from "axios";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;f
`;
const Title = styled.h2``;

const UserMessageModal = ({ onClose, userId }) => {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const sendNotification = () => {
    try {
      axios.post(`/user/directMessage`, {
        message,
        userId,
        type: "email",
        subject: "User Notification",
      });
    } catch (err) {}
  };
  return (
    <Modal
      onClose={() => {
        setError("");
        onClose();
      }}
      centered
      closeIcon
      style={{
        textAlign: "center",
        backgroundColor: "white",
        padding: "2vh",
        maxWidth: 700,
      }}
    >
      <Main>
        <Title>Type the message you'd like to send the user.</Title>
        <TextArea
          placeholder="message to be sent to the user"
          error={error}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <Button onClick={sendNotification}>Send Message</Button>
      </Main>
    </Modal>
  );
};
export default UserMessageModal;
