import React from "react";
import { Button, Message, Modal, Header } from "semantic-ui-react";
import Theme from "../Theme.js";

class PermissionDenied extends React.Component {
  state = {
    open: false
  };
  render() {
    const { open, onConfirm, onDeny, message } = this.props;
    return (
      <Modal
        open={open}
        onClose={onDeny}
        style={{
          width: "40vw",
          top: "40vh",
          left: "30vw",
          position: "fixed",
          padding: ".5%"
        }}
        centered={true}
      >
        <Header style={{ textAlign: "center" }}>{message}</Header>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button fluid onClick={onConfirm} type="button" icon color="blue">
            Yes
          </Button>
          <Button fluid type="button" icon onClick={onDeny}>
            No
          </Button>
        </div>
      </Modal>
    );
  }
}

export default PermissionDenied;
