import { DatePicker, Table } from "globalComponents";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { errorHandler } from "globalResources/util";
import axios from "axios";
import { timesheetHeaders } from "../timesheets/components/headers";
import moment from "moment";

const Wrapper = styled.div``;
const Title = styled.h3`
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-right: auto;
  align-self: flex-end;
`;
const FilterWrap = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
`;

const UserTimeEntries = ({ userId, setSelected, selected, className }) => {
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [sort, setSort] = useState({ sort: "end", direction: -1 });
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    total: 0,
  });

  const [daterange, setDaterange] = useState({ start: "", end: "" });

  useEffect(() => {
    fetchTimesheets();
  }, [
    pagination.limit,
    pagination.page,
    daterange.start,
    daterange.end,
    selected,
  ]);

  const fetchTimesheets = () => {
    setLoading(true);
    axios
      .get(`/timesheets`, {
        params: {
          user: userId,
          start:
            daterange.start &&
            moment(daterange.start, "YYYY-MM-DD").startOf("day").utc(),
          end:
            daterange.end &&
            moment(daterange.end, "YYYY-MM-DD").endOf("day").utc(),
          page: pagination.page,
          limit: pagination.limit,
          sort: sort.sort,
          direction: sort.direction,
        },
      })
      .then(async (res) => {
        setEntries(res.data.timesheets);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Wrapper className={className}>
      <FilterWrap>
        <Title>Time Entries</Title>
        <DatePicker
          type="date"
          label="Start"
          width="20%"
          max={moment(daterange.end).format("YYYY-MM-DD")}
          onChange={(v) => {
            setDaterange((old) => ({ ...old, start: v }));
          }}
          onFirst={(v) =>
            !daterange.end &&
            setDaterange((old) => ({
              ...old,
              end: moment(v).add(6, "days").format("YYYY-MM-DD"),
            }))
          }
          value={moment(daterange.start).format("YYYY-MM-DD")}
        />{" "}
        <DatePicker
          type="date"
          label="End"
          width="20%"
          min={moment(daterange.start).format("YYYY-MM-DD")}
          onChange={(v) => setDaterange((old) => ({ ...old, end: v }))}
          value={moment(daterange.end).format("YYYY-MM-DD")}
        />
      </FilterWrap>
      <Table
        loading={loading}
        data={entries}
        headers={timesheetHeaders}
        pagination={pagination}
        onSort={(by, direction) => setSort({ sort: by, direction })}
        onPage={(page) => setPagination((old) => ({ ...old, page }))}
        onLimit={(limit) => {
          setPagination((old) => ({ ...old, limit }));
        }}
        onClick={(row) => setSelected(row || {})}
      />
    </Wrapper>
  );
};

export default UserTimeEntries;
