import axios from "axios";
import {
  FolderContainer,
  SearchBar,
  Table,
  ToggleButton,
} from "globalComponents";
import ExportCSV from "globalComponents/ExportCSV";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import ClientModal from "./components/ClientModal";
import CreateClientModal from "./components/CreateClientModal";

const headers = [
  {
    label: "Name",
    sortBy: "name",
    render: (row) => <div>{row.name}</div>,
  },
  { label: "Description", render: (row) => <div>{row.description}</div> },
  { label: "Pay Period End", render: (row) => <div>{row.reportDay}</div> },
  {
    label: "Pay Group",
    render: (row) => <div>{row?.payGroup ? row.payGroup : ""}</div>,
  },
];

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  gap: 20px;
  flex-wrap: wrap;
`;

const Clients = () => {
  const location = useLocation();
  const cachedFilters = location?.state?.cachedFilters
    ? location.state.cachedFilters
    : null;

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [create, setCreate] = useState(false);
  const [error, setError] = useState();
  const [search, setSearch] = useState(
    cachedFilters ? cachedFilters.search : ""
  );
  const [archived, setArchived] = useState(
    cachedFilters ? cachedFilters.archived : false
  );
  const [pagination, setPagination] = useState(
    cachedFilters
      ? cachedFilters.pagination
      : {
          page: 1,
          limit: 20,
          direction: 1,
          total: 0,
        }
  );
  const history = useHistory();

  const currentUser = useSelector((state) => state.current.user);

  const fetchClients = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/clients`, {
        params: {
          search: search,
          page: pagination.page,
          limit: pagination.limit,
          direction: pagination.direction,
          archived: archived,
        },
      })
      .then(async (res) => {
        setClients(res.data.clients);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch((err) => {
        const { message } = errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const format4CSV = () => {
    let ans = [];
    clients.map((row) => {
      ans.push({
        name: row.name,
        description: row.description,
        payPeriod: row.reportDay,
        payGroup: row.payGroup,
      });
    });
    return ans;
  };

  useEffect(() => {
    fetchClients();
  }, [
    pagination.direction,
    pagination.limit,
    pagination.page,
    archived,
    search,
  ]);

  return (
    <FolderContainer title="Clients">
      <Button
        style={{ position: "absolute", top: "70px", right: "10px" }}
        color="blue"
        onClick={() => setCreate(true)}
      >
        + Add Client
      </Button>
      <Main>
        <FilterBar>
          <SearchBar
            style={{
              width: "50%",
              margin: "auto",
            }}
            onSearch={(v) => setSearch(v)}
            search={search}
          />
          <ToggleButton
            label="Show Disabled"
            onChange={() => setArchived(!archived)}
            value={archived}
          />
          <ExportCSV data={format4CSV()} filename="users" />
        </FilterBar>
        <Table
          data={clients}
          loading={loading}
          headers={headers}
          pagination={pagination}
          onClick={(row) =>
            history.push({
              pathname: `/client/${row._id}/details`,
              state: { cachedFilters: { pagination, search, archived } },
            })
          }
          onPage={(page) => setPagination((old) => ({ ...old, page }))}
          onLimit={(limit) => {
            setPagination((old) => ({ ...old, limit }));
          }}
          sortBy={"name"}
          sortDirection={pagination.direction}
          onSort={(by, direction) => setPagination((old) => ({ direction }))}
        />
      </Main>
      {create && <CreateClientModal onClose={() => setCreate(false)} />}
    </FolderContainer>
  );
};
export default Clients;
