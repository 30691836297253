import axios from "axios";
import {
  Button,
  Confirm,
  FormRow,
  Input,
  Modal,
  Selector,
  SelectorSearch,
} from "globalComponents";
import { notifTags } from "globalComponents/Notification";
import UserSelector from "globalComponents/UserSelector";
import { errorHandler } from "globalResources/util";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "store/slices/uiSlice";
import styled from "styled-components";
import { AdjustmentTypes } from "./util";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h1`
  margin: auto;
`;
const SubTitle = styled.h4`
  margin: auto;
`;

const initialForm = {
  title: "",
  description: "",
  type: "bonus",
  amount: 0,
  users: [],
};
const initialError = {
  title: "",
  description: "",
  type: "",
  amount: "",
  client: "",
};

const AdjustmentModal = ({ onClose, adjustment, report }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState(initialError);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [clients, setClients] = useState([]);

  function updateForm(val, name) {
    if (name === "title" && val.length > 50) {
      setErrors((old) => ({
        ...old,
        title: "Length of title must not exceed 50 characters",
      }));
      return;
    } else if (name === "title" && val.length <= 50)
      setErrors((old) => ({ ...old, title: "" }));
    setForm((old) => ({ ...old, [name]: val }));
  }

  const fetchClients = async () => {
    try {
      let res = await axios.get("/client/suggestions");
      let temp = [];
      res.data.map(
        (client) =>
          client.name !== "All" &&
          temp.push({
            label: client.name,
            value: client._id,
          })
      );
      setClients(temp);
    } catch (err) {
      const { message } = await errorHandler(err);
    }
  };

  async function submit() {
    if (
      form.type !== AdjustmentTypes.bonus &&
      !form.client &&
      !report?.client
    ) {
      setErrors((old) => ({ ...old, client: "Required" }));
      return;
    } else setErrors(initialError);
    setLoading(true);
    try {
      await axios[adjustment._id ? "patch" : "post"](
        `/adjustment${adjustment._id ? `/${adjustment._id}` : ""}`,
        {
          ...form,
          client: report?.client || form.client,
          amount: form.amount * 100,
          report: report?._id,
          users: employees.map((emp) => emp._id),
        }
      );
      dispatch(
        addNotification({
          message: `Successfully created ${
            adjustment._id ? "updated" : "created"
          } adjustment`,
        })
      );
      onClose();
    } catch (err) {
      const { message } = await errorHandler(err);
      dispatch(addNotification({ message, type: notifTags.error }));
    } finally {
      setLoading(false);
    }
  }

  async function remove() {
    setLoading(true);
    try {
      await axios.delete(`/adjustment/${adjustment._id}`);
      dispatch(
        addNotification({
          message: `Successfully deleted  adjustment`,
        })
      );
      onClose();
    } catch (err) {
      const { message } = await errorHandler(err);
      dispatch(addNotification({ message, type: notifTags.error }));
    } finally {
      setLoading(false);
    }
  }

  const getFullUsers = async (ids) => {
    try {
      let res = await axios.get("/users/details", {
        params: { type: "list", id: ids },
      });

      setEmployees((old) =>
        old.concat(
          res.data.filter(
            (u) => old.filter((ou) => ou._id === u._id).length === 0
          )
        )
      );
    } catch (err) {
      const { message } = await errorHandler(err);
    }
  };

  useEffect(() => {
    if (adjustment._id) {
      setForm({ ...adjustment, amount: adjustment.amount / 100 });
      getFullUsers(adjustment.users);
    }
    fetchClients();
  }, [adjustment]);

  return (
    <Modal onClose={onClose}>
      <Main>
        <Title>Adjustment</Title>
        {!report &&
          (form.type === AdjustmentTypes.bonus ||
            form.type === AdjustmentTypes.deduction) && (
            <SubTitle>
              Bonuses created here will not be billed to a client. To do so add
              the bonuses from within the report page.
            </SubTitle>
          )}
        <FormRow>
          <Input
            label="Title"
            value={form.title}
            onChange={(e) => updateForm(e.currentTarget.value, "title")}
            error={errors.title}
          />
          {!report?.client &&
            !form.report &&
            (form.type !== AdjustmentTypes.bonus ||
              form.type !== AdjustmentTypes.deduction) && (
              <SelectorSearch
                label="Client"
                onSelect={(v) => setForm((old) => ({ ...old, client: v }))}
                options={clients}
                value={form.client}
                placeholder="Select a client"
                error={errors.client}
              />
            )}
        </FormRow>
        <FormRow>
          <Input
            label="Description"
            value={form.description}
            onChange={(e) => updateForm(e.currentTarget.value, "description")}
            error={errors.description}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Amount"
            value={form.amount}
            onChange={(e) => updateForm(e.currentTarget.value, "amount")}
            error={errors.amount}
            type="number"
            min="0"
          />
          <Selector
            label="Type"
            width="300px"
            options={Object.keys(AdjustmentTypes).map((key) => ({
              label: key,
              value: AdjustmentTypes[key],
            }))}
            value={form.type}
            onSelect={(v) => updateForm(v, "type")}
          />
        </FormRow>
        {(form.type === AdjustmentTypes.bonus ||
          form.type === AdjustmentTypes.deduction) && (
          <FormRow>
            <UserSelector
              employees={employees}
              setEmployees={setEmployees}
              client={report?.client._id}
            />
          </FormRow>
        )}
        <FormRow>
          <Button
            onClick={() =>
              adjustment._id
                ? setConfirm(
                    adjustment.users
                      ? `Do you want to delete this adjustment for all ${adjustment.users.length} employees`
                      : `Are you sure you want to delete this adjustment?`
                  )
                : onClose()
            }
            color="red"
          >
            {adjustment._id ? "Delete Adjustment" : "Cancel"}
          </Button>
          <Button onClick={submit}>Save Changes</Button>
        </FormRow>
      </Main>
      <Confirm
        open={!!confirm}
        onConfirm={remove}
        onDeny={() => setConfirm("")}
        message={confirm}
      />
    </Modal>
  );
};
export default AdjustmentModal;
