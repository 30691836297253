import jsCookie from "js-cookie";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const HandleLoginLink = () => {
  const { token } = useParams();
  const { search } = useLocation();
  const reportId = new URLSearchParams(search).get("reportId");

  useEffect(() => {
    async function setToken() {
      await jsCookie.set("token", token);
      if (reportId) window.location.replace(`/accounting/report/${reportId}`);
      else window.location.replace("/home");
    }
    setToken();
  }, [token]);

  return <Main>Loggin you in...</Main>;
};
export default HandleLoginLink;
