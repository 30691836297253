import axios from "axios";
import {
  Button,
  FormHeader,
  FormRow,
  Input,
  Modal,
  Row,
  Selector,
  ToggleButton,
} from "globalComponents";
import { days, getTimezones, states } from "globalResources/constants";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const initialForm = {
  name: "",
  description: "",
  address: "",
  city: "",
  state: "",
  contacts: [],
  zipcode: "",
  employees: [],
  team: [],
  managerTeam: [],
  managers: [],
  timezone: "",
  password: "",
  confirmPassword: "",
  reportDay: "",
  subtractLunch: false,
  payscale: [],
  isArchived: false,
  payGroup: "",
};

const initialErrors = {
  ...initialForm,
  contacts: "",
  employees: "",
  team: "",
  managerTeam: "",
  managers: "",
  subtractLunch: "",
  payscale: "",
  isArchived: "",
};

const Title = styled.h1`
  margin: auto;
`;

const ClientEdit = ({ onClose, client, open }) => {
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState(initialErrors);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setForm(client ? { ...client } : initialForm);
  }, [client]);

  const updateForm = (value, name) => {
    setDirty(true);
    setForm((old) => ({ ...old, [name]: value }));
  };

  const submit = () => {
    setLoading(true);
    axios[client._id ? "patch" : "post"]("/client", {
      ...form,
      id: client._id,
    })
      .then((res) => {
        onClose(res.data);
      })
      .catch(async (error) => {
        const { message } = await errorHandler(error);
      })
      .finally(() => setLoading(false));
  };

  if (!open) return null;
  return (
    <Modal onClose={() => onClose()}>
      <Main>
        <Title>Client Details</Title>
        <Row>
          <ToggleButton
            label="Subtract Lunch?"
            value={form.subtractLunch}
            onChange={(v) => updateForm(!form.subtractLunch, "subtractLunch")}
          />
        </Row>
        <FormRow>
          <Input
            label="Name"
            value={form.name}
            onChange={(e) => updateForm(e.currentTarget.value, "name")}
            error={errors.name}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Description"
            value={form.description}
            onChange={(e) => updateForm(e.currentTarget.value, "description")}
            error={errors.description}
          />
        </FormRow>

        <FormRow>
          <Input
            label="Address"
            value={form.address}
            onChange={(e) => updateForm(e.currentTarget.value, "address")}
            error={errors.address}
          />
        </FormRow>
        <FormRow>
          <Input
            label="City"
            value={form.city}
            onChange={(e) => updateForm(e.currentTarget.value, "city")}
            error={errors.city}
          />
          <Selector
            label="State"
            width="300px"
            options={states}
            value={form.state}
            onSelect={(v) => updateForm(v, "state")}
          />
          <Input
            label="Zipcode"
            value={form.zipcode}
            onChange={(e) => updateForm(e.currentTarget.value, "zipcode")}
            error={errors.zipcode}
          />
        </FormRow>
        <FormRow>
          <Selector
            label="Timezone"
            width="300px"
            options={getTimezones()}
            value={form.timezone}
            onSelect={(v) => updateForm(v, "timezone")}
          />
          <Selector
            style={{ marginLeft: "auto" }}
            label="Report Day"
            width="300px"
            options={days}
            value={form.reportDay}
            onSelect={(v) => updateForm(v, "reportDay")}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Pay Group"
            value={form.payGroup}
            onChange={(e) => updateForm(e.currentTarget.value, "payGroup")}
          />
        </FormRow>
        <FormRow>
          <Button
            color={theme.BUTTON_COLOR_GREY}
            onClick={() => updateForm(!form.isArchived, "isArchived")}
          >
            {form.isArchived ? "Enable" : "Disable"}
          </Button>
          <Button onClick={submit}>Save Changes</Button>
        </FormRow>
      </Main>
    </Modal>
  );
};
export default ClientEdit;
