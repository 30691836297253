import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: {},
	permissions: [],
	timesheets: [],
	accounts: [],
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload.user || state.user;
			state.permissions = action.payload.permissions || state.permissions;
			state.timesheets = action.payload.timesheets || state.timesheets;
			state.accounts = action.payload.accounts || state.accounts;
		},
		setAccounts: (state, action) => {
			state.accounts = action.payload || [];
		},
		clearUser: state => {
			state = initialState;
		},
	},
});

export const { setUser, setAccounts, clearUser } = userSlice.actions;

export default userSlice.reducer;
