import axios from "axios";
import {
	AccountingFolderContainer,
	Button,
	Column,
	DatePicker,
	InfiniteTable,
	Selector,
	SelectorSearch,
	ToggleButton,
	Row,
} from "globalComponents";
import {notifTags} from "globalComponents/Notification";
import UserSelector from "globalComponents/UserSelector";
import {
	PayrollFrequency,
	PayrollFrequencyOptions,
} from "globalResources/constants";
import {determineNextRun, errorHandler} from "globalResources/util";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {addNotification} from "store/slices/uiSlice";
import styled from "styled-components";
import {getTheme} from "Theme";
const theme = getTheme();

const Main = styled.div`
	display: flex;
	gap: 10px;
`;

const Title = styled.h2`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const PayrollManagement = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [employees, setEmployees] = useState([]);
	const [info, setInfo] = useState({start: null, end: null});
	const [isJob, setIsJob] = useState(false);
	const [frequency, setFrequency] = useState(PayrollFrequency.WEEKLY);

	const submit = async () => {
		try {
			if (isJob) {
				const res = await axios.post("/accounting/payroll/job", {
					nextRunAt: info.start,
					frequency,
					employees: employees.map(emp => emp._id),
				});

				dispatch(
					addNotification({
						type: notifTags.success,
						message: "Payroll report created",
					})
				);
				history.push(`/accounting/payroll/job/${res.data._id}`);
			} else {
				const res = await axios.post("/accounting/payroll/report", {
					start: info.start,
					end: info.end,
					employees: employees.map(emp => emp._id),
				});

				dispatch(
					addNotification({
						type: notifTags.success,
						message: "Payroll report created",
					})
				);
				history.push(`/accounting/payroll/${res.data._id}`);
			}
		} catch (err) {
			const {message} = await errorHandler(err);
			dispatch(
				addNotification({
					type: notifTags.error,
					message: `Error creating payroll report ${message}`,
				})
			);
		}
	};

	return (
		<AccountingFolderContainer>
			<Main>
				<Column style={{width: "100%"}}>
					<Title>Payroll Info </Title>{" "}
					<ToggleButton
						label="Create payroll job"
						value={isJob}
						onChange={() => setIsJob(!isJob)}
					/>
					<DatePicker
						type="date"
						label={isJob ? "Job Start Date" : "Start"}
						value={info.start}
						onChange={v => setInfo(old => ({...old, start: v}))}
					/>
					{isJob ? (
						<Selector
							label="Payroll Frequency"
							options={PayrollFrequencyOptions}
							value={frequency}
							onSelect={v => setFrequency(v)}
						/>
					) : (
						<DatePicker
							type="date"
							label="End"
							value={info.end}
							onChange={v => setInfo(old => ({...old, end: v}))}
						/>
					)}
					{isJob && info.start && (
						<Title>
							Next payroll: {determineNextRun(frequency, info.start)}
						</Title>
					)}
					<Button style={{marginTop: "auto"}} onClick={submit}>
						{isJob ? "Create Payroll Job" : "Run Payroll"}
					</Button>
				</Column>
				<Column style={{width: "100%", gap: "5px"}}>
					<Title>
						Employees{" "}
						<Button
							style={{
								background: theme.BUTTON_COLOR_GREY,
								width: "100px",
								fontSize: "14px",
							}}
							onClick={() => history.push("/accounting/payroll")}
						>
							Cancel
						</Button>
					</Title>
					<UserSelector employees={employees} setEmployees={setEmployees} />
				</Column>
			</Main>
		</AccountingFolderContainer>
	);
};
export default PayrollManagement;
