import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Modal,
  Header,
  List,
  Icon,
  Popup,
  Select,
} from "semantic-ui-react";
import cookie from "js-cookie";
import axios from "axios";
import PayScale from "./PayScale";
import Theme from "Theme";

const Employees = ({ props }) => {
  const [editUser, setEditUser] = useState(null);
  const [searchBy, setSearchBy] = useState("name");
  const [search, setSearch] = useState();
  const [users, setUsers] = useState([]);
  const [addUser, setAddUser] = useState({});
  const [payRate, setPayRate] = useState(0);
  const [billRate, setBillRate] = useState(0);
  const [overtimeBillRate, setOvertimeBillRate] = useState(0);
  const [overtimePayRate, setOvertimePayRate] = useState(0);
  const [addError, setAddError] = useState("");

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
      "token"
    )}`;
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        params: {
          search: search,
          limit: "100",
          searchBy: searchBy,
        },
      })
      .then(async (res) => {
        if (res.data.users.length < 1) setUsers([]);
        else setUsers(res.data.users);
      });
  }, [search, searchBy]);

  const checkEmployees = (employees, check) => {
    var found = false;
    employees.forEach((cur) => {
      if (cur.user === check) {
        found = true;
        return null;
      }
    });
    return found;
  };

  const payOptions = () => {
    let temp = [];
    props.values.payscale.map((row, index) => {
      let newLabel =
        row.label +
        "  | Pay: $" +
        (row.payRate / 100).toFixed(2) +
        "  |   Bill: $" +
        (row.billRate / 100).toFixed(2);
      temp.push({ text: newLabel, row, value: row, key: index });
    });
    return temp;
  };

  return (
    <Grid>
      <Header
        as="h3"
        style={{
          borderTop: "2px solid white",
          color: "white",
          margin: "auto",
          width: "100%",
          paddingTop: "1%",
        }}
      >
        Employees
      </Header>
      <Grid.Row
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Form.Dropdown
          selection
          onChange={(event, { name, value }) => {
            setSearchBy(value);
          }}
          defaultValue="name"
          options={[
            { text: "Name", value: "name" },
            { text: "Email", value: "email" },
            { text: "Phone Number", value: "number" },
          ]}
          placeholder="Search By"
        />
        <Form.Input
          onChange={(event, { name, value }) => {
            setSearch(value);
          }}
          style={{ color: "blue", paddingLeft: 10 }}
          icon="search"
          iconPosition="left"
          placeholder={`Search by ${searchBy}`}
        />
        <PayScale
          payscale={props.values.payscale}
          setPayscale={(edit, type) => {
            let temp = props.values.payscale;
            if (type === "delete") {
              temp.splice(edit, 1);
            } else if (type === "edit") {
              temp[edit.index] = { ...edit };
            } else {
              temp.push(edit);
            }
            props.setFieldValue("payscale", temp);
          }}
        />
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column
          style={{
            border: "2px solid #333333",
            borderRadius: 10,
            paddingTop: "1vh",
            backgroundColor: "white",
          }}
        >
          <Header as="h3">Users</Header>
          <List
            divided
            style={{
              height: "25vh",
              overflowY: "scroll",
            }}
          >
            {users.map((user, index) => {
              if (checkEmployees(props.values.employees, user._id)) return null;
              return (
                <Popup
                  key={index}
                  content={
                    <div>
                      <Header as="h5">
                        Email: {user.email}
                        <br />
                        Phone: {user.phone}
                      </Header>
                    </div>
                  }
                  trigger={
                    <List.Item>
                      <List.Content>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Header as="h5">
                            {searchBy === "name"
                              ? user.firstName + " " + user.lastName
                              : user[searchBy]}
                          </Header>
                          <Button
                            onClick={() => setAddUser(user)}
                            type="button"
                            icon
                          >
                            <Icon name="add" />
                          </Button>
                        </div>
                      </List.Content>
                    </List.Item>
                  }
                />
              );
            })}
          </List>
        </Grid.Column>
        <Grid.Column
          style={{
            border: "2px solid #333333",
            borderRadius: 10,
            paddingTop: "1vh",
            backgroundColor: "white",
          }}
        >
          <Header as="h3">Selected Users</Header>
          <List
            divided
            style={{
              height: "25vh",
              overflowY: "scroll",
            }}
          >
            {props.values.team.map((cur, index) => {
              if (!cur.user) return null;
              return (
                <Popup
                  wide="very"
                  style={{ width: "15vw" }}
                  position="bottom left"
                  key={index}
                  content={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Header as="h6">
                          {cur.user.email}
                          <br />
                          {cur.user.phone}
                        </Header>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "auto 0 auto auto",
                        }}
                      >
                        <Header as="h6">
                          PayRate: ${(cur.payRate / 100).toFixed(2)}
                          {"  "}($
                          {(cur.overtimePayRate / 100).toFixed(2)})
                          <br />
                          BillRate: ${(cur.billRate / 100).toFixed(2)}
                          {"  "}($
                          {(cur.overtimeBillRate / 100).toFixed(2)})
                        </Header>
                      </div>
                    </div>
                  }
                  trigger={
                    <List.Item>
                      <List.Content>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Header as="h5">
                            {searchBy === "name"
                              ? cur.user.firstName + " " + cur.user.lastName
                              : cur.user[searchBy]}
                          </Header>
                          <Button
                            style={{ margin: "auto 3px auto auto" }}
                            onClick={() => {
                              let temp = cur;
                              temp.index = index;
                              setAddUser(cur.user);
                              setEditUser(cur.user._id);
                              setBillRate(
                                parseFloat(temp.billRate / 100).toFixed(2)
                              );
                              setPayRate(
                                parseFloat(temp.payRate / 100).toFixed(2)
                              );
                              setOvertimeBillRate(
                                parseFloat(temp.overtimeBillRate / 100).toFixed(
                                  2
                                )
                              );
                              setOvertimePayRate(
                                parseFloat(temp.overtimePayRate / 100).toFixed(
                                  2
                                )
                              );
                            }}
                            type="button"
                            icon
                          >
                            <Icon name="edit" />
                          </Button>
                          <Button
                            style={{ margin: " 0" }}
                            onClick={() => {
                              props.setFieldValue(
                                "team",
                                props.values.team.filter(
                                  (team) => team.user._id !== cur.user._id
                                )
                              );
                              props.setFieldValue(
                                "employees",
                                props.values.employees.filter(
                                  (emp) => emp.user !== cur.user._id
                                )
                              );
                            }}
                            type="button"
                            icon
                          >
                            <Icon name="delete" />
                          </Button>
                        </div>
                      </List.Content>
                    </List.Item>
                  }
                />
              );
            })}
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {props.errors.general && (
          <div
            style={{
              color: "blue",
              margin: "-1vh auto .5vh auto",
              textAlign: "center",
            }}
          >
            {props.errors.general}
          </div>
        )}

        <Modal
          style={{
            padding: "2%",
            marginTop: "20%",
            maxWidth: 700,
            backgroundColor: Theme.PAGE_BACKGROUND,
          }}
          open={!!addUser.firstName}
          onClose={() => {
            setAddUser({});
            setEditUser(null);
            setPayRate(0);
            setBillRate(0);
            setOvertimePayRate(0);
            setOvertimeBillRate(0);
          }}
        >
          <Grid centered>
            <Grid.Row>
              <Header as="h2" style={{ color: "white" }}>
                {" Adding: "}
                {addUser && addUser.firstName + " " + addUser.lastName}
              </Header>
            </Grid.Row>
            <Grid.Column style={{ width: "100%", textAlign: "center" }}>
              <Header color="blue">Select a Pay Scale</Header>
              <Form.Dropdown
                selection
                options={payOptions()}
                onChange={(event, { name, value }) => {
                  setBillRate((value.billRate / 100).toFixed(2));
                  setPayRate((value.payRate / 100).toFixed(2));
                  setOvertimeBillRate(
                    ((value.billRate + value.billRate / 2) / 100).toFixed(2)
                  );
                  setOvertimePayRate(
                    ((value.payRate + value.payRate / 2) / 100).toFixed(2)
                  );
                }}
                style={{ margin: "auto", width: "80%" }}
              />
              <Header color="blue">Or Enter Manually</Header>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as="h4" style={{ color: "white" }}>
                  Pay Rate
                </Header>
                <Form.Input
                  type="number"
                  onChange={(event, { name, value }) => {
                    setPayRate(parseFloat(value));
                    setOvertimePayRate(parseFloat(value * 1.5).toFixed(2));
                  }}
                  style={{ color: "blue" }}
                  icon="dollar"
                  onBlur={() => setPayRate((payRate || 0).toFixed(2))}
                  value={payRate}
                  iconPosition="left"
                />
              </Grid.Column>
              <Grid.Column width={2} />
              <Grid.Column width={5}>
                <Header as="h4" style={{ color: "white" }}>
                  Bill Rate
                </Header>
                <Form.Input
                  type="number"
                  onChange={(event, { name, value }) => {
                    setBillRate(parseFloat(value));
                    setOvertimeBillRate(parseFloat(value * 1.5).toFixed(2));
                  }}
                  onBlur={() => setBillRate((billRate || 0).toFixed(2))}
                  style={{ color: "blue" }}
                  icon="dollar"
                  value={billRate}
                  iconPosition="left"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as="h4" style={{ color: "white" }}>
                  Overtime Pay Rate
                </Header>
                <Form.Input
                  type="number"
                  onChange={(event, { name, value }) =>
                    setOvertimePayRate(parseInt(value))
                  }
                  onBlur={() =>
                    setOvertimePayRate((overtimePayRate || 0).toFixed(2))
                  }
                  style={{ color: "blue" }}
                  icon="dollar"
                  value={overtimePayRate}
                  iconPosition="left"
                />
              </Grid.Column>
              <Grid.Column width={2} />
              <Grid.Column width={5}>
                <Header as="h4" style={{ color: "white" }}>
                  Overtime Bill Rate
                </Header>
                <Form.Input
                  type="number"
                  onChange={(event, { name, value }) =>
                    setOvertimeBillRate(parseInt(value))
                  }
                  onBlur={() =>
                    setOvertimeBillRate((overtimeBillRate || 0).toFixed(2))
                  }
                  style={{ color: "blue" }}
                  icon="dollar"
                  value={overtimeBillRate}
                  iconPosition="left"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Header color="red" as="h3">
                {addError}
              </Header>
            </Grid.Row>
            <Button
              color="blue"
              onClick={() => {
                if (!billRate) setAddError("Missing Bill Rate");
                else if (!payRate) setAddError("Missing Pay Rate");
                else if (!overtimePayRate) setAddError("Missing Overtime Pay");
                else if (!overtimeBillRate)
                  setAddError("Missing Overtime Bill");
                else {
                  var temp = props.values.employees;
                  if (editUser != null) {
                    temp.forEach((emp, index) => {
                      if (emp.user === editUser) {
                        emp.payRate = payRate * 100;
                        emp.billRate = billRate * 100;
                        emp.overtimePayRate = overtimePayRate * 100;
                        emp.overtimeBillRate = overtimeBillRate * 100;
                        return;
                      }
                    });
                  } else
                    temp.push({
                      user: addUser._id,
                      payRate: payRate * 100,
                      billRate: billRate * 100,
                      overtimePayRate: overtimePayRate * 100,
                      overtimeBillRate: overtimeBillRate * 100,
                    });
                  props.setFieldValue("employees", temp);

                  temp = props.values.team;
                  if (editUser != null) {
                    temp.forEach((emp, index) => {
                      if (emp.user._id === editUser) {
                        emp.payRate = payRate * 100;
                        emp.billRate = billRate * 100;
                        emp.overtimePayRate = overtimePayRate * 100;
                        emp.overtimeBillRate = overtimeBillRate * 100;
                        return;
                      }
                    });
                  } else
                    temp.push({
                      user: addUser,
                      payRate: payRate * 100,
                      billRate: billRate * 100,
                      overtimePayRate: overtimePayRate * 100,
                      overtimeBillRate: overtimeBillRate * 100,
                    });
                  props.setFieldValue("team", temp);
                  setAddUser({});
                  setEditUser(null);
                  setPayRate(0);
                  setBillRate(0);
                  setOvertimePayRate(0);
                  setOvertimeBillRate(0);
                }
              }}
              content={editUser ? "Edit User" : "Add User"}
            />
          </Grid>
        </Modal>
      </Grid.Row>
    </Grid>
  );
};

export default Employees;
