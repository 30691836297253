import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import React, { useState } from "react";
import { Button, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import { getTheme } from "Theme";
import Modal from "./Modal";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const Container = styled.div`
  background-color: ${theme.PAGE_BACKGROUND};
  border-radius: 25px;
  width: 650px;
  height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: auto auto 10px auto;
`;

const Title = styled.div`
  font-size: 26px;
  color: ${theme.MAIN_COLOR};
  font-weight: 800;
  text-align: center;
  margin-bottom: 10px;
`;

const Note = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid ${theme.MAIN_COLOR};
  border-radius: 10px;
  margin: 10px;
  padding: 5px;
`;

const Name = styled.h3`
  margin-bottom: 2px;
`;

const DateTag = styled.div`
  color: grey;
  font-weight: 400;
  margin-bottom: 5px;
`;

const Action = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

const NotesModal = ({ notes, type, id, noButton, onClose }) => {
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(null);
  const [error, setError] = useState("");
  const [newNotes, setNewNotes] = useState(notes);

  const saveNote = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
      "token"
    )}`;
    axios
      .put(`${process.env.REACT_APP_API_URL}/note`, { type, id, note: add })
      .then((res) => {
        setAdd(null);
        setNewNotes(res.data.notes);
      })
      .catch((error) => setError(error));
  };

  const spaceNote = (note) => {
    let lines = note.split("\n");
    return lines;
  };
  if (!notes) return null;
  if (!open && !noButton)
    return (
      <Button type="button" onClick={() => setOpen(true)}>
        View Edit History
      </Button>
    );
  if (open || noButton)
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          onClose && onClose();
        }}
        closeIcon
      >
        <Main>
          <Title>Edit History Notes</Title>
          {add !== null ? (
            <TextArea
              style={{ width: "90%", height: "20vh" }}
              value={add}
              onChange={(e, v) => setAdd(v.value)}
            />
          ) : (
            <Container>
              {newNotes.map((note, index) => (
                <Note index={index}>
                  <Name>{note.user}</Name>
                  <DateTag>{moment(note.date).format("lll z")}</DateTag>

                  {note.note &&
                    spaceNote(note?.note).map((line, i) => (
                      <Action>{line}</Action>
                    ))}
                </Note>
              ))}
            </Container>
          )}
          {add !== null ? (
            <Row>
              <Button onClick={() => setAdd(null)}>Cancel</Button>
              <Button color="blue" onClick={() => saveNote()}>
                Save Note
              </Button>
            </Row>
          ) : (
            <Button color="blue" onClick={() => setAdd("")}>
              Add Note
            </Button>
          )}
        </Main>
      </Modal>
    );
  return null;
};

export default NotesModal;
