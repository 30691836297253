import axios from "axios";
import { Button, DatePicker, Error, Input, Modal, Row } from "globalComponents";
import FileUploader from "globalComponents/FileUploader";
import { imgExtensions } from "globalResources/constants";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 20px 40px;
`;

const Header = styled.h2`
  text-align: center;
  font-size: 28px;
`;

const Image = styled.img`
  max-height: 200px;
`;

const Preview = styled.a`
  font-size: 18px;
  font-weight: 800;
  color: ${theme.BUTTON_COLOR};
  margin: 10px auto;
  padding: 10px 50px;
  background-color: ${theme.INPUT_BACKGROUND};
  border-radius: 20px;
`;

const CheckModal = ({ onClose, report, setCheck }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const saveCheck = () => {
    axios
      .post("/report/check-info", {
        id: report._id,
        url: report.check.url,
        number: report.check.number,
        timestamp: moment
          .tz(report.check.timestamp, report.client.timezone)
          .utc(),
      })

      .then(() => {
        onClose();
        dispatch(
          addNotification({
            type: notifTags.success,
            message: "Saved",
          })
        );
      })
      .catch((err) => {
        const message = "Failed to save check information";
        setError(message);
        dispatch(
          addNotification({
            type: notifTags.error,
            message,
          })
        );
      });
  };

  const isImage = () => {
    const type = report.check.url
      ? report.check.url
          .substring(report.check.url.lastIndexOf(".") + 1)
          .toUpperCase()
      : "";
    if (imgExtensions.includes(type)) return true;
    return false;
  };
  return (
    <Modal onClose={onClose}>
      <Main>
        <Header>Check Info</Header>
        <Error>{error}</Error>
        <Input
          value={report.check.number}
          label="Number"
          onChange={(e) =>
            setCheck((old) => ({
              ...old,
              check: { ...old.check, number: e.target.value },
            }))
          }
        />
        {report.check.url ? (
          isImage() ? (
            <Image src={report.check.url} />
          ) : (
            <Preview href={report.check.url} target="_blank">
              View Check
            </Preview>
          )
        ) : null}
        <FileUploader
          label="Upload Check"
          namePrefix={`checks/${report._id}-${report.client._id}`}
          noFileName={true}
          onUrl={(url) =>
            setCheck((old) => ({ ...old, check: { ...old.check, url } }))
          }
        />
        <DatePicker
          label={"Payment Date"}
          value={moment
            .tz(report.check.timestamp, report.client.timezone)
            .format("YYYY-MM-DDTHH:mm")}
          onChange={(v) =>
            setCheck((old) => ({
              ...old,
              check: { ...old.check, timestamp: v },
            }))
          }
        />
        <Row style={{ gap: "10px" }}>
          <Button color={theme.BUTTON_COLOR_GREY} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => saveCheck()}>Save Check Info</Button>
        </Row>
      </Main>
    </Modal>
  );
};
export default CheckModal;
