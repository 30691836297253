const theme = {
  PAGE_BACKGROUND: "#4C4C4C",
  MAIN_COLOR: "#3075FF",
  SECONDARY_COLOR: "#D9E6FF",
  RED: "#FF5757",
  BORDER_COLOR: "#666666",
  BLACK: "#1B1B1B",
};

export const getTheme = () => {
  return {
    PAGE_BACKGROUND: "#FFFFFF",
    MAIN_COLOR: "rgba(48, 117, 255,.9)",
    SECONDARY_COLOR: "rgba(217, 230, 255.95)",
    MANILLA: "#f2e6a9",
    BORDER: "#a8b3b3",
    TEXT: "#1B1B1B",
    ICONS: "#1B1B1B",
    NEGATIVE: "#FF5757",
    NOTIFICATIONS: "#FF5757",
    BUTTON_COLOR: "rgba(48, 117, 255,.9)",
    BUTTON_TEXT: "#FFFFFF",
    BUTTON_COLOR_NEGATIVE: "#FF5757",
    BUTTON_COLOR_GREY: "rgb(99, 90, 90)",
    SHADOW: "rgba(0,0,0,.6)",
    INPUT_BORDER: "#4682ff6b",
    MESSAGE_BACKGROUND: "rgba(255,255,255,1)",
    MESSAGE_TEXT: "rgba(0,0,0,.9)",
    INPUT_BACKGROUND: "rgba(255,255,255,1)",
    INPUT_TEXT: "rgba(0,0,0,.65)",
    TABLE_ROW: "rgba(255,255,255,.9)",
    TABLE_ROW_ALT: "#4682ff57",
    HEADER_TEXT: "rgba(255,255,255,1)",
    TABLE_ARROW_ACTIVE: "#6ad171",
    TABLE_ARROW_INACTIVE: "#ffffff",
    SUCCESS: "rgba(105, 209, 113,.95)",
    ERROR: "rgba(255, 87, 88,.95)",
    WARNING: "rgba(223, 224, 51,.9)",
    INFO: "rgb(48, 117, 255, .95)",
  };
};

export const getButtonColor = (color) => {
  const { BUTTON_COLOR_GREY, BUTTON_COLOR_NEGATIVE, MAIN_COLOR } = getTheme();
  switch (color) {
    case "grey":
      return BUTTON_COLOR_GREY;
    case "red":
      return BUTTON_COLOR_NEGATIVE;
    default:
      return MAIN_COLOR;
  }
};

export const resetButtonStyles = () => {
  return `
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  `;
};

export default theme;
