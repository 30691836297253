import React, { useEffect, useRef, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { Parser } from "json2csv";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/slices/uiSlice";
import { notifTags } from "./Notification";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExportCSV = ({ data, filename, label = "Export CSV" }) => {
  const [file, setFile] = useState("");
  const dispatch = useDispatch();
  const exportCSV = () => {
    const parser = new Parser();
    const csv = parser.parse(data);
    const blob = new Blob([csv]);
    setFile(URL.createObjectURL(blob));
    dispatch(
      addNotification({
        type: notifTags.success,
        message: "CSV created!",
      })
    );
  };
  const ref = useRef(null);

  useEffect(() => {
    if (file) ref.current.click();
  }, [file]);
  return (
    <>
      <Popup
        content="Export the current table view as a csv"
        trigger={
          <Button
            style={{ height: 30 }}
            icon
            compact
            circular
            color="blue"
            onClick={() => exportCSV()}
          >
            {label}
          </Button>
        }
      />
      <a
        style={{ display: "none" }}
        download={`${filename}.csv`}
        href={file}
        ref={ref}
      />
    </>
  );
};
export default ExportCSV;
