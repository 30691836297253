import React, { useState } from "react";
import { Button, Modal, Input } from "semantic-ui-react";
import styled from "styled-components";
import theme from "Theme";

const List = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  background-color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  color: white;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-size: 14px;
  font-weigt: 700;
  margin-right: auto;
`;

const Rate = styled.div`
  font-size: 12px;
  margin: 0 10px 0 10px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin: -10px auto 20px auto;
  text-align: center;
`;

const PayScale = ({ payscale, setPayscale }) => {
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(null);
  if (!open)
    return (
      <Button
        color="blue"
        type="button"
        onClick={() => setOpen(true)}
        style={{ margin: "auto" }}
      >
        Edit Payscale
      </Button>
    );
  return (
    <Modal
      open={open}
      centered
      onOpen={() => this.setState({ open: true })}
      onClose={() => setOpen(false)}
      closeIcon
      style={{
        textAlign: "center",
        backgroundColor: theme.MAIN_COLOR,
        padding: "1vh",
      }}
    >
      {add && (
        <>
          <Input
            fluid
            style={{ margin: "auto 10% auto 10%" }}
            label="Level Name"
            type="text"
            value={add.label}
            onChange={(e, { name, value }) => setAdd({ ...add, label: value })}
          />
          <Row>
            <Input
              label="Pay Rate"
              type="number"
              value={add.payRate}
              onBlur={() =>
                setAdd({ ...add, payRate: parseInt(add.payRate).toFixed(2) })
              }
              onChange={(e, { name, value }) =>
                setAdd({ ...add, payRate: parseInt(value) })
              }
            />
            <Input
              label="Bill Rate"
              type="number"
              value={add.billRate}
              onBlur={() =>
                setAdd({ ...add, billRate: parseInt(add.billRate).toFixed(2) })
              }
              onChange={(e, { name, value }) =>
                setAdd({ ...add, billRate: parseInt(value) })
              }
            />
          </Row>
        </>
      )}
      <Button
        color="black"
        type="button"
        onClick={() =>
          add
            ? setPayscale(
                {
                  ...add,
                  payRate: add.payRate * 100,
                  billRate: add.billRate * 100,
                },
                "add"
              )
            : setAdd({ label: "", billRate: 0, payRate: 0 })
        }
      >
        Add Level to Payscale
      </Button>
      <List>
        <Title>Pay Scale Brackets</Title>
        {payscale.length
          ? payscale.map((row, index) => (
              <ListRow>
                <Label>{row.label}</Label>
                <Rate style={{ marginLeft: "auto" }}>
                  Pay Rate: ${(row.payRate / 100).toFixed(2)}
                </Rate>
                <Rate>Bill Rate: ${(row.payRate / 100).toFixed(2)}</Rate>
                <Button
                  icon="close"
                  color="red"
                  type="button"
                  onClick={() => setPayscale(index, "delete")}
                />
              </ListRow>
            ))
          : "Add Some Payscale Levels Above"}
      </List>
    </Modal>
  );
};

export default PayScale;
