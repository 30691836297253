import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();
const Background = styled.div`
  display: flex;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`;
const Main = styled.div`
  display: flex;
  background-color: ${theme.MANILLA};
  min-height: 200px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 5%;
  width: 800px;
  padding: 10px;
  border-radius: 10px;
  justify-content: center;
  z-index: 100;
  overflow: auto;
`;

const Modal = ({ children, onClose }) => {
  return (
    <>
      <Background onClick={onClose} />
      <Main>{children}</Main>
    </>
  );
};

export default Modal;
