import axios from "axios";
import {
  FormColumn,
  FormRow,
  Input,
  Modal,
  ToggleButton,
  SelectorSearch,
  ItemList,
  Item,
  Button,
} from "globalComponents";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 16px;
`;

const defaultForm = {
  name: "",
  description: "",
  notifyBilled: false,
  notifyReview: false,
  users: [],
  permissions: [],
  team: [],
};

const defaultErrors = {
  ...defaultForm,
  notifyBilled: "",
  notifyReview: "",
  users: "",
  permissions: "",
};
const GroupEdit = ({ selected, onClose }) => {
  const dispatch = useDispatch();
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [form, setForm] = useState(defaultForm);
  const [userSearch, setUserSearch] = useState("");

  useEffect(() => {
    if (!selected) return;
    if (selected._id) setForm({ ...selected });
    else setForm(defaultForm);
  }, [selected]);

  useEffect(() => {
    fetchUsers();
    fetchPermissions();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [userSearch]);
  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        params: { search: userSearch, searchBy: "name" },
      })
      .then(async (res) => {
        setUserOptions(
          res.data.users.map((user) => ({
            label: `${user.firstName} ${user.lastName} (${user.accountType})`,
            value: JSON.stringify(user),
          }))
        );
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setErrors((old) => ({ ...old, general: message }));
      })
      .finally(() => setLoading(false));
  };

  const fetchPermissions = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/permissions`, {
        params: { full: true },
      })
      .then(async (res) => {
        setPermissionOptions(
          res.data.permissions.map((p) => ({
            label: p.name,
            value: JSON.stringify(p),
          }))
        );
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setErrors((old) => ({ ...old, general: message }));
      })
      .finally(() => setLoading(false));
  };

  const submit = () => {
    axios[selected._id ? "patch" : "post"](
      `${process.env.REACT_APP_API_URL}/group`,
      {
        id: selected._id,
        ...form,
        users: form.team.map((t) => t._id),
      }
    )
      .then((res) => {
        onClose();
        dispatch(
          addNotification({
            type: notifTags.success,
            message: "User created",
          })
        );
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setErrors((old) => ({ ...old, general: message }));
        dispatch(addNotification({ type: notifTags.error, message }));
      })
      .finally(() => setLoading(false));
  };

  const updateForm = (value, name, isDelete) => {
    if (name === "users" || name === "permissions") {
      name = name === "users" ? "team" : "permissions";
      setForm((old) => ({
        ...old,
        [name]: isDelete
          ? old[name].filter((t) => t._id !== value)
          : old[name].concat([JSON.parse(value)]),
      }));
    } else if (isDelete) {
      setForm((old) => ({
        ...old,
        [name]: old[name].filter((i) => i !== value),
      }));
    } else setForm((old) => ({ ...old, [name]: value }));
    setErrors((old) => ({ ...old, [name]: "" }));
  };

  const disable = async () => {
    try {
      const res = await axios.patch("/group/archive", {
        id: selected._id,
      });
      onClose();
    } catch (err) {
      const { message } = await errorHandler(err);
      setErrors((old) => ({ ...errors, general: message }));
    }
  };

  function getIds(users) {
    var ids = [];
    users.forEach((group) => {
      ids.push(group._id);
    });
    return ids;
  }

  function filterPermission() {
    let idArray = form.permissions.map((p) => p.name);
    return permissionOptions.filter(
      (o) => !idArray.includes(JSON.parse(o.value).name)
    );
  }

  const getByValue = (value, list) => {
    return list.find((item) => item.value === value);
  };

  if (!selected) return null;

  return (
    <Modal onClose={onClose}>
      <Main>
        <Input
          label="Name"
          value={form.name}
          onChange={(e) => updateForm(e.currentTarget.value, "name")}
          error={errors.name}
        />
        <Input
          label="Description"
          value={form.description}
          onChange={(e) => updateForm(e.currentTarget.value, "description")}
          error={errors.description}
        />
        <FormRow style={{ gap: "20px" }}>
          <ToggleButton
            label="Receive Billed Notifications"
            value={form.notifyBilled}
            onChange={() => updateForm(!form.notifyBilled, "notifyBilled")}
          />
          <ToggleButton
            label="Review Notifications"
            value={form.notifyReview}
            onChange={() => updateForm(!form.notifyReview, "notifyReview")}
          />
        </FormRow>
        <FormRow>
          {/* <FormColumn>
            <SelectorSearch
              fillSearch={false}
              value=""
              label="Add User"
              onSearch={(s) => {
                setUserSearch(s);
              }}
              onSelect={(v) => updateForm(v, "users")}
              options={userOptions.filter(
                (g) =>
                  g.label !== "All" &&
                  !form.team
                    .map((t) => t?._id)
                    .includes(JSON.parse(g.value)?._id)
              )}
            />
            <ItemList>
              {form.team.map((g) =>
                g ? (
                  <Item onClick={() => updateForm(g._id, "users", true)}>
                    {g.firstName} {g.lastName} ({g.accountType})
                  </Item>
                ) : null
              )}
            </ItemList>
          </FormColumn> */}
          <FormColumn>
            <SelectorSearch
              fillSearch={false}
              value=""
              label="Add Permission"
              onSelect={(v) => updateForm(v, "permissions")}
              options={filterPermission()}
            />
            <ItemList>
              {form.permissions.map((p) => (
                <Item onClick={() => updateForm(p._id, "permissions", true)}>
                  {p.name}
                </Item>
              ))}
            </ItemList>
          </FormColumn>
        </FormRow>
        <FormRow>
          <Button onClick={disable} color="grey">
            {selected.isArchived ? "Enable" : "Disable"}
          </Button>
          <Button onClick={submit}>Save Changes</Button>
        </FormRow>
      </Main>
    </Modal>
  );
};
export default GroupEdit;
