import { clearCookies } from "globalResources/helpers";
import React, { useEffect } from "react";
import { setUser } from "store/slices/userSlice";

const Logout = () => {
  useEffect(async () => {
    await clearCookies();
    await setUser({});
    window.location.replace("/home");
  });
  return <div>Logging You Out...</div>;
};
export default Logout;
