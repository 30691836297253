import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import Papa from "papaparse";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Preview = styled.a``;

const FileUploader = ({ label, onParse }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const onUpload = async (event) => {
    setLoading(true);
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      Papa.parse(file, {
        complete: (e) => {
          onParse(e.data);
          ref.current.value = "";
        },
      });
    }
  };

  return (
    <Main>
      {file && (
        <Preview href={file.url} target="_blank">
          {file.name}
        </Preview>
      )}
      <Button onClick={() => ref.current.click()}>{label}</Button>
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        onChange={onUpload}
      />
    </Main>
  );
};
export default FileUploader;
