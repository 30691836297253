import React from "react";
import { Button, Message, Modal, Header } from "semantic-ui-react";
import Theme from "../Theme.js";

class PermissionDenied extends React.Component {
  state = {
    open: false
  };
  render() {
    const { onClose, permission } = this.props;
    return (
      <Modal
        open={!!permission}
        centered
        onOpen={() => this.setState({ open: true })}
        onClose={onClose}
        closeIcon
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: "1vh",
          maxWidth: 600
        }}
      >
        <Message
          style={{
            border: "2px solid rgba(0,0,0,0.6)",
            backgroundColor: Theme.PAGE_BACKGROUND,
            marginBottom: "1vh"
          }}
        >
          <Header as="h1" style={{ marginBottom: "5%", color: Theme.RED }}>
            {" "}
            Missing Required Permission{" "}
          </Header>
          <Header as="h2" style={{ color: "white" }}>
            {`
            If this is something you need to complete your job function please
            ask a manager or admin to give you the permission: "${this.props.permission}"`}
          </Header>
        </Message>

        <Button type="button" color="blue" onClick={onClose} fluid size="large">
          I Understand
        </Button>
      </Modal>
    );
  }
}

export default PermissionDenied;
