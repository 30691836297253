import React, { useEffect } from "react";
import axios from "axios";
import { useSearchParam } from "../../hooks";
import { Message, Header, Image, Button } from "semantic-ui-react";
import { useHistory } from "react-router";
import Theme from "../../Theme.js";
import Logo from "../../assets/logos/full.png";

export default function Invoice() {
  const id = useSearchParam("id").split("&")[0];
  const accountId = useSearchParam("accountId");
  const history = useHistory();

  useEffect(() => {
    download();
  }, []);

  const download = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/invoice`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
        },
        params: { id, accountId },
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `invoice#${id}.pdf`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return (
    <div
      style={{
        backgroundColor: Theme.PAGE_BACKGROUND,
        display: "flex",
        height: "100vh",
      }}
    >
      <Message
        style={{
          justifyContent: "center",
          textAlign: "center",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        <Image
          style={{
            margin: "0vh auto 1vh auto",
            height: 200,
          }}
          src={Logo}
        />
        <Header as="h1">Invoice Download Started.</Header>
        <Header as="h3" color="grey">
          If your download didn't start click download below
        </Header>
        <Button color="blue" onClick={download}>
          Download
        </Button>
        <Button color="grey" onClick={() => history.push("/")}>
          Back To TCS Login
        </Button>
      </Message>
    </div>
  );
}
