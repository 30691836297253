import axios from "axios";
import { InfiniteTable, Table } from "globalComponents";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div``;
const Title = styled.h3`
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
  padding-bottom: 8px;
  padding-left: 8px;
`;

const headers = [
  { label: "Client Name", render: (row) => <div>{row.name}</div> },
];

const ClientList = ({ clientIds, className }) => {
  const [clients, setClients] = useState([]);
  const history = useHistory();
  const getClientSuggestions = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/client/suggestions`
      );

      setClients(
        res.data
          .filter((client) => clientIds.includes(client._id))
          .map((client) => ({ name: client.name, id: client._id }))
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getClientSuggestions();
  }, []);

  return (
    <Wrapper className={className}>
      <Link to="/clients">
        <Title>Clients ({clients.length})</Title>
      </Link>
      <InfiniteTable
        height="300px"
        headers={headers}
        data={clients}
        onClick={(row) => history.push(`/client/${row.id}/details`)}
      />
    </Wrapper>
  );
};

export default ClientList;
