import axios from "axios";
import { Button, InfiniteTable, Row } from "globalComponents";
import ExportCSV from "globalComponents/ExportCSV";
import { formatDollars } from "globalResources/helpers";
import { errorHandler } from "globalResources/util";
import React from "react";
import { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import EmployeeModal from "./EmployeeModal";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

const headers = [
  { label: "UID", render: (row) => <div>{row.user.UID}</div>, width: "30%" },
  {
    label: "Name",
    render: (row) => <div>{`${row.user.firstName} ${row.user.lastName}`}</div>,
  },
  {
    label: "Position",
    render: (row) => <div>{row.position}</div>,
  },
  {
    label: "Bill Rate",
    render: (row) => (
      <div>
        ${formatDollars(row.billRate / 100)} ($
        {formatDollars(row.overtimeBillRate / 100)})
      </div>
    ),
  },
  {
    label: "Pay Rate",
    render: (row) => (
      <div>
        ${formatDollars(row.payRate / 100)} ($
        {formatDollars(row.overtimePayRate / 100)})
      </div>
    ),
  },
];

const EmployeeList = ({ employees = [], client, updateDetails }) => {
  const [employee, setEmployee] = useState();

  const update = (employee, action) => {
    let temp = client;

    if (action === "add") temp.employees.push(employee);
    else if (action === "update")
      temp.employees.forEach((e, i) => {
        if (
          e.user === employee.user &&
          employee.previousPosition === e.position
        ) {
          temp.employees[i] = employee;
        }
        return;
      });
    else
      temp.employees = temp.employees.filter(
        (e) =>
          e.user !== employee.user || employee.previousPosition !== e.position
      );

    axios
      .patch("/client", { ...temp, id: temp._id })
      .then((res) => updateDetails(res.data))
      .catch(async (error) => {
        const { message } = await errorHandler(error);
      });
  };

  const format4CSV = () => {
    return employees.map((row) => ({
      UID: row.user.UID,
      externalId: row.user.externalId,
      EmployeeName: `${row.user.firstName} ${row.user.lastName}`,
      AddressLine1: row.user.addressLine1,
      AdderssLine2: row.user.addressLine2,
      State: row.user.state,
      Zipcode: row.user.zipcode,
      Position: row.position,
      accountType: row.user.accountType,
      TaxType: row.user.employeeType,
      BillRate: (row.billRate / 100).toFixed(2),
      PayRate: (row.payRate / 100).toFixed(2),
      ClientName: client.name,
      ClientAddress: `${client.address} ${client.city} ${client.state}, ${client.zipcode}`,
    }));
  };

  return (
    <Main>
      <Row style={{ alignItems: "center", padding: "10px 0" }}>
        <Title>Employees ({employees.length})</Title>
        <Row style={{ marginLeft: "auto", alignItems: "center" }}>
          <ExportCSV
            label="Export Employees"
            data={format4CSV()}
            filename={`${client.name}-employees-${moment().format("lll")}.csv`}
          />
          <Button width="150px" onClick={() => setEmployee({})}>
            + Add Employee
          </Button>
        </Row>
      </Row>
      <InfiniteTable
        height="400px"
        data={employees}
        headers={headers}
        onClick={(row) => setEmployee(row)}
      />
      <EmployeeModal
        employees={employees}
        employee={employee}
        onClose={() => setEmployee(null)}
        onSubmit={(employee, action) => update(employee, action)}
      />
    </Main>
  );
};
export default EmployeeList;
