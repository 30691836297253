import axios from "axios";
import { Column } from "globalComponents";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import React, { useState } from "react";
import { Button, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import { getTheme } from "Theme";
import Modal from "./Modal";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.MANILLA};
  border-radius: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;
const Container = styled.div`
  border-radius: 25px;
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: auto auto 10px auto;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 800;
  text-align: center;
`;

const Note = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid ${theme.MAIN_COLOR};
  border-radius: 10px;
  margin: 10px;
  padding: 5px;
`;

const Name = styled.h3`
  margin-bottom: 2px;
`;

const DateTag = styled.div`
  color: grey;
  font-weight: 400;
  margin-bottom: 5px;
`;

const Action = styled.div`
  display: flex;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 800;
  color: black;
  padding: 8px;
  background: ${theme.SECONDARY_COLOR};
  min-height: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const Notes = ({ notes, type, id }) => {
  const [add, setAdd] = useState(null);
  const [error, setError] = useState("");
  const [newNotes, setNewNotes] = useState(notes);

  const saveNote = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
      "token"
    )}`;
    axios
      .put(`${process.env.REACT_APP_API_URL}/note`, { type, id, note: add })
      .then((res) => {
        setAdd(null);
        setNewNotes(res.data.notes);
      })
      .catch((error) => setError(error));
  };

  const spaceNote = (note) => {
    let lines = note.split("\n");
    return lines;
  };
  return (
    <Main>
      <HeaderRow>
        User Notes
        <Button color="blue" onClick={() => setAdd(" ")}>
          New Note
        </Button>
      </HeaderRow>
      <Container>
        {newNotes
          .sort((n1, n2) => (n1.date > n2.date ? -1 : 1))
          .map((note, index) => (
            <Note index={index}>
              <Name>{note.user}</Name>
              <DateTag>{moment(note.date).format("lll z")}</DateTag>

              {note.note &&
                spaceNote(note.note).map((line, i) => <Action>{line}</Action>)}
            </Note>
          ))}
      </Container>
      {add && (
        <Modal onClose={() => setAdd(null)} closeIcon>
          <Column style={{ width: "100%", alignItems: "center" }}>
            <Title>Adding New Note</Title>
            <TextArea
              style={{ width: "90%", height: "20vh" }}
              value={add}
              onChange={(e, v) => setAdd(v.value)}
            />
            <Row>
              <Button onClick={() => setAdd(null)}>Cancel</Button>
              <Button color="blue" onClick={() => saveNote()}>
                Save Note
              </Button>
            </Row>
          </Column>
        </Modal>
      )}
    </Main>
  );
};

export default Notes;
