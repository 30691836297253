import React from "react";
import {
  Button,
  Form,
  Modal,
  Header,
  Grid,
  Icon,
  DatePicker,
  TextArea,
} from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../../../Theme.js";
import cookie from "js-cookie";
import { connect } from "react-redux";
import moment from "moment-timezone";

const SignupSchema = Yup.object().shape({
  user: Yup.string().required("Required"),
  client: Yup.string().required("Required"),
});

class Station extends React.Component {
  constructor() {
    super();

    this.state = {
      users: [],
      clients: [],
      clockin: false,
      out: false,
      open: false,
      customPayRate: false,
      customBillRate: false,
    };
  }
  componentDidMount() {
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  async beforeunload(e) {
    if (this.props.client) {
      e.preventDefault();
      await cookie.remove("token");
      e.returnValue = "You are being logged out for security reasons";
    }
  }

  verify = () => {
    const { user, password, verified } = this.state;
    if (!user) this.setState({ error: "Must select user" });
    else if (!password) this.setState({ error: "Must provide your password" });
    else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
        "token"
      )}`;
      this.setState({ loading: true });
      axios
        .post(`${process.env.REACT_APP_API_URL}/users/timeclock/login`, {
          email: this.state.user.email || null,
          phone: this.state.user.phone || null,
          error: null,
          password: this.state.password,
        })
        .then((res) => {
          this.setState({ verified: res.data.user });
          this.setState({ loading: false });
        })
        .catch(() =>
          this.setState({
            loading: false,
            error: "Trouble signing user in. Try again",
          })
        );
    }
  };
  startTimer = async () => {
    const { verified } = this.state;
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/startTimer`, {
        start: moment().utc(),
        client: this.props.client._id,
        id: verified._id,
      })
      .then(async (res) => {
        this.setState({
          verified: res.data.user,
          error: null,
          clockin: true,
          message: "You are now clocked in",
        });
      })
      .catch((err) => {
        this.setState({ error: "Error while starting timer" });
      });
  };

  endTimer = () => {
    const { verified } = this.state;
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/endTimer`, {
        start: moment(this.state.verified.currentTimer.start).utc(),
        end: moment().utc(),
        clientId: this.props.client._id,
        notes: this.state.notes,
        id: verified._id,
        station: true,
      })
      .then(async (res) => {
        this.setState(
          {
            verified: null,
            error: null,
            out: true,
            message: "You are now clocked out.",
          },
          this.fetchCurrentUser
        );
      })
      .catch((err) => {
        this.setState({ error: "Unable to create time entry" });
      });
  };
  cancelTimer = () => {
    const { verified } = this.state;
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/cancelTimer`, {
        id: verified._id,
      })
      .then(async (res) => {
        this.setState(
          {
            permission: res.data.permission,
            verified: res.data.user,
            message: "Canceled last clockin",
            error: null,
          },
          this.fetchCurrentUser
        );
      })
      .catch((err) => {
        this.setState({ error: "Error canceling timer" });
      });
  };
  render() {
    const {
      clients,
      notes,
      loading,
      verified,
      error,
      message,
      confirm,
      clockin,
      out,
    } = this.state;
    const { users, client, onClose, spanish } = this.props;
    return (
      <Modal
        open={users}
        centered
        closeIcon
        onClose={() => this.setState({ confirm: true })}
        textAlign="center"
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: "2vh",
          maxWidth: 1000,
          zIndex: 90,
          top: 5,
        }}
        verticalAlign="middle"
      >
        <Header as="h1"> {client && client.name + "Time Station"} </Header>
        <Form>
          <Grid
            style={{
              borderRadius: 10,
              backgroundColor: Theme.PAGE_BACKGROUND,
              padding: "1%",
            }}
          >
            {!verified ? (
              <Grid.Row columns={2}>
                <Grid.Column centered>
                  <Header as="h3" style={{ color: "white", margin: "auto" }}>
                    {!spanish ? "User" : "Nombre"}
                  </Header>
                  <Form.Dropdown
                    onChange={(e, v) => this.setState({ user: v.value })}
                    selection
                    search
                    options={users}
                  />
                </Grid.Column>
                <Grid.Column centered>
                  <Header as="h3" style={{ color: "white", margin: "auto" }}>
                    {!spanish ? "Password" : "Contraseña"}
                  </Header>
                  <Form.Input
                    type="password"
                    onChange={(e, v) => this.setState({ password: v.value })}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Header as="h1" style={{ margin: "auto", color: "white" }}>
                {verified && verified.firstName + " " + verified.lastName}
                <br />
                {verified.currentTimer
                  ? !spanish
                    ? "Clocked in at: "
                    : "Empezó a las" +
                      moment(verified.currentTimer.start).format(
                        "DD/MM hh:mm A"
                      )
                  : null}
              </Header>
            )}
            {verified ? (
              <Grid.Row>
                {verified.currentTimer && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Header as="h3" style={{ color: "white", margin: "auto" }}>
                      {spanish ? "notas" : "notes"}
                    </Header>
                    <TextArea
                      value={notes}
                      onChange={(e, d) => this.setState({ notes: d.value })}
                    />
                  </div>
                )}
                <Button
                  fluid
                  style={{ margin: "2vh auto auto auto" }}
                  loading={loading}
                  onClick={() => {
                    if (!verified.currentTimer) this.startTimer();
                    else this.endTimer();
                  }}
                  color="blue"
                >
                  {verified.currentTimer
                    ? spanish
                      ? "Salida"
                      : "Clock Out "
                    : spanish
                    ? "Entrada"
                    : "Clock In"}
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "2vh",
                  }}
                >
                  <Button
                    fluid
                    style={{
                      margin: "auto 1vw auto 1vw",
                      display: !verified.currentTimer && "none",
                    }}
                    loading={loading}
                    onClick={() => {
                      this.cancelTimer();
                    }}
                    color="red"
                  >
                    {spanish ? "Cancelar" : " Cancel Clock In"}
                  </Button>
                  <Button
                    fluid
                    style={{ margin: "auto 1vw auto 1vw" }}
                    loading={loading}
                    onClick={() => {
                      this.setState({
                        verified: null,
                        error: null,
                        message: "logged out user",
                      });
                    }}
                    color="white"
                  >
                    {spanish ? "cerrar sesión" : " Log Out"}
                  </Button>
                </div>
              </Grid.Row>
            ) : (
              <Button
                style={{ margin: "2vh 10% auto 10%" }}
                fluid
                loading={loading}
                onClick={this.verify}
                color="blue"
              >
                {spanish ? "Iniciar Sesión" : "Sign In"}
              </Button>
            )}
            <Header
              style={{
                margin: "1vh auto auto auto",
                color: error ? "red" : "white",
              }}
            >
              {error || message}
            </Header>
          </Grid>
        </Form>
        <Modal
          open={confirm}
          style={{
            width: "40vw",
            margin: "auto auto auto auto",
            padding: "2vw 2vw .5vw 2vw",
          }}
          centered={true}
        >
          <Header textAlign="center" style={{ margin: "auto" }}>
            Are you sure you want to exit the time station?
            <br /> You will be required to log back in.
          </Header>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              fluid
              onClick={() => {
                this.setState({ confirm: false }, onClose);
              }}
              type="button"
              icon
              color="blue"
            >
              {spanish ? "Si" : "Yes"}
            </Button>
            <Button
              fluid
              type="button"
              icon
              onClick={() => this.setState({ confirm: false })}
            >
              No
            </Button>
          </div>
        </Modal>
        <Modal
          closeIcon
          onClose={() => this.setState({ clockin: false, out: false })}
          open={clockin || out}
          style={{
            width: "40vw",
            margin: "auto auto auto auto",
            padding: "2vw 2vw .5vw 2vw",
            zIndex: 99,
            textAlign: "center",
          }}
          centered={true}
        >
          <Header>
            {clockin
              ? spanish
                ? "Has empezado"
                : "You are now clocked in"
              : spanish
              ? "Has detenido"
              : "You are now clocked out"}
          </Header>
          <Button
            style={{ margin: "auto" }}
            onClick={() => {
              this.setState({
                verified: null,
                error: null,
                clockin: null,
                out: null,
                message: "logged out user",
              });
            }}
          >
            Ok
          </Button>
        </Modal>
      </Modal>
    );
  }
}

export default Station;
