import React, { useRef, useState } from "react";
import styled from "styled-components";
import AWS from "aws-sdk";
import { Button } from "semantic-ui-react";
import axios from "axios";

AWS.config.update({
  region: "us-east-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_S3_IDENTITY_POOL,
  }),
});

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET,
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Preview = styled.a``;

const FileUploader = ({ label, namePrefix, onUrl }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const onUpload = async (event) => {
    setLoading(true);
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      const key = namePrefix + file.name;
      s3.putObject(
        {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: key,
          Body: file,
        },
        function (err, data) {
          setLoading(true);
          if (err) {
            return alert(
              "There was an error creating your album: " + err.message
            );
          }
          onUrl("https://timecard-solutions.s3.us-east-2.amazonaws.com/" + key);
          ref.current.value = "";
        }
      );
    }
  };

  return (
    <Main>
      {file && (
        <Preview href={file.url} target="_blank">
          {file.name}
        </Preview>
      )}
      <Button onClick={() => ref.current.click()}>{label}</Button>
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        onChange={onUpload}
      />
    </Main>
  );
};
export default FileUploader;
