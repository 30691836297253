import { useLocation } from "react-router";

function useSearchParam(param) {
  var path = useLocation().pathname;
  var reg = new RegExp(`${param}=[^/]+`);
  path = path.match(reg);
  if (path) return path[0].split(param + "=")[1];
  else return null;
}

export default useSearchParam;
