import axios from "axios";
import {
  ButtonRow,
  DatePicker,
  FilterBar,
  FolderContainer,
  SelectorSearch,
  Table,
  Time,
} from "globalComponents";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { timesheetHeaders } from "./components/headers";
import moment from "moment-timezone";
import { errorHandler } from "globalResources/util";
import ExportCSV from "globalComponents/ExportCSV";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "semantic-ui-react";
import TimeStationModal from "./components/TimeStationModal";
import { useSelector } from "react-redux";
import { formatDollars } from "globalResources/helpers";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const RiggedHoursTotals = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-top: -30px;
  z-index: 999;
  position: relative;
  right: calc(-80vw + 100px);
`;

const Timesheets = () => {
  const currentUser = useSelector((state) => state.current.user);

  const treatClient = currentUser.accountType === "client";
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    total: 0,
  });
  const [sort, setSort] = useState({ sort: "end", direction: -1 });
  const [filters, setFilters] = useState({
    user: "",
    client: "",
    payGroup: null,
  });
  const [daterange, setDaterange] = useState({ start: "", end: "" });
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [search, setSearch] = useState({ client: "", user: "" });
  const [open, setOpen] = useState(false);
  const [payGroups, setPayGroups] = useState([]);

  const history = useHistory();

  const fetchTimesheets = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/timesheets`, {
        params: {
          user: filters.user,
          payGroup: filters.payGroup,
          start:
            daterange.start &&
            moment(daterange.start, "YYYY-MM-DD").startOf("day").utc(),
          end:
            daterange.end &&
            moment(daterange.end, "YYYY-MM-DD").endOf("day").utc(),
          client: filters.client,
          page: pagination.page,
          limit: pagination.limit,
          sort: sort.sort,
          direction: sort.direction,
        },
      })
      .then(async (res) => {
        setTimesheets(res.data.timesheets);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        params: {
          search: search.user,
          searchBy: "name",
          client: filters.client,
          limit: 100,
          accountType: "employee",
        },
      })
      .then(async (res) => {
        const users = res.data.users;
        var temp = [];

        users.forEach((user) => {
          temp.push({
            label: `${user.firstName} ${user.lastName}`,
            value: user._id,
          });
        });
        temp.sort((a, b) => (a.label > b.label ? 1 : -1));
        setUserSuggestions(temp);
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
      });
  };

  const fetchClients = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`)
      .then(async (res) => {
        var clients = res.data;
        var temp = [];
        clients.forEach((client) => {
          if (client.name !== "All")
            temp.push({ label: client.name, value: client._id });
        });
        setClientSuggestions(temp);
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
      });
  };

  const fetchPayGroupSuggestions = async () => {
    try {
      let res = await axios
        .get(`${process.env.REACT_APP_API_URL}/client/payGroupSuggestions`)
        .then(async (res) => {
          setPayGroups(res.data.map((pg) => ({ label: pg, value: pg })));
        });
    } catch (err) {
      const { message } = await errorHandler(err);
    }
  };

  const formatForCSV = () => {
    let formatted = [];
    timesheets.map((row) => {
      formatted.push({
        ...(!treatClient && {
          payGroup: row.client.payGroup || "",
          client: row.client ? row.client.name : "",
          employeeType: row.user.employeeType,
          externalId: row.user.externalId,
        }),
        employee: row.user?.firstName + " " + row.user?.lastName,
        start: moment(row.start)
          .tz(row.client ? row.client.timezone : "US/Central")
          .format("lll z"),
        end: moment(row.end)
          .tz(row.client ? row.client.timezone : "US/Central")
          .format("lll z"),
        ...(!treatClient && {
          payRate: formatDollars(row.payRate / 100),
          billRate: formatDollars(row.billRate / 100),
        }),
        hours: (
          moment.duration(moment(row.end).diff(moment(row.start))).asHours() -
          (row.subtractLunch && !row.user?.overrideLunch ? 0.5 : 0)
        ).toFixed(2),
        position: row.position,
        searchDaterange:
          moment(daterange.start).format("MM/DD/YYYY") +
          "-" +
          moment(daterange.end).format("MM/DD/YYYY"),
        subtractsLunch:
          row.subtractLunch && !row.user?.overrideLunch ? "yes" : "no",
      });
    });
    return formatted;
  };

  useEffect(() => {
    setPagination((old) => ({ ...old, page: 1 }));
  }, [filters]);

  useEffect(() => {
    fetchUsers();
  }, [search.user, filters.client]);

  useEffect(() => {
    fetchPayGroupSuggestions();
  }, []);

  useEffect(() => {
    fetchClients();
  }, [search.client]);

  useEffect(() => {
    fetchTimesheets();
  }, [pagination.limit, pagination.page, filters, sort, daterange]);
  return (
    <Main>
      <ButtonRow>
        <ExportCSV data={formatForCSV()} filename="timesheetsTable" />
        {currentUser.accountType !== "client" && (
          <Button
            style={{ margin: "1vh" }}
            color="blue"
            onClick={() => history.push("timesheets/rate-editor")}
          >
            Rate Editor
          </Button>
        )}
        <Button
          style={{ margin: "1vh" }}
          color="blue"
          onClick={() => setOpen(true)}
        >
          Time Station
        </Button>
        <Button
          style={{ margin: "1vh" }}
          color="blue"
          onClick={() => setSelected({})}
        >
          Add Time Entry
        </Button>
        <Button
          style={{ margin: "1vh" }}
          color="blue"
          onClick={() => history.push("/timesheets/batch")}
        >
          Batch Time Entry
        </Button>{" "}
        <Button
          style={{ margin: "1vh" }}
          color="blue"
          onClick={() => history.push("/timesheets/import")}
        >
          Import
        </Button>
      </ButtonRow>
      <FolderContainer title="Time Entries">
        <FilterBar>
          <SelectorSearch
            onSelect={(client) => setFilters((old) => ({ ...old, client }))}
            onSearch={(v) => setSearch((old) => ({ ...old, client: v }))}
            label="Client"
            options={clientSuggestions}
            width="20%"
            value={filters.client}
          />
          <SelectorSearch
            onSelect={(user) => setFilters((old) => ({ ...old, user }))}
            onSearch={(v) => setSearch((old) => ({ ...old, user: v }))}
            label="Employee"
            options={userSuggestions}
            width="20%"
            value={filters.user}
          />
          {!treatClient && (
            <SelectorSearch
              onSelect={(payGroup) =>
                setFilters((old) => ({ ...old, payGroup }))
              }
              label="Client Pay Group"
              options={payGroups}
              width="10%"
              error={
                filters.client
                  ? "Client selection overrides pay group"
                  : undefined
              }
            />
          )}
          <DatePicker
            type="date"
            label="Start"
            width="20%"
            max={moment(daterange.end).format("YYYY-MM-DD")}
            onChange={(v) => {
              setDaterange((old) => ({ ...old, start: v }));
            }}
            onFirst={(v) =>
              !daterange.end &&
              setDaterange((old) => ({
                ...old,
                end: moment(v).add(6, "days").format("YYYY-MM-DD"),
              }))
            }
            value={moment(daterange.start).format("YYYY-MM-DD")}
          />{" "}
          <DatePicker
            type="date"
            label="End"
            width="20%"
            min={moment(daterange.start).format("YYYY-MM-DD")}
            onChange={(v) => setDaterange((old) => ({ ...old, end: v }))}
            value={moment(daterange.end).format("YYYY-MM-DD")}
          />
        </FilterBar>
        <Table
          data={timesheets}
          loading={loading}
          headers={timesheetHeaders}
          pagination={pagination}
          onClick={(row) => setSelected(row || {})}
          onPage={(page) => setPagination((old) => ({ ...old, page }))}
          onLimit={(limit) => {
            setPagination((old) => ({ ...old, limit }));
          }}
          sortBy={sort.sort}
          sortDirection={sort.direction}
          onSort={(by, direction) => setSort({ sort: by, direction })}
        />
        <RiggedHoursTotals>
          Total Hours:{" "}
          {timesheets.reduce(
            (partial, time) =>
              partial +
              (moment
                .duration(moment(time.end).diff(moment(time.start)))
                .asHours()
                .toFixed(2) -
                (time.subtractLunch && !time.user?.overrideLunch ? 0.5 : 0)),
            0
          )}
        </RiggedHoursTotals>
      </FolderContainer>{" "}
      <Time
        selected={selected}
        onClose={() => {
          setSelected(null);
          fetchTimesheets();
        }}
      />
      <TimeStationModal open={open} onClose={() => setOpen(false)} />
    </Main>
  );
};
export default Timesheets;
