import { getTheme } from "Theme";
import axios from "axios";
import { BackButton, FolderContainer, Row, Table } from "globalComponents";
import ExportCSV from "globalComponents/ExportCSV";
import { notifTags } from "globalComponents/Notification";
import { errorHandler, roundTwoDecimal, sortUsers } from "globalResources/util";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icon, Loader, Button } from "semantic-ui-react";
import { addNotification } from "store/slices/uiSlice";
import styled from "styled-components";
import { formatDollars } from "globalResources/helpers";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const headers = [
  {
    label: "Name",
    render: (row) => <div>{`${row.user.firstName} ${row.user.lastName}`}</div>,
  },
  {
    label: "Hours",
    render: (row) => <div>{roundTwoDecimal(row.totals.hours)}</div>,
  },
  {
    label: "Overtime",
    render: (row) => <div>{roundTwoDecimal(row.totals.overtime)}</div>,
  },
  {
    label: "Adjustments",
    render: (row) => (
      <div>${roundTwoDecimal(row.totals.adjustments / 100)}</div>
    ),
  },
  {
    label: "Pay",
    render: (row) => (
      <div>
        ${roundTwoDecimal((row.totals.pay + row.totals.adjustments || 0) / 100)}
      </div>
    ),
  },
];

const ViewPayrollReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    total: 0,
  });
  const [report, setReport] = useState(null);

  const fetchPayroll = async () => {
    try {
      let res = await axios.get(`/accounting/payroll/report/${id}`);
      setReport(res.data.sort((a, b) => sortUsers(a, b)));
    } catch (err) {
      dispatch(addNotification("Unable to load report"));
    }
  };

  const format4CSV = () => {
    return report.lines.map((line) => ({
      externalId: line.user.externalId,
      employeeName: `${line.user.firstName} ${line.user.lastName}`,
      state: line.user.state,
      employeeType: line.user.employeeType,
      regularHours: roundTwoDecimal(line.totals.hours),
      overtimeHours: roundTwoDecimal(line.totals.overtime),
      adjustments: formatDollars(line.totals.adjustments / 100),
      grossPay: roundTwoDecimal(
        ((line.totals.adjustments || 0) + line.totals.pay) / 100
      ),
    }));
  };

  const deleteReport = async () => {
    try {
      await axios.delete(`/accounting/payroll/report/${id}`);
      history.push("/accounting/payroll");
    } catch (err) {
      const { message } = await errorHandler(err);
      dispatch(
        addNotification({
          message: "Failed to delete report",
          type: notifTags.error,
        })
      );
    }
  };

  async function pullAdjustments() {
    setLoading(true);
    try {
      await axios.patch("/adjustments/pullPayroll", {
        payrollReportId: report._id,
      });
      dispatch(addNotification({ message: "Added in adjustments" }));
      fetchPayroll();
    } catch (err) {
      let { message } = await errorHandler(err);
      dispatch(
        addNotification({
          message: "Failed to add adjustments",
          type: notifTags.error,
        })
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchPayroll();
  }, []);

  if (!report) return <Loader active size="large" />;

  return (
    <FolderContainer
      title={`Payroll for ${moment.utc(report.start).format("ll")} - ${moment
        .utc(report.end)
        .format("ll")}`}
    >
      <Main>
        <Row style={{ justifyContent: "space-between" }}>
          <BackButton onClick={() => history.push("/accounting/payroll")}>
            Back
          </BackButton>
          <Button
            color="blue"
            onClick={pullAdjustments}
            loading={loading}
            disabled={loading}
          >
            <Icon name="list" />
            Pull In Adjustments
          </Button>
          <BackButton
            style={{ background: theme.BUTTON_COLOR_NEGATIVE }}
            onClick={deleteReport}
          >
            Delete
          </BackButton>
          <ExportCSV
            data={format4CSV()}
            filename={`payroll-${moment.utc(report.start).format("l")}-${moment
              .utc(report.end)
              .format("l")}`}
          />
        </Row>
        <Table data={report.lines} headers={headers} pagination={pagination} />
      </Main>
    </FolderContainer>
  );
};
export default ViewPayrollReport;
