import { Row } from "globalComponents";
import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Container = styled.div`
  min-height: 600px;
`;

const FolderMarkLeft = styled.div`
  border-bottom: 40px solid ${theme.MANILLA};
  border-left: 15px solid transparent;
  margin-left: 20px;
  margin-right: -2px;
`;
const FolderMarkRight = styled.div`
  border-bottom: 40px solid ${theme.MANILLA};
  border-right: 15px solid transparent;
  margin-left: -2px;
`;

const FolderLabel = styled.div`
  background: ${theme.MANILLA};
  font-size: 22px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: -2px;
  border-radius: 5px;
  min-width: 150px;
  @media (max-width: 800px) {
    width: 200px;
  }
`;

const ChildrenContainer = styled.div`
  border-radius: 20px;
  background: ${theme.MANILLA};
  min-height: 400px;
  padding: 10px;
  @media (max-width: 900px) {
    border: 0;
  }
`;

const TabRow = styled.div`
  display: flex;
  min-width: 100px;
  opacity: ${(props) => (props.selected ? 1 : 0.7)};
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

const FolderContainer = ({
  children,
  title,
  tabs = [],
  onTab,
  selectedTab,
}) => {
  return (
    <Container>
      <Row>
        {title && (
          <TabRow selected={true}>
            <FolderMarkLeft />
            <FolderLabel>{title}</FolderLabel>
            <FolderMarkRight />
          </TabRow>
        )}
        {tabs.map((tab) => (
          <TabRow selected={selectedTab === tab} onClick={() => onTab(tab)}>
            <FolderMarkLeft />
            <FolderLabel>{tab}</FolderLabel>
            <FolderMarkRight />
          </TabRow>
        ))}
      </Row>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};
export default FolderContainer;
