import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-less/semantic.less";
import store from "./store";
import { Provider } from "react-redux";
import ErrorBoundary from "globalComponents/ErrorBoundry";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);

const dev = process.env.NODE_ENV === "development";

const RenderError = () => {
  if (dev) return <App />;
  else
    return (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    );
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {RenderError()}
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
