import React, { useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
import { Row, Time, Table, Button, FormRow } from "../../globalComponents";
import CSVUploader from "globalComponents/CSVUploader";
import { useDispatch } from "react-redux";
import { addNotification } from "store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";
import { errorHandler, formatPhoneNumber } from "globalResources/util";
import axios from "axios";
import UserImportEdit from "./UserImportEdit";

const theme = getTheme();
const Title = styled.h2`
  margin-right: auto;
  margin-bottom: 0px;
`;
const Subtitle = styled.p`
  font-size: 14px;
  margin: 0px;
  margin-bottom: 12px;
  margin-left: auto;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 90vh;
`;

const Submit = styled(Button)`
  width: 50%;
  margin: auto auto 0 auto;
`;

const headers = [
  { label: "firstName", render: (row) => <div>{row?.firstName}</div> },
  { label: "lastName", render: (row) => <div>{row?.lastName}</div> },
  { label: "email", render: (row) => <div>{row?.email}</div> },
  {
    label: "phone",
    render: (row) => <div>{row?.phone && formatPhoneNumber(row.phone)}</div>,
  },
  { label: "language", render: (row) => <div>{row?.language}</div> },
  { label: "accountType", render: (row) => <div>{row?.accountType}</div> },
  {
    label: "employeeType",
    render: (row) => <div>{row?.employmentType}</div>,
  },
  { label: "pin", render: (row) => <div>{row?.pin}</div> },
];
const defaultState = { headers, rows: [] };

const UserImport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [importedUsers, setImportedUsers] = useState(defaultState);
  const [selected, setSelected] = useState(null);

  const onParse = (csv) => {
    const [head, ...rows] = csv;
    const filteredRows = rows.filter((row) => row.length === head.length);
    const formattedRows = filteredRows.map((row) =>
      row.reduce((acc, cell, idx) => ({ ...acc, [head[idx]]: cell }), {})
    );

    setImportedUsers({ ...importedUsers, rows: formattedRows });
  };
  const onImport = async () => {
    try {
      const { data } = await axios.post("/users/import", {
        users: importedUsers.rows,
      });
      const isErrors = data.errors.length > 0;
      if (isErrors) {
        const headersWithoutError = importedUsers.headers.filter(
          (header) => header.label !== "Error"
        );
        setImportedUsers({
          headers: [
            ...headersWithoutError,
            { label: "Error", render: (row) => <div>{row.error}</div> },
          ],
          rows: data.errors.map((err) => ({ ...err.user, error: err.msg })),
        });
      } else {
        setImportedUsers(defaultState);
      }
      const msg =
        (data.newUsers.length > 0
          ? `Successfully imported ${data.newUsers.length} users!`
          : "") +
        (isErrors
          ? ` ${data.errors.length} user${
              data.errors.length > 1 ? "s" : ""
            } had errors, please correct them.`
          : "");
      dispatch(
        addNotification({
          type: isErrors ? notifTags.info : notifTags.success,
          message: msg,
          time: 6000,
        })
      );
    } catch (err) {
      const { message } = errorHandler(err);
      dispatch(addNotification({ type: notifTags.error, message }));
    }
  };
  const onEditSubmit = (editedUser, editedUserIdx) => {
    const newUserList = importedUsers.rows.map((u, idx) => {
      if (editedUserIdx === idx) {
        return editedUser;
      }
      return u;
    });
    setImportedUsers({ ...importedUsers, rows: newUserList });
    setSelected(null);
  };
  return (
    <Main>
      <FormRow style={{ alignItems: "flex-end", justifyContent: "right" }}>
        <Title>Import Users</Title>
        {importedUsers.rows.length > 0 && (
          <Button
            width="100px"
            color={theme.BUTTON_COLOR_NEGATIVE}
            onClick={() => {
              setImportedUsers(defaultState);
            }}
          >
            Clear
          </Button>
        )}
        <CSVUploader label="Upload Your CSV" onParse={onParse} />
      </FormRow>
      <Subtitle>
        Required columns: <strong>firstName</strong>, <strong> lastName</strong>
        ,<strong> email</strong> OR <strong> phone</strong>
      </Subtitle>
      <Subtitle>
        Optional columns: <strong>language</strong>,{" "}
        <strong>accountType</strong>, <strong>employeeType</strong>,{" "}
        <strong>pin</strong>
      </Subtitle>
      <Table
        data={importedUsers.rows}
        loading={loading}
        headers={importedUsers.headers}
        onClick={(row, index) => setSelected({ user: row, index } || {})}
      />
      {importedUsers.rows.length > 0 && (
        <Submit onClick={onImport}>Save Entries</Submit>
      )}
      {selected && (
        <UserImportEdit
          selected={selected}
          onSubmit={onEditSubmit}
          onCancel={() => setSelected(null)}
        />
      )}
    </Main>
  );
};

export default UserImport;
