import React, { useEffect, useState } from "react";
import { Button, Icon, Header, Modal, Popup, Loader } from "semantic-ui-react";
import {
  SelectorSearch,
  Selector,
  Table,
  DatePicker,
  Input,
  AccountingFolderContainer,
} from "../../../globalComponents";
import axios from "axios";
import cookie from "js-cookie";
import Report from "../forms/Report";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { getTheme } from "../../../Theme.js";
import { errorHandler } from "globalResources/util";
import styled from "styled-components";
import CheckModal from "./CheckModal";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../store/slices/uiSlice";
import { notifTags } from "globalComponents/Notification";
import { useLocation } from "react-router-dom";
import { formatDollars } from "globalResources/helpers";
import ExportCSV from "globalComponents/ExportCSV";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 10px 10px 10px;
  gap: 10px;
`;

const InvoiceSearch = styled(Input)`
  width: 100px;
`;

const Action = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 900;
  color: ${theme.MAIN_COLOR};
  cursor: pointer;
  :hover {
    opacity: 0;
  }
`;

const ReportList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const cachedFilters = location?.state?.cachedFilters
    ? location?.state?.cachedFilters
    : null;
  const user = useSelector((state) => state.current.user);
  const treatClient = user.accountType === "client";
  const [loading, setLoading] = useState(false);
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [filters, setFilters] = useState(
    cachedFilters
      ? { ...cachedFilters.filters }
      : {
          client: "",
          status: "all",
          number: null,
        }
  );
  const [daterange, setDaterange] = useState(
    cachedFilters ? cachedFilters.daterange : { start: "", end: "" }
  );
  const [searchByEnd, setSearchByEnd] = useState(false);
  const [pagination, setPagination] = useState(
    cachedFilters
      ? cachedFilters.pagination
      : {
          limit: 20,
          page: 1,
          total: 0,
        }
  );
  const [sort, setSort] = useState(
    cachedFilters ? cachedFilters.sort : { sort: "end", direction: -1 }
  );
  const [selected, setSelected] = useState(null);
  const [reports, setReports] = useState([]);
  const [error, setError] = useState("");
  const [addCheck, setAddCheck] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const onView = (row) => {
    history.push({
      pathname: `/accounting/report/${row._id}`,
      state: { cachedFilters: { sort, pagination, filters, daterange } },
    });
  };

  const downloadReport = (report, view) => {
    console.log("report: ", report);
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/invoice`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
        },
        params: { id: report._id, view },
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `${report.client?.name}-${
            moment.tz(report.start, report.client?.timezone).format("L") +
            " - " +
            moment.tz(report.end, report.client?.timezone).format("L")
          }-invoice#${report.number}.pdf`
        ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => setDownloading(false));
  };

  const headers = [
    {
      label: "Invoice #",
      width: "30%",
      render: (row) => <div>{row.number}</div>,
    },
    {
      label: "Invoiced At",
      width: "20%",
      render: (row) => (
        <div>
          {row?.invoicedAt
            ? moment.tz(row.invoicedAt, row.client.timezone).format("ll")
            : moment.tz(row.end, row.client.timezone).format("ll")}
        </div>
      ),
    },
    {
      label: "Paid At",
      width: "20%",
      render: (row) => (
        <div>
          {row.check?.timestamp
            ? moment.tz(row.check.timestamp, row.client.timezone).format("ll")
            : "Not Paid"}
        </div>
      ),
    },
    {
      label: "Total",
      width: "20%",
      render: (row) => (
        <div>
          $
          {formatDollars(
            ((row.totals?.total || 0) + row.totals?.adjustments || 0) / 100
          )}
        </div>
      ),
    },
    {
      label: "Profit",
      width: "20%",
      render: (row) => (
        <div style={{ color: row.totals?.profit > 0 ? "green" : "red" }}>
          $
          {formatDollars(
            ((row.totals?.profit || 0) + row.totals?.adjustments) / 100
          )}
        </div>
      ),
    },
    {
      label: "Client",
      render: (row) => <div>{row.client?.name}</div>,
    },
    {
      width: "70%",
      label: "Week",
      sortName: "start",
      render: (row) => (
        <div>
          {row.start && moment.tz(row.start, row.client.timezone).format("ll")}{" "}
          - {row.end && moment.tz(row.end, row.client.timezone).format("ll")}
        </div>
      ),
    },
    {
      width: "10%",
      label: (
        <Popup
          position="top center"
          content={
            <div>
              <Icon name="money" color="green" /> = Paid <br />
              <Icon name="file" color="green" /> = Billed <br />
              <Icon name="delete" color="blue" /> = Errored <br />
              <Icon name="minus" color="grey" /> = Generated <br />
              <Icon color="white" name="newspaper" /> = In-Review
            </div>
          }
          trigger={<div>Status</div>}
        />
      ),
      render: (row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {row.status === "paid" ? (
            <Icon color="green" name="money" />
          ) : row.status === "errored" ? (
            <Icon color="blue" name="delete" />
          ) : row.status === "billed" ? (
            <Icon color="green" name="file" />
          ) : row.status === "generated" ? (
            <Icon color="grey" name="minus" />
          ) : (
            <Icon color="white" name="newspaper" />
          )}
        </div>
      ),
    },
    {
      width: "80%",
      label: "",
      render: (row) => (
        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          {["billed", "paid"].includes(row.status) ? (
            <Popup
              position="top right"
              content="Download report"
              loading={downloading}
              trigger={
                <Button
                  color="blue"
                  icon
                  type="button"
                  onClick={() => downloadReport(row)}
                  content={<Icon name="file" size="large" color="white" />}
                />
              }
            />
          ) : null}
          {row.status === "errored" || row.status === "generated" ? (
            <Button
              compact
              size="large"
              color="blue"
              type="button"
              style={{ margin: "auto" }}
              onClick={() => sendReview(row._id)}
              content="Send For Review"
            />
          ) : row.status === "billed" && !treatClient ? (
            <Button
              compact
              size="large"
              color="blue"
              type="button"
              style={{ margin: "auto" }}
              onClick={() => setPaid(row._id)}
              content="Set Paid"
            />
          ) : (
            row.status === "paid" &&
            !treatClient && (
              <Button
                compact
                size="large"
                color="blue"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setAddCheck(row)}
                content={
                  row.check.number || row.check.url ? "Edit Check" : "Add Check"
                }
              />
            )
          )}
          <Popup
            position="top right"
            content="View Report"
            trigger={
              <Button
                icon
                type="button"
                style={{ marginRight: "5px", marginLeft: "auto" }}
                onClick={() => onView(row)}
                content={<Icon name="eye" color="blue" size="large" />}
              />
            }
          />
          <Popup
            position="top right"
            content="Delete a report"
            trigger={
              <Button
                color="red"
                icon
                type="button"
                onClick={() => deleteReport(row._id)}
                content={<Icon name="delete" size="large" color="white" />}
              />
            }
          />
        </div>
      ),
    },
  ];

  const fetchReports = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/reports`, {
        params: {
          page: pagination.page,
          limit: pagination.limit,
          client: filters.client,
          number: filters.number,
          status: filters.status !== "all" ? filters.status : null,
          start: daterange.start,
          end: daterange.end,
          sort: sort.sort,
          direction: sort.direction,
          searchByEnd: searchByEnd,
        },
      })
      .then(async (res) => {
        setReports(res.data.reports);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReports();
  }, [searchByEnd]);

  function sendReview(id) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/report/send`, {
        id: id,
      })
      .then(async (res) => {
        fetchReports();
        dispatch(
          addNotification({
            type: notifTags.success,
            message: "Report Sent for Review",
          })
        );
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
        dispatch(
          addNotification({
            type: notifTags.error,
            message,
          })
        );
      })
      .finally(() => setLoading(false));
  }
  function deleteReport(id) {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/report/remove`, {
        params: {
          id: id,
        },
      })
      .then(async (res) => {
        fetchReports();
        dispatch(addNotification({ message: "Successfully deleted report" }));
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  }
  function fetchClients() {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/suggestions`, {
        params: {
          id: cookie.get("id"),
        },
      })
      .then(async (res) => {
        var clients = res.data;
        var temp = [];
        clients.forEach((client) => {
          temp.push({ label: client.name, value: client._id });
        });
        setClientSuggestions(temp);
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  }
  const setPaid = (id) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/report/paid`, {
        id: id,
      })
      .then(async (res) => {
        fetchReports();
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const format4CSV = () => {
    return reports.map((report) => ({
      invoiceNumber: report.number,
      invoicedAt: report?.invoicedAt
        ? moment.tz(report.invoicedAt, report.client.timezone).format("ll")
        : moment.tz(report.end, report.client.timezone).format("ll"),
      paidAt: report.check?.timestamp
        ? moment.tz(report.check.timestamp, report.client.timezone).format("ll")
        : "Not Paid",

      ...(treatClient
        ? {}
        : {
            total: formatDollars(
              ((report.totals?.total || 0) + report.totals?.adjustments) / 100
            ),
            profit: formatDollars(
              ((report.totals?.profit || 0) + report.totals?.adjustments) / 100
            ),
          }),
      client: report.client.name,
      week: `${
        report.start &&
        moment.tz(report.start, report.client.timezone).format("ll")
      }-${
        report.end && moment.tz(report.end, report.client.timezone).format("ll")
      }`,
      status: report.status,
    }));
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [filters, sort, pagination.page, pagination.limit, daterange]);

  return (
    <AccountingFolderContainer>
      <Main>
        {" "}
        <div>
          <ExportCSV
            data={format4CSV()}
            filename={`reports-${pagination.limit}`}
          />
        </div>
        <Button
          style={{
            marginTop: "auto",
            position: "absolute",
            top: "70px",
            right: "20px",
          }}
          icon
          color="blue"
          onClick={() => setSelected({ create: true })}
        >
          <Icon name="add" color="white" />
          Create Report
        </Button>
        <FilterRow style={{ alignItems: "flex-end" }}>
          <SelectorSearch
            width="500px"
            label="Client"
            onSelect={(v) => setFilters((old) => ({ ...old, client: v }))}
            value={filters.client}
            options={clientSuggestions}
          />
          <Selector
            width="200px"
            label="Status"
            onSelect={(v) => setFilters((old) => ({ ...old, status: v }))}
            value={filters.status}
            options={[
              { label: "All", value: "all" },
              { label: "Generated", value: "generated" },
              { label: "In-Review", value: "in-review" },
              { label: "Errored", value: "errored" },
              { label: "Billed", value: "billed" },
              { label: "Paid", value: "paid" },
            ]}
          />
          <InvoiceSearch
            label="Invoice Number"
            value={filters.number}
            type="number"
            onChange={(v) =>
              setFilters((old) => ({ ...old, number: v.target.value }))
            }
            min="1"
          />{" "}
          <Action
            onClick={() => setDaterange({ start: null, end: null })}
            style={{ color: theme.BUTTON_COLOR_NEGATIVE }}
          >
            Clear
          </Action>
          <DatePicker
            type="date"
            width="30%"
            label={searchByEnd ? "Date Ends Between" : "Date Starts Between"}
            max={moment(daterange.end).subtract(1, "day").format("YYYY-MM-DD")}
            onChange={(v) => {
              setDaterange((old) => ({ ...old, start: v }));
            }}
            value={moment(daterange.start).format("YYYY-MM-DD")}
          />
          {""}
          <DatePicker
            type="date"
            width="30%"
            label={
              <Action onClick={() => setSearchByEnd(!searchByEnd)}>
                {searchByEnd ? "Use Start Date" : " Use End Date"}
              </Action>
            }
            min={moment(daterange.start).add(1, "day").format("YYYY-MM-DD")}
            onChange={(v) => setDaterange((old) => ({ ...old, end: v }))}
            value={moment(daterange.end).format("YYYY-MM-DD")}
          />
          <Button
            style={{ marginTop: "auto" }}
            icon
            color="blue"
            compact
            circular
            onClick={fetchReports}
          >
            <Icon name="redo" color="white" />
          </Button>
        </FilterRow>
        <Table
          loading={loading}
          data={reports}
          pagination={pagination}
          headers={
            treatClient
              ? headers.filter((header) =>
                  header.label === "Profit" || header.label === "Total"
                    ? false
                    : true
                )
              : headers
          }
          sortBy={sort.sort}
          sortDirection={sort.direction}
          onPage={(page) => setPagination((old) => ({ ...old, page }))}
          onLimit={(limit) => setPagination((old) => ({ ...old, limit }))}
          onSort={(sort, direction) => setSort({ sort, direction })}
        />
        <Report
          selected={selected}
          onClose={(report) => {
            setSelected(null);
            report && onView(report);
          }}
        />
        {addCheck && (
          <CheckModal
            report={addCheck}
            setCheck={setAddCheck}
            onClose={() => {
              setReports((old) => {
                const idx = old.findIndex(
                  (report) => addCheck._id === report._id
                );
                old[idx] = addCheck;
                return old;
              });
              setAddCheck(null);
            }}
          />
        )}
      </Main>
    </AccountingFolderContainer>
  );
};

export default ReportList;
