import React from "react";
import styled from "styled-components";
import {getTheme} from "Theme";
const theme = getTheme();

const Button = styled.button`
	background-color: ${props => props.color || theme.BUTTON_COLOR};
	color: ${theme.BUTTON_TEXT};
	border: none;
	border-radius: 5px;
	padding: 8px;
	cursor: pointer;
	font-weight: 600;
	height: ${props => props.height || "40px"};
	width: ${props => props.width || "100%"};
	margin: ${props => props.margin};
	:hover {
		opacity: 0.85;
	}
`;

export default Button;
