import axios from "axios";
import { Button, FormHeader, Row } from "globalComponents";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20%;
  align-items: center;
  gap: 10px;
`;

const DownloadPage = () => {
  const [downloading, setDownloading] = useState(false);
  const [report, setReport] = useState({});
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const reportId = query.get("reportId");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/report?id=${reportId}`)
      .then((res) => {
        setReport(res.data.report);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [reportId]);
  const downloadReport = (view) => {
    setDownloading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/report/invoice`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
        },
        params: { id: reportId, view },
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `${report.client?.name}-${
            moment.tz(report.start, report.client?.timezone).format("L") +
            " - " +
            moment.tz(report.end, report.client?.timezone).format("L")
          }-invoice#${report.number}.pdf`
        ); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => setDownloading(false));
  };

  return (
    <Container>
      {report.client && (
        <FormHeader style={{ textAlign: "center", fontSize: 20 }}>
          Report for {report.client?.name}
          <br /> Week
          {moment.tz(report.start, report.client?.timezone).format(" L")}
          {moment.tz(report.end, report.client?.timezone).format(" - L")}
        </FormHeader>
      )}
      <Row style={{ gap: "10px" }}>
        <Button disabled={downloading} onClick={() => downloadReport()}>
          Download Invoice
        </Button>
        <Button
          disabled={downloading}
          onClick={() => downloadReport("compact")}
        >
          Download Compact Invoice
        </Button>
      </Row>
      {downloading && <Loader active size="large" centered />}
    </Container>
  );
};

export default DownloadPage;
