import React, { useEffect, useState } from "react";
import { Button, Grid, Header, Popup } from "semantic-ui-react";
import moment from "moment-timezone";
import axios from "axios";
import cookie from "js-cookie";
import styled, { keyframes } from "styled-components";
import { useWindowDimensions } from "hooks";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import ClockinModal from "globalComponents/ClockinModal";
import { getTheme } from "Theme";
import ClockoutReason from "./ClockoutReason";
const theme = getTheme();
const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  flex-wrap: wrap;
  padding-top: 25px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: -15px auto 10px auto;
  width: ${(props) => (props.mobile ? "95vw" : "90%")};
  height: ${(props) => (props.mobile ? "auto" : "300px")};
  background-image: linear-gradient(
    ${theme.SECONDARY_COLOR} 80%,
    transparent 102%
  );
  overflow: hidden;
  padding: 4px 4px 8px 4px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px;
  margin: 1px 0 2px 0;
  align-items: center;
  font-size: 12px;
  background-color: white;
  font-weight: 800;
`;

const Name = styled.div`
  text-align: left;
  width: 30%;
  color: ${theme.BLACK};
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  text-align: center;
  justify-content: space-between;
  gap: 10px;
`;

const Date = styled.div`
  color: ${theme.BLACK};
`;

const Hours = styled.div`
  color: ${(props) => (props.over ? theme.RED : theme.BLACK)};
`;

const Client = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
  color: white;
  padding: 8px;
  border-bottom: 2px solid ${theme.BLACK};
  background: ${theme.MAIN_COLOR};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  min-height: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const Timezone = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin: auto 0 -2px 10px;
`;

const shine = keyframes`
to {
  background-position-y: 200%;
}
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 10px auto 10px auto;
  width: 90%;
  height: 300px;
  background: linear-gradient(
    ${theme.SECONDARY_COLOR} 13%,
    transparent 40%,
    ${theme.SECONDARY_COLOR} 90%
  );
  background-size: 100% 200%;
  animation: 1.5s ${shine} linear infinite;
  @media (max-width: 800px) {
    width: 90vw;
  }
`;

const ClockedInUsers = () => {
  const [users, setUsers] = useState(null);
  const [message, setMessage] = useState({});
  const [end, setEnd] = useState(null);

  const fetchClockedIn = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/clockedIn`)
      .then(async (res) => {
        if (!res.data.users || res.data.users.length < 1) setUsers([]);
        else {
          let key = {};
          res.data.users.map((user) => {
            if (user.currentTimer) {
              let tempClient = user.currentTimer.client;
              if (tempClient) {
                if (key[tempClient._id]) key[tempClient._id].push(user);
                else {
                  key[tempClient._id] = [user];
                }
              }
            }
          });
          setUsers(key);
          setTimeout(() => fetchClockedIn(), 60000);
        }
      });
  };

  const endTimer = (reason, user) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/endTimer`, {
        start: moment(user.currentTimer.start).utc(),
        end: moment().utc(),
        id: user._id,
        clientId: user.currentTimer.client._id,
        notes: reason,
      })
      .then((res) => {
        fetchClockedIn();
      })
      .catch((err) => {});
  };

  const cancelTimer = (user) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/cancelTimer`, {
        id: user._id,
      })
      .then((res) => {
        fetchClockedIn();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchClockedIn();
  }, []);

  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  if (!users)
    return (
      <Grid.Column
        style={{ minHeight: "50vh", width: "100%", textAlign: "center" }}
      >
        <Container mobile={isMobile}>
          <Loader>
            <Client />
          </Loader>
          <Loader>
            <Client />
          </Loader>
          <Loader>
            <Client />
          </Loader>
          <Loader>
            <Client />
          </Loader>
        </Container>
      </Grid.Column>
    );

  if (users.length === 0) return <ClockinModal onClose={fetchClockedIn} />;

  return (
    <Grid.Column
      style={{ minHeight: "50vh", width: "100%", textAlign: "center" }}
    >
      <ClockinModal onClose={fetchClockedIn} />

      <Container mobile={isMobile}>
        <Header
          as="h1"
          style={{
            textAlign: "center",
            color: message.type === "error" ? theme.RED : "white",
          }}
        >
          {message.text}
        </Header>
        {Object.keys(users).map((group) => (
          <List mobile={isMobile}>
            <Client>
              {users[group][0].currentTimer.client.name}
              <Timezone>
                {users[group][0].currentTimer.client.timezone}
              </Timezone>
            </Client>
            <SimpleBar style={{ maxHeight: 260 }}>
              {users[group].map((user, index) => (
                <Row
                  last={index + 1 === group.length}
                  mobile={isMobile}
                  key={`index${user.firstName}`}
                >
                  <Name>
                    {user.firstName + " " + user.lastName} (
                    {user.currentTimer.position})
                  </Name>
                  <TimeContainer>
                    <Date>
                      {moment(user.currentTimer.start)
                        .tz(user.currentTimer.client.timezone)
                        .format("hh:mm a")}
                    </Date>
                    <Hours
                      over={
                        moment
                          .duration(
                            moment
                              .tz(user.currentTimer.client.timezone)
                              .diff(
                                moment.tz(
                                  user.currentTimer.start,
                                  user.currentTimer.client.timezone
                                )
                              )
                          )
                          .asHours()
                          .toFixed(2) > 10
                      }
                    >
                      {moment
                        .duration(
                          moment
                            .tz(user.currentTimer.client.timezone)
                            .diff(
                              moment.tz(
                                user.currentTimer.start,
                                user.currentTimer.client.timezone
                              )
                            )
                        )
                        .asHours()
                        .toFixed(2)}
                      hrs
                    </Hours>
                  </TimeContainer>
                  <Popup
                    content="Cancel the user's timer"
                    trigger={
                      <Button
                        onClick={() => cancelTimer(user)}
                        color="grey"
                        style={{ marginLeft: "auto" }}
                      >
                        Cancel
                      </Button>
                    }
                  />
                  <Popup
                    content="Clock this user out"
                    trigger={
                      <Button onClick={() => setEnd(user)} color="red">
                        Out
                      </Button>
                    }
                  />
                </Row>
              ))}
            </SimpleBar>
          </List>
        ))}
      </Container>
      {end && (
        <ClockoutReason
          user={end}
          onClose={() => setEnd(null)}
          onOut={(reason) => {
            endTimer(reason, end);
            setEnd(null);
          }}
        />
      )}
    </Grid.Column>
  );
};

export default ClockedInUsers;
