import React, { useState } from "react";
import ClockedInUsers from "./ClockedInUsers";
import UserClockins from "./UserClockins";
import axios from "axios";
import styled from "styled-components";
import { FolderContainer } from "globalComponents";
import { useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import TimeStationModal from "pages/timesheets/components/TimeStationModal";
const theme = require("Theme").getTheme();

const Main = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr;
  padding: 0px 5px;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }
`;

const StationButton = styled(Button)`
  position: absolute;
  top: 65px;
  right: 200px;
  @media (max-width: 800px) {
    top: 40px;
    right: auto;
    left: 10px;
  }
`;

const Home = () => {
  const currentUser = useSelector((state) => state.current.user);
  const showTimes = useSelector((state) =>
    state.current.permissions.includes("manageUserTimeSheets")
  );
  const [timeStation, setTimeStation] = useState(false);
  return (
    <Main
      style={{
        gridTemplateColumns:
          currentUser.accountType === "client" || !showTimes
            ? "1fr"
            : undefined,
      }}
    >
      {currentUser.accountType !== "client" && <UserClockins />}
      {currentUser.accountType === "client" || showTimes ? (
        <>
          <StationButton color="blue" onClick={() => setTimeStation(true)}>
            Time Station
          </StationButton>
          <FolderContainer title="Timecards">
            <ClockedInUsers />
          </FolderContainer>
          <TimeStationModal
            open={timeStation}
            onClose={() => setTimeStation(false)}
          />
        </>
      ) : (
        <></>
      )}
    </Main>
  );
};

export default Home;
