import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SearchBar,
  Table,
  ToggleButton,
  Button,
  Selector,
} from "globalComponents";
import { errorHandler } from "globalResources/util";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faChalkboardUser,
  faCircleCheck,
  faCircleExclamation,
  faMagnifyingGlass,
  faCirclePause,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import styled from "styled-components";
import { Popup } from "semantic-ui-react";
import { getTheme } from "Theme";
const theme = getTheme();

const BoldRow = styled.div`
  font-size: 16px;
  font-weight: 600px;
`;

export const statusTags = {
  all: "All",
  $new: "New",
  inProgress: "In Progress",
  inReview: "In Review",
  completed: "Completed",
  onHold: "On Hold",
};
const labelTags = {
  all: "All",
  internal: "Internal",
  external: "External",
  client: "Client",
};
export const getStatusIcon = (status) => {
  const { $new, inProgress, inReview, completed, onHold } = statusTags;
  const statuses = {
    [$new]: { icon: faCircleExclamation, color: "orange" },
    [inProgress]: { icon: faChalkboardUser, color: "black" },
    [inReview]: { icon: faMagnifyingGlass, color: "blue" },
    [completed]: { icon: faCircleCheck, color: "green" },
    [onHold]: { icon: faCirclePause, color: theme.WARNING },
  };

  switch (status) {
    case statusTags.all:
      return statuses;
    case statusTags.new:
      return statuses[$new];
    case statusTags.inProgress:
      return statuses[inProgress];
    case statusTags.inReview:
      return statuses[inReview];
    case statusTags.completed:
      return statuses[completed];
    case statusTags.onHold:
      return statuses[onHold];
    default:
      return statuses[$new];
  }
};
const headers = [
  {
    label: "Subject",
    render: (row) => <div>{row.subject}</div>,
    sortBy: "subject",
  },

  {
    label: (
      <Popup
        position="top center"
        content={
          <div>
            {Object.entries(getStatusIcon("All")).map(([key, val]) => (
              <>
                <FontAwesomeIcon icon={val.icon} color={val.color} /> = {key}
                <br />
              </>
            ))}
          </div>
        }
        trigger={<div>Status</div>}
      />
    ),
    width: "10%",
    centered: true,
    sortBy: "status",
    render: (row) => (
      <BoldRow>
        <FontAwesomeIcon {...getStatusIcon(row.status)} />
      </BoldRow>
    ),
  },
  {
    label: "Label",
    width: "10%",
    centered: true,
    sortBy: "label",
    render: (row) => <BoldRow>{row.label}</BoldRow>,
  },
  {
    label: "Created By",
    width: "20%",
    centered: true,
    sortBy: "createdBy",
    render: (row) => <BoldRow>{row.createdByUser}</BoldRow>,
  },

  {
    label: "Created At",
    width: "20%",
    centered: true,
    sortBy: "createdAt",
    render: (row) => (
      <BoldRow>{moment(row.createdAt).format("MM/DD/YYYY-hh:mm a")}</BoldRow>
    ),
  },
];

const FilterBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  gap: 20px;
`;
const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;

const RefreshButton = styled(Button)`
  width: 33px;
  border-radius: 180px;
  right: 5vw;
  height: 33px;
`;

const BugList = () => {
  const location = useLocation();
  const cachedFilters = location?.state?.cachedFilters
    ? location?.state?.cachedFilters
    : null;

  const [bugs, setBugs] = useState([]);
  const [error, setError] = useState();
  const [archived, setArchived] = useState(
    cachedFilters ? cachedFilters.archived : false
  );
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(
    cachedFilters ? cachedFilters.search : ""
  );
  const [pagination, setPagination] = useState(
    cachedFilters
      ? { ...cachedFilters.pagination }
      : {
          page: 1,
          limit: 50,
          total: 0,
        }
  );
  const [sort, setSort] = useState(
    cachedFilters
      ? { ...cachedFilters.sort }
      : { sort: "createdAt", direction: -1 }
  );
  const [filters, setFilters] = useState(
    cachedFilters
      ? { ...cachedFilters.filters }
      : {
          label: labelTags.all,
          status: statusTags.all,
        }
  );
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchBugs();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const history = useHistory();

  const fetchBugs = () => {
    setLoading(true);
    axios
      .get("/bug/search", {
        params: {
          search,
          page: pagination.page,
          limit: pagination.limit,
          isArchived: archived,
          ...(filters.label !== labelTags.all
            ? { label: filters.label }
            : null),
          ...(filters.status !== statusTags.all
            ? { status: filters.status }
            : null),
          ...sort,
        },
      })
      .then(async (res) => {
        setBugs(res.data.bugs);
        setPagination((old) => ({ ...old, total: res.data.total }));
      })
      .catch((err) => {
        const { message } = errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBugs();
  }, [
    sort.sort,
    sort.direction,
    pagination.page,
    pagination.limit,
    archived,
    filters,
  ]);

  return (
    <React.Fragment>
      <FilterBar>
        <SearchBar
          style={{
            width: "50%",
            margin: "auto",
          }}
          onSearch={(v) => {
            setSearch(v);
            setPagination((old) => ({ ...old, page: 1 }));
          }}
          search={search}
        />
        <FilterWrap>
          <Selector
            options={Object.values(labelTags).map((label) => ({
              value: label,
              label,
            }))}
            onSelect={(val) => setFilters((old) => ({ ...old, label: val }))}
            label={"Label"}
            style={{ width: "240px" }}
            value={filters.label}
          />
          <Selector
            options={Object.values(statusTags).map((label) => ({
              value: label,
              label,
            }))}
            onSelect={(val) => setFilters((old) => ({ ...old, status: val }))}
            label={"Status"}
            style={{ width: "240px" }}
            value={filters.status}
          />
          <ToggleButton
            label="Show Archived"
            onChange={() => setArchived(!archived)}
            value={archived}
          />
          <RefreshButton onClick={() => fetchBugs()}>
            <FontAwesomeIcon icon={faArrowsRotate} />
          </RefreshButton>
        </FilterWrap>
      </FilterBar>
      <Table
        data={bugs}
        loading={loading}
        headers={headers}
        pagination={pagination}
        onClick={(row) =>
          history.push({
            pathname: `/bug/${row._id}/details`,
            state: {
              cachedFilters: { pagination, sort, filters, search, archived },
            },
          })
        }
        onPage={(page) => setPagination((old) => ({ ...old, page }))}
        onLimit={(limit) => {
          setPagination((old) => ({ ...old, limit }));
        }}
        sortBy={sort.sort}
        sortDirection={sort.direction}
        onSort={(by, direction) => setSort({ sort: by, direction })}
      />
    </React.Fragment>
  );
};

export default BugList;
