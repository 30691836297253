import React from "react";
import styled from "styled-components";
import {getTheme} from "Theme";
import Icon from "../../../assets/default.png";
import {getFileIcon, isImage} from "globalResources/util";
import moment from "moment";
import {Modal} from "globalComponents";
import {useState} from "react";
const theme = getTheme();

const OuterBubble = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	padding: 25px;
`;

const Timestamp = styled.div`
	opacity: 0.8;
	font-size: 10px;
	margin-top: 4px;
`;

const Bubble = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 500px;
	background: white;
	border-radius: 15px;
	margin-left: ${props => props.rightAlign && "auto"};
	padding: 24px;
	text-align: center;
	color: #000;
	word-wrap: break-word;
`;

const BubbleBottom = styled.div`
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 12px solid transparent;
	border-right: 24px solid white;
	border-top: 12px solid white;
	border-bottom: 20px solid transparent;
	border-radius: 0px 5px 5px 5px;
	right: ${props => props.rightAlign && "30px"};
	bottom: -24px;
	transform: ${props => !props.rightAlign && "scaleX(-1)"};
	margin-right: ${props => props.rightAlign && "auto"};
`;

const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 3px;
	position: absolute;
	top: -20px;
	margin-left: -30px;
	gap: 10px;
`;

const FileRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 3px;
	position: absolute;
	bottom: -30px;
	gap: 10px;
	padding: 0 30px;
`;

const Text = styled.p`
	color: black;
	text-align: left;
	font-size: 18px;
`;

const Image = styled.img`
	width: 40px;
	height: 40px;
	border: solid;
	border-width: 2px;
	object-fit: scale-down;
	border-radius: 5px;
	background: ${theme.BUTTON_COLOR_GREY};
	cursor: pointer;
`;

const Files = styled.img`
	display: flex;
	width: 50px;
	height: 50px;
`;

const UserName = styled.div`
	margin: 0 auto;
	font-weight: bold;
	font-size: 16px;
`;

const BugNote = ({note, rightAlign}) => {
	const [viewImg, setViewImg] = useState(null);
	return (
		<React.Fragment>
			<OuterBubble>
				<Bubble rightAlign={rightAlign}>
					<HeaderRow>
						<Image src={Icon} />
						<UserName>
							{note.user?.firstName + " " + note.user?.lastName}
						</UserName>
						<Timestamp>{moment(note.date).format("lll")}</Timestamp>
					</HeaderRow>
					<Text>{note.note}</Text>
					<FileRow>
						{note.files.map(file =>
							isImage(file) ? (
								<Image src={file} onClick={() => setViewImg(file)} />
							) : (
								<a href={file} target="_blank" rel="noreferrer">
									<Files src={getFileIcon(file)} />
								</a>
							)
						)}
					</FileRow>
					<BubbleBottom rightAlign={rightAlign} />
				</Bubble>
			</OuterBubble>
			{viewImg && (
				<Modal onClose={() => setViewImg(null)}>
					<a href={viewImg} target="_blank" rel="noreferrer">
						<Image src={viewImg} style={{width: "100%", height: "90vh"}} />
					</a>
				</Modal>
			)}
		</React.Fragment>
	);
};

export default BugNote;
