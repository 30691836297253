import React, { Component } from "react";
import styled from "styled-components";
import { Column } from "globalComponents";
import AM from "../../assets/faqshots/accounting_menu.png";
import AN from "../../assets/faqshots/accounting_nav.png";
import Eyecon from "../../assets/faqshots/eyecon.png";
import Download from "../../assets/faqshots/accounting_download.png";
import Grouping from "../../assets/faqshots/accounting_grouping.png";
import Status from "../../assets/faqshots/accounting_status.png";
import Login from "../../assets/faqshots/login-email.png";
import LoginLink from "../../assets/faqshots/login-email_link.png";
import LoginPW from "../../assets/faqshots/password-login.png";
import LoginPwBttn from "../../assets/faqshots/password-login_use-pw.png";
import PwResetBttn from "../../assets/faqshots/login-email_reset.png";
import EmailReset from "../../assets/faqshots/reset-email.png";
import ResetBttn from "../../assets/faqshots/reset-email_request.png";
import PhoneLink from "../../assets/faqshots/phone-request_link.png";
import PhoneReq from "../../assets/faqshots/phone-request_bttn.png";
import TimeEntries from "../../assets/faqshots/time-entries.png";
import TimeEntryBttn from "../../assets/faqshots/time-entries_add-time_bttn.png";
import AddTime from "../../assets/faqshots/time-entries_add-time.png";
import AddTimeConfirm from "../../assets/faqshots/time-entries_add-time_edit.png";
import RateEditor from "../../assets/faqshots/rate-editor.png";
import RateEditing from "../../assets/faqshots/rate-editor_first-inputs.png";
import RateFinishing from "../../assets/faqshots/rate-editor_second-inputs.png";
import BatchTime from "../../assets/faqshots/batch-time.png";
import BatchInput from "../../assets/faqshots/batch-time_entries.png";
import BatchMulti from "../../assets/faqshots/batch-entries_add-time_multi.png";
import Users from "../../assets/faqshots/users.png";
import UserOptions from "../../assets/faqshots/users-options.png";
import CreateUser from "../../assets/faqshots/users-createUser.png";
import CreateUserModal from "../../assets/faqshots/users-createUsers-modal.png";
import UsersExport from "../../assets/faqshots/users-export.png";
import UsersEdit from "../../assets/faqshots/users-edit.png";
import UsersEditModal from "../../assets/faqshots/users-edit_modal.png";
import TimeStationTab from "../../assets/faqshots/timeStation-tab.png";
import TimeStationSetup from "../../assets/faqshots/timeStation-setup.png";
import TimeStationPin from "../../assets/faqshots/timeStation-pin.png";
import Import from "../../assets/faqshots/import.png";
import ImportCSV from "../../assets/faqshots/import-csv.png";
import ImportEdit from "../../assets/faqshots/import-edit-line.png";
import ImportLineEdit from "../../assets/faqshots/csv-line-edit.png";
import ImportSubmit from "../../assets/faqshots/csv-submit.png";
import { getTheme } from "Theme";

const theme = getTheme();

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding-top: 50px;
  margin: 0 10vw;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    gap: 50px;
    margin: 10px 0;
    padding: 0px;
  }
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin: 18px auto 10px auto;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;

const Text = styled.p`
  color: black;
  font-size: 16px;
  margin: 0 auto auto auto;
  @media (max-width: 1200px) {
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: bold;
  text-align: Center;
  border-bottom: 10px solid ${theme.MAIN_COLOR};
  width: 75vw;
  margin: 0 auto 20px auto;
  padding-bottom: 4px;
  @media (max-width: 1200px) {
    width: 100%;
    font-size: 28px;
    margin-bottom: -50px;
  }
`;
const Screenshot = styled.img`
  display: flex;
  object-fit: scale-down;
  width: 100%
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding: 0px 0px 0px 10px;
  gap: 10px 10px;
  align-items: center;
  @media (max-width: 1200px) {
    align-items: center;
    flex-direction: column-reverse;
    align-content: center;
    padding: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 75vw;
  justify-content: center;

  align-items: center;
  background: ${theme.SECONDARY_COLOR};
  padding: 8px 20px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    align-content: stretch;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
`;

const Sidebar = styled.div`
  flex-direction: row;
  padding: 8px 20px;
  width: 25vw;
  margin: 0 auto auto auto;
  text-align: left;
  @media (max-width: 1200px) {
    width: auto;
  }
`;

const Faq = () => {
  return (
    <Page>
      <Title>Timecard Solution Tutorial</Title>
      <Row>
        <Content>
          <Screenshot src={AM} />
          <Screenshot src={AN} />
        </Content>
        <Sidebar>
          <Header>Accounting (Reports and Invoices)</Header>
          <Text color="black">
            Reports and invoices can be viewed by going to the reports page from
            either the navigation bar or the navigation menu.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={Eyecon}
          />
        </Content>
        <Sidebar>
          <Text color="black">Click on the eye icon to view a report</Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={Download}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            To download the invoice click the "Download Invoice" button for the
            full invoice or the "Download Compact Invoice" button for a
            condensed version.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={Status}

          />
        </Content>
        <Sidebar>
          <Text color="black">
            The status of the report can be found in the top left corner of the
            Report Details page.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={Grouping}

          />
        </Content>
        <Sidebar>
          <Text color="black">
            To view a specific employees time entries within the report select
            them from the sidebar.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={Login}
          />
        </Content>
        <Sidebar>
          <Header>Updates Window</Header>
          <Text color="black">
            the most recent updates and changes can be found here! Feel free to
            email for any clarification on these.
          </Text>
          <br />

          <Text color="black">
            There are two ways to log into Time Card Solutions. The first way is
            by using the "Get Sign In Link" found on the log in screen.
          </Text>
          <br />

          <Text color="black">
            Double check to make sure that you have entered in your correct work
            provided email address.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={LoginLink}

          />
        </Content>
        <Sidebar>
          <Text color="black">
            Click on the "Get Sign In Link", that will have a one time link sent
            to the entered email, when that link is clicked it will log you in
            to your profile.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={LoginPwBttn}

          />
        </Content>
        <Sidebar>
          <Text color="black">
            The second way to log in is by inputting your email or phone number
            and then typing in your password.
          </Text>
          <Text color="black">
            To log in with your password, click on the "Use Password" button to
            input your email and password.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={LoginPW}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Input your email or phone number in the "Email or Phone" bar, and
            your password in the "Password" bar, then click the "Login" button.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={PwResetBttn}
          />
        </Content>
        <Sidebar>
          <Header>Changing Your Password (Email)</Header>
          <Text color="black">
            Click on the "Reset Your Password" button to start the process.{" "}
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={EmailReset}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Input your work provided email. Double check that you have inputted
            the correct email.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={ResetBttn}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            After you have checked that the you inputted the correct email.
            Click on the "Request Reset" button to have a reset link sent to
            your email.
          </Text>
          <br />
          <Text color="black">
            Then follow the steps in your emial to complete the password reset.
          </Text>{" "}
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={PhoneLink}
          />
        </Content>
        <Sidebar>
          <Header>Changing Your Password (Phone Number)</Header>
          <Text color="black">
            To change your password via phone number, click on the "Use Phone
            Number" link above the space where you input your email.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={PhoneReq}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Input your phone number, then click the "Request Verification Code"
            to have a one time code sent to your phone. Proceed to the next page
            and follow the instrucitons to confirm your new password.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={TimeEntries}
          />
        </Content>
        <Sidebar>
          <Header>Adding A New Time Entry</Header>
          <Text color="black">
            To start adding a new time entry you have to be in the time entries
            tab. Make sure you are in the "Time Entries" tab, or click on the
            tab to take you to the correct page.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={TimeEntryBttn}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            To add a new time entry, click on the "add time entry" button.{" "}
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={AddTime}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once in the "Edit Time Entry" menu, make sure you select the correct
            Client, Employee, Time in, and Time Out options. All options must be
            filled out.{" "}
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={AddTimeConfirm}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once all the information is inputted, the "Delete Time Entry" and
            "Add Time Entry" buttons will be clickable.
          </Text>
          <br />
          <Text color="black">
            {" "}
            You can then choose to submit the time entry or to delete the time
            entry that you are working on.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={TimeEntries} />
        </Content>
        <Sidebar>
          <Header>Rate Editor</Header>
          <Text color="black">
            Make sure that your are in the "Time Entries" tab.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={RateEditor} />
        </Content>
        <Sidebar>
          <Text color="black">
            Click on the "Rate Editor" tab on the top right section of the
            screen.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={RateEditing} />
        </Content>
        <Sidebar>
          <Text color="black">
            Once in the rate edit form, select the Employee you want to edit,
            you can optionally select the Client, then select the Start date you
            want the change to occur on, and the end date you want the change to
            occur on.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={RateFinishing} />
        </Content>
        <Sidebar>
          <Text color="black">
            After the employee and client information, and the start and end
            dates are filled out, next input the position of the person's rate
            you want to edit.
          </Text>
          <br />
          <Text color="black">
            Select the "Pay Rate" and "Bill Rate", set the "Overtime Pay Rate",
            and the "Overtime Bill Rate".
          </Text>
          <br />
          <Text color="black">
            Once you have double checked all the information on the editing form
            you can either choose to "Cancel" to cancel the edit. Or click
            "Update Rates" to submit the form.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={TimeEntries} />
        </Content>
        <Sidebar>
          <Header>Batch Time Entry</Header>
          <Text color="black">
            Make sure that your are in the "Time Entries" tab.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={BatchTime} />
        </Content>
        <Sidebar>
          <Text color="black">
            Click on the "Batch Time Entry" tab in the top right section of the
            screen to take you to the batch time entry page.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot src={BatchInput} />
        </Content>
        <Sidebar>
          <Text color="black">
            In the "Batch Time Entry" page, select the "Client" and "Employee"
            that you want to edit.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot src={BatchMulti} />
        </Content>
        <Sidebar>
          <Text color="black">
            Double check that you have entered the correct client and employee.
            Then Click the "Add Time" button at the bottom left of the screen.
            Input the "Time In" and the "Time Out".
          </Text>
          <br />
          <Text color="black">
            You can also input notes based on the reason for the time entry.
          </Text>
          <br />
          <Text color="black">
            Once you have all your information set and doubled checked, click on
            the "Submit Batch Entries" to submit the multiple time entries.
          </Text>
          <br />
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={TimeEntries}
          />
        </Content>
        <Sidebar>
          <Header>Time Station</Header>
          <Text color="black">
            To get to the "Time Station" page, make sure that you are in the
            "Time Entries" tab. Once you are in the "Time Entries" tab click on
            the "Time Station" tab button in the top right section of the
            screen.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={TimeStationTab}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once in the "Time Entries" tab, click on the "Time Station" button
            in the top right of the screen.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={TimeStationSetup}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Choose the correct client you wish to start a time station for. Once
            you have selected the correct client, click on the blue "Setup Time
            Station" button.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={TimeStationPin}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Enter in the Employee that you want to start the Time Station for.
            Then enter in your pin and click "Login".
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Text color="black">
          {" "}
          Once you have signed in click on the green "Clock-In" button to clock
          into the time station. Remember when you clock in you will be
          automatically signed out.
        </Text>
        <Text color="black">
          To sign out input your employee name and pin. Then you will see a
          prompt that shows you how long you have been clocked into the time
          station. There you can log out or clock out.
        </Text>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={Import}
          />
        </Content>
        <Sidebar>
          <Header>Uploading CSVs</Header>
          <Text color="black">
            To import a CSV file you have to be in the "Time Entries" tab. Once
            in the time entries tab click on the "Import" tab button to take you
            to the page where you can import your CSV file.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={ImportCSV}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Click on the "Upload CSV" button at the top on the screen. Once you
            have clicked that you will need to pick the file you want to input
            from your computer/device. Remember it needs to be a .csv type file.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={ImportEdit}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once you have imported the .csv file that you want, a list of all
            the lines will populate as a list.
          </Text>
          <br />
          <Text color="black">
            Each of those rows can be edited as well. To edit the information in
            that line click on line you would like to edit and that will take
            you to the editing menu.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={ImportLineEdit}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once in the editing menu you can change the information that has
            been popluated from the import.
            </Text>
            <br />
            <Text color="black">
              Once you are done editing the line click on the blue "Edit Time
              Entry" button to save the changes.
            </Text>
          <br />
          <Text color="black">
            If you want to delete the entry all together click on the red
            "Delete Time Entry" button. And to cancel the edit, click off of the
            editing menu.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={ImportSubmit}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once you have reviewed all the entries and ensured all the
            information is correct and you have edited anything that was not
            correct.
            </Text>
            <br />
            <Text color="black"> Click on the gray "submit" button below the list to
            finish all your time inputs.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Header>Users</Header>
      </Row>
      <Row>
        <Content>
          <Screenshot src={Users} />
        </Content>
        <Sidebar>
          <Text color="black">Make sure that you are in the "Users" tab.</Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={UserOptions}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once you are in the "Users" tab there are options towards the top of
            the page that you can choose to search for specific users. In
            "Clients" you can choose the client user list you want to search
            through.
          </Text>
          <br />
          <Text color="black">
            The "Groups" input will let you select specific groups of users that
            have already been created. You can select the account type or search
            a specific name of a user.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={CreateUser}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            To make a new user, in the top right section of the page there is a
            "+Create User" button and that will take you to the Create User
            Page.
          </Text>
        </Sidebar>
      </Row>

      <Row>
        <Content>
          <Screenshot
            src={CreateUserModal}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            When you are in the "Create User" menu, double check that you have
            inputted all of the correct information in each section.
          </Text>
          <br />
          <Text color="black">
            Once all of the correct information has been entered you can click
            the blue Save Changes" button at the bottom to accept the new user.{" "}
          </Text>
          <br />
          <Text color="black">
            If you need to cancel the user you are creating, click the gray
            "Disable User" button to cancel the operation.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={UsersExport}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once you have the users list that you want you can then click on the
            "Export CSV" button to download a .csv file.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={UsersEdit}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            To edit an already existing user, click on the user you want from
            the list of users that has populated.
          </Text>
        </Sidebar>
      </Row>
      <Row>
        <Content>
          <Screenshot
            src={UsersEditModal}
          />
        </Content>
        <Sidebar>
          <Text color="black">
            Once you have clicked on the user that you want to edit, you will be
            in this "User Edit" menu. Here you can edit the information of the
            user.
          </Text>
          <br />
          <Text color="black">
            Once you have edited the information you need to edit, double check
            to make sure you have changed the correct information, click on the
            blue "Save Changes" button on the bottom right of the menu to save
            the changes.
          </Text>
          <br />
          <Text color="black">
            If you need to cancel your changes click off of the menu and it will
            cancel the changes and close out of the menu. If you need to disable
            the user, click on the gray "Disable User" button on the bottom left
            of the menu.
          </Text>
        </Sidebar>
      </Row>
    </Page>
  );
};

export default Faq;
