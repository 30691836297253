import React from "react";
import { Button, Form, Modal, Header, Grid, TextArea } from "semantic-ui-react";
import { Formik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Theme from "../Theme.js";
import { Confirm, DatePicker } from "./";
import cookie from "js-cookie";
import { connect } from "react-redux";
import moment from "moment-timezone";
import NotesModal from "./NotesModal.js";

const SignupSchema = Yup.object().shape({
	user: Yup.string().required("Required"),
	start: Yup.string().required("Required"),
	end: Yup.string().required("Required"),
});

class TimeClock extends React.Component {
	state = { users: [], clients: [], confirm: false, customPay: false };

	delete = () => {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		this.setState({ loading: true });
		axios
			.post(`${process.env.REACT_APP_API_URL}/timesheet/remove`, {
				id: this.props.selected._id,
			})
			.then(res => {
				this.props.onClose();
			})
			.catch(err => this.setState({ loading: false, error: "error" }));
	};

	fetchUsers = () => {
		axios.defaults.headers.common["Authorization"] = `Bearer ${cookie.get(
			"token"
		)}`;
		this.setState({ loading: true });
		axios
			.get(`${process.env.REACT_APP_API_URL}/client/team`, {
				params: {
					search: this.state.search,
					searchBy: "name",
					limit: 100,
				},
			})
			.then(async res => {
				if (res.data.users.length < 1) {
					this.setState({
						users: [],
					});
				} else {
					const users = res.data.users;
					var temp = [];
					users.forEach(user => {
						temp.push({
							text: `${user.user.firstName} ${user.user.lastName}`,
							value: user.user._id,
						});
					});
					this.setState({
						users: temp,
					});
				}
			})
			.catch(err => this.setState({ loading: false, error: "error" }));
	};

	componentDidMount() {
		this.fetchUsers();
		this.setState({ loading: true });
	}
	render() {
		const { users, clients, showMore, confirm, error, customPay } = this.state;
		const { selected = {}, onClose } = this.props;
		return (
			<Formik
				initialValues={{
					user: selected._id ? selected.user._id : "",
					start: selected._id
						? moment(selected.start).format("YYYY-MM-DDTHH:mm")
						: "",
					end: selected._id
						? moment(selected.end).format("YYYY-MM-DDTHH:mm")
						: "",
				}}
				enableReinitialize={true}
				validationSchema={SignupSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					axios
						.post(`${process.env.REACT_APP_API_URL}/timesheet`, {
							user: values.user,
							start: moment(values.start, "YYYY-MM-DDThh:mmA").utc(),
							end: moment(values.end, "YYYY-MM-DDThh:mmA ").utc(),
							client: JSON.parse(cookie.get("client") || "{}")._id,
							createdBy: cookie.get("id"),
							id: selected._id,
						})
						.then(res => {
							actions.setSubmitting(false);

							this.setState({ open: false, error: "" });
							actions.resetForm();
							this.props.onClose();
							this.setState({ customPay: null });
						})
						.catch(error => {
							actions.setFieldError("general", "Error saving timesheet");
						});
				}}
				render={props => (
					<Modal
						open={!!selected._id || !!selected.create}
						onClose={() => {
							this.setState({ error: "", customPay: null });
							if (props.dirty) this.setState({ confirm: true });
							else onClose();
						}}
						centered
						closeIcon
						style={{
							textAlign: "center",
							backgroundColor: "white",
							padding: "2vh",
							maxWidth: 600,
						}}
					>
						<Header as="h1">
							{selected.create ? "Add Time Entry" : "Edit Time Entry"}{" "}
						</Header>
						<Header
							as="h3"
							style={{
								color: selected._id && selected.flagged ? "red" : "black",
							}}
						>
							{(selected._id &&
								selected.flagged &&
								!selected.gps &&
								"Missing GPS location") ||
								(selected._id &&
									!selected.flagged &&
									!selected.gps &&
									"Added Via Time Station")}
						</Header>
						<Form onSubmit={props.handleSubmit} size="large">
							<Grid
								style={{
									borderRadius: 10,
									backgroundColor: Theme.PAGE_BACKGROUND,
									padding: "1%",
								}}
							>
								<Grid.Row>
									<Grid.Column centered>
										<Header as="h3" style={{ color: "white", margin: "auto" }}>
											User
										</Header>
										<Form.Dropdown
											error={props.touched.user && props.errors.user}
											onChange={(e, v) => {
												props.setFieldValue("user", v.value);
												this.setState({ search: "" });
											}}
											selection
											search
											placeholder={
												selected.user
													? selected.user.firstName +
													  " " +
													  selected.user.lastName
													: null
											}
											onSearchChange={v =>
												this.setState(
													{ search: v.target.value },
													this.fetchUsers
												)
											}
											value={props.values.user}
											options={users}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Header as="h3" style={{ color: "white", margin: "auto" }}>
											Time In
										</Header>
										<DatePicker
											error={props.touched.start && props.errors.start}
											max={moment
												.utc(props.values.end)
												.format("YYYY-MM-DDTHH:mm")}
											onChange={v => {
												if (!props.values.end || props.values.end < v)
													props.setFieldValue("end", v);
												props.setFieldValue("start", v);
											}}
											value={moment(props.values.start).format(
												"YYYY-MM-DDTHH:mm"
											)}
										/>
									</Grid.Column>
									<Grid.Column>
										<Header as="h3" style={{ color: "white", margin: "auto" }}>
											Time Out
										</Header>
										<DatePicker
											error={props.touched.end && props.errors.end}
											min={moment
												.utc(props.values.start)
												.local()
												.format("YYYY-MM-DDTHH:mm")}
											onChange={v => props.setFieldValue("end", v)}
											value={moment(props.values.end).format(
												"YYYY-MM-DDTHH:mm"
											)}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									{props.errors.general && (
										<div
											style={{
												color: "red",
												margin: "-1vh auto .5vh auto",
												textAlign: "center",
											}}
										>
											{props.errors.general}
										</div>
									)}
									{
										<Header
											style={{ margin: "auto" }}
											color="red"
											textAlign="center"
										>
											{error}
										</Header>
									}
								</Grid.Row>{" "}
								{selected && (
									<NotesModal
										notes={selected.notes}
										type="timesheet"
										id={selected._id}
									/>
								)}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										width: "100%",
									}}
								>
									<Button fluid disabled={props.isSubmitting} color="blue">
										{selected.create ? "Add Time Entry" : "Edit Time Entry"}
									</Button>
									<Button
										type="button"
										fluid
										disabled={props.isSubmitting}
										style={{}}
										color="red"
										onClick={this.delete}
									>
										Delete Time Entry
									</Button>
								</div>
							</Grid>
						</Form>
						<Confirm
							open={confirm}
							message={"Leave without saving changes?"}
							onConfirm={() => this.setState({ confirm: false }, onClose)}
							onDeny={() => this.setState({ confirm: false })}
						/>
					</Modal>
				)}
			/>
		);
	}
}

const mapStateToProps = state => ({ ...state.global });
export default connect(mapStateToProps, null)(TimeClock);
