import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px solid ${theme.INPUT_BORDER};
  border-radius: 20px;
  padding: 3px 5px;
  background: ${theme.INPUT_BACKGROUND};
  width: 100%;
  min-width: 300px;
`;

const SearchBy = styled.select`
  border: none;
  outline: none;
  background: none;
  color: ${theme.INPUT_TEXT};
  cursor: pointer;
`;

const Option = styled.option`
  cursor: pointer;
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: none;
  width: 100%;
  color: ${theme.INPUT_TEXT};
  font-size: 14px;
`;

const SearchBar = ({
  searchBy,
  search,
  onSearch,
  onSearchBy,
  byOptions,
  style,
}) => {
  return (
    <Main style={style}>
      {byOptions && (
        <SearchBy
          onChange={(e) => onSearchBy(e.currentTarget.value)}
          value={searchBy}
        >
          {byOptions.map((option) => (
            <Option value={option}>{option}</Option>
          ))}
        </SearchBy>
      )}
      <Input
        onChange={(e) => onSearch(e.currentTarget.value)}
        value={search}
        placeholder="Search..."
      />
    </Main>
  );
};
export default SearchBar;
