import axios from "axios";
import { Button, InfiniteTable, Row, SelectorSearch } from "globalComponents";
import { errorHandler } from "globalResources/util";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

const ManagerList = ({ managers = [], client, updateDetails }) => {
  const [manager, setManager] = useState();
  const [managerOptions, setManagerOptions] = useState([]);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();

  const update = (manager, action) => {
    if (!manager.user) return;
    let temp = client;
    if (action === "add") temp.managers.push(manager);
    else temp.managers = temp.managers.filter((e) => e.user !== manager.user);

    axios
      .patch("/client", { ...temp, id: temp._id })
      .then((res) => updateDetails(res.data))
      .catch(async (error) => {
        const { message } = await errorHandler(error);
      });
  };

  const headers = [
    {
      label: "Name",
      sortBy: "name",
      render: (row) => (
        <div>{row.user && row.user.firstName + " " + row.user.lastName}</div>
      ),
    },
    {
      label: "Email",
      render: (row) => <div>{row.user && row.user.email}</div>,
    },
    {
      label: "Phone",
      width: "200px",
      render: (row) => <div>{row.user && row.user.phone}</div>,
    },
    {
      label: "",
      width: "100px",
      render: (row) =>
        row.user && (
          <Button
            color={theme.NEGATIVE}
            onClick={() => update({ user: row.user._id }, "remove")}
          >
            X
          </Button>
        ),
    },
  ];

  const fetchUsers = () => {
    //todo infinite scroll on search
    axios
      .get("/users", {
        params: {
          limit: 50,
          page,
          search,
          searchBy: "name",
          sort: "firstName",
          direction: 1,
          accountType: "client",
        },
      })
      .then((res) => {
        const idCheck = managers.map((m) => m.user._id);
        let temp = res.data.users
          .filter((u) => !idCheck.includes(u._id))
          .map((u) => ({
            label: u.firstName + " " + u.lastName + ` (${u.uid})`,
            value: u._id,
          }));
        setManagerOptions(temp);
      })
      .catch((err) => {
        const { message } = errorHandler(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, [search]);

  return (
    <Main>
      <Row style={{ justifyContent: "space-between", padding: "10px 0" }}>
        <Title>Managers ({managers.length})</Title>

        <SelectorSearch
          placeholder="Type to search"
          onSearch={(v) => setSearch(v)}
          label="Add a Manager"
          width="300px"
          options={managerOptions}
          value={null}
          onSelect={(v) => update({ user: v }, "add")}
        />
      </Row>
      <InfiniteTable
        height="400px"
        data={managers.sort((a, b) =>
          a?.user?.firstName + a?.user?.lastName >
          b?.user?.firstName + b?.user?.lastName
            ? 1
            : -1
        )}
        headers={headers}
        onClick={(row) => setManager(row)}
      />
    </Main>
  );
};
export default ManagerList;
