import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	clients: [],
	client: {},
};

export const clientsSlice = createSlice({
	name: "clients",
	initialState,
	reducers: {
		setClients: (state, action) => {
			state.clients = action.payload;
		},
		setClient: (state, action) => {
			state.client = action.payload;
		},
		clearClients: state => {
			state = initialState;
		},
	},
});

export const { setClients, setClient, clearClients } = clientsSlice.actions;

export default clientsSlice.reducer;
