import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
};

//payload structure {type: string, message: string}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },
    removeNotification: (state) => {
      state.notifications = state.notifications.slice(1);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } =
  uiSlice.actions;

export default uiSlice.reducer;
