import { Button, FormRow, Input, Modal, Selector } from "globalComponents";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h2`
  margin-bottom: 0px;
`;

const UserImportEdit = ({ selected, onSubmit, onCancel }) => {
  const [form, setForm] = useState(selected.user);

  const currentUser = useSelector((state) => state.current.user);
  const getAccountTypeOptions = () => {
    let options = [
      { label: "Employee", value: "employee" },
      { label: "Client", value: "client" },
    ];

    if (currentUser.accountType === "super")
      return options.concat([
        { label: "Super", value: "super" },
        { label: "Admin", value: "admin" },
      ]);
    else if (currentUser.accountType === "admin")
      return options.concat([{ label: "Admin", value: "admin" }]);
    else return options;
  };
  return (
    <Modal onClose={onCancel}>
      <Wrapper>
        <Title>Edit User</Title>
        <FormRow>
          <Input
            label="First Name"
            value={form.firstName}
            onChange={(e) =>
              setForm({ ...form, firstName: e.currentTarget.value })
            }
          />
          <Input
            label="Last Name"
            value={form.lastName}
            onChange={(e) =>
              setForm({ ...form, lastName: e.currentTarget.value })
            }
          />
        </FormRow>
        <FormRow>
          <Input
            label="Email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.currentTarget.value })}
          />
          <Input
            label="Phone"
            value={form.phone}
            onChange={(e) => setForm({ ...form, phone: e.currentTarget.value })}
          />
        </FormRow>
        <FormRow>
          <Selector
            label="Language"
            options={[
              { label: "English", value: "english" },
              { label: "Spanish", value: "spanish" },
            ]}
            value={form.language}
            onSelect={(v) => setForm({ ...form, language: v })}
          />
          <Selector
            label="Account Type"
            options={getAccountTypeOptions()}
            value={form.accountType}
            onSelect={(v) => setForm({ ...form, accountType: v })}
          />
        </FormRow>
        <FormRow>
          <Selector
            label="Employment Type"
            value={form.employeeType}
            onSelect={(v) => setForm({ ...form, employeeType: v })}
            options={[
              { label: "W2", value: "W2" },
              { label: "1099", value: "1099" },
            ]}
          />
          <Input
            label="Timestation Pin Number"
            type="number"
            value={form.pin}
            onChange={(e) => setForm({ ...form, pin: e.currentTarget.value })}
          />
        </FormRow>
        <Button
          onClick={() => onSubmit(form, selected.index)}
          style={{ marginTop: "12px" }}
        >
          Save Changes
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default UserImportEdit;
