import axios from "axios";
import { errorHandler } from "globalResources/util";

export async function getClientOptions(search) {
  let res = await axios.get(
    `${process.env.REACT_APP_API_URL}/client/suggestions`,
    {
      params: { search },
    }
  );

  var temp = [];
  res.data.forEach((client) => {
    if (client.name !== "All")
      temp.push({
        label: client.name,
        value: client._id,
        timezone: client.timezone,
      });
  });
  return temp;
}

export async function getClientEmployeeOptions(clientId) {
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/client/team`, {
      params: {
        id: clientId,
        employees: true,
      },
    });

    var temp = [];
    res.data.users.forEach((emp) => {
      let user = emp.user;
      temp.push({
        label: `${user.firstName} ${user.lastName} (${emp.position})`,
        value: emp,
      });
    });
    temp.sort((a, b) => (a.label > b.label ? 1 : -1));
    return temp;
  } catch (e) {
    return [];
  }
}
