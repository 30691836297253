import axios from "axios";
import {
	Button,
	FormRow,
	Input,
	Modal,
	Selector,
	SelectorSearch,
} from "globalComponents";
import { errorHandler } from "globalResources/util";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
const theme = getTheme();

const Main = styled.div`
	display: flex;
	flex-direction: column;
`;

const initialForm = {
	user: "",
	position: "employee",
	previousPosition: "employee",
	payRate: 0,
	billRate: 0,
	overtimePayRate: 0,
	overtimeBillRate: 0,
};

const initialErrors = {
	...initialForm,
	position: "",
	payRate: "",
	billRate: "",
	overtimePayRate: "",
	overtimeBillRate: "",
};
const EmployeeModal = ({ employee, employees, onClose, onSubmit }) => {
	const [form, setForm] = useState(initialForm);
	const [users, setUsers] = useState([]);
	const [errors, setErrors] = useState(initialErrors);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({
		limit: 50,
		page: 1,
		total: 0,
	});

	const fetchUsers = () => {
		axios
			.get("/users", {
				params: {
					...pagination,
					search,
					searchBy: "name",
					sort: "firstName",
					direction: 1,
					accountType: ["employee", "admin", "super"],
				},
			})
			.then(res => {
				let temp = res.data.users.map(u => ({
					label:
						u.firstName + " " + u.lastName + ` (${u.accountType} ${u.UID})`,
					value: u._id,
				}));
				setUsers(temp);
				setPagination(old => ({ ...old, total: res.data.total }));
			})
			.catch(async err => {
				const { message } = await errorHandler(err);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (employee && employee.user) {
			setForm({
				...employee,
				previousPosition: employee.position,
				user: employee.user ? employee.user._id : "",

				payRate: (parseFloat(employee.payRate) / 100).toFixed(2),
				billRate: (parseFloat(employee.billRate) / 100).toFixed(2),
				overtimePayRate: (parseFloat(employee.overtimePayRate) / 100).toFixed(
					2
				),
				overtimeBillRate: (parseFloat(employee.overtimeBillRate) / 100).toFixed(
					2
				),
			});
			setSearch(employee.user.firstName + " " + employee.user.lastName);
		}
	}, [employee]);

	useEffect(() => {
		fetchUsers();
	}, [search]);

	const updateForm = (value, name) => {
		setForm(old => ({ ...old, [name]: value }));
	};

	const checkSubmit = () => {
		let positions = employees.map(e =>
			e.user._id === form.user ? e.position : null
		);
		if (positions.includes(form.position))
			setErrors(old => ({
				...old,
				position: "This user already added with this position",
			}));
		if (!form.user)
			setErrors(old => ({
				...old,
				user: "Required",
			}));
		else {
			setErrors(old => ({
				...old,
				position: "",
				user: "",
			}));
			onSubmit(
				{
					...form,
					payRate: form.payRate * 100,
					billRate: form.billRate * 100,
					overtimeBillRate: form.overtimeBillRate * 100,
					overtimePayRate: form.overtimePayRate * 100,
				},
				employee.user ? "update" : "add"
			);
		}
	};

	if (!employee) return null;

	return (
		<Modal onClose={onClose}>
			<Main>
				<FormRow>
					<SelectorSearch
						placeholder="Select a user"
						onSearch={v => setSearch(v)}
						label="Employee"
						options={users}
						value={form.user}
						onSelect={v => updateForm(v, "user")}
						error={errors.user}
					/>
					<Input
						label="Position"
						value={form.position}
						onChange={e => updateForm(e.target.value, "position")}
						error={errors.position}
					/>
				</FormRow>
				<FormRow>
					<Input
						type="number"
						label="Bill Rate"
						value={form.billRate}
						onBlur={() => {
							updateForm(
								(parseFloat(form.billRate) * 1.5).toFixed(2),
								"overtimeBillRate"
							);
							updateForm(parseFloat(form.billRate).toFixed(2), "billRate");
						}}
						onChange={e =>
							updateForm(parseFloat(e.currentTarget.value), "billRate")
						}
						error={errors.billRate}
					/>
					<Input
						type="number"
						label="Pay Rate"
						value={form.payRate}
						onBlur={() => {
							updateForm(
								(parseFloat(form.payRate) * 1.5).toFixed(2),
								"overtimePayRate"
							);
							updateForm(parseFloat(form.payRate).toFixed(2), "payRate");
						}}
						onChange={e =>
							updateForm(parseFloat(e.currentTarget.value), "payRate")
						}
						error={errors.payRate}
					/>
				</FormRow>
				<FormRow>
					<Input
						type="number"
						label="Overtime Bill Rate"
						value={form.overtimeBillRate}
						onBlur={() => {
							updateForm(
								parseFloat(form.overtimeBillRate).toFixed(2),
								"overtimeBillRate"
							);
						}}
						onChange={e =>
							updateForm(parseFloat(e.currentTarget.value), "overtimeBillRate")
						}
						error={errors.overtimeBillRate}
					/>
					<Input
						type="number"
						label="Overtime Pay Rate"
						value={form.overtimePayRate}
						onBlur={() => {
							updateForm(
								parseFloat(form.overtimePayRate).toFixed(2),
								"overtimePayRate"
							);
						}}
						onChange={e =>
							updateForm(parseFloat(e.currentTarget.value), "overtimePayRate")
						}
						error={errors.overtimePayRate}
					/>
				</FormRow>
				<FormRow>
					{employee.user && (
						<Button
							color={theme.NEGATIVE}
							onClick={() => onSubmit(form, "remove")}
						>
							Remove Employee
						</Button>
					)}
					<Button onClick={checkSubmit}>Save Employee</Button>
				</FormRow>
			</Main>
		</Modal>
	);
};
export default EmployeeModal;
