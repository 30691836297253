import React from "react";
import styled from "styled-components";
import { getTheme } from "Theme";
import Button from "./Button";
import Input from "./Input";
import Modal from "./Modal";
import { formatDollars } from "globalResources/helpers";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 4px 16px;
`;

const RateModal = ({ rate, setRate, onSave, onDelete }) => {
  if (!rate) return null;

  return (
    <Modal onClose={() => setRate(null)}>
      <Main>
        <Input
          label="Position"
          value={rate.position}
          onChange={(e) =>
            setRate((old) => ({ ...old, position: e.target.value }))
          }
        />
        <Row>
          <Input
            label="Pay Rate"
            type="currency"
            value={rate.payRate}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              setRate((old) => ({
                ...old,
                payRate: v,
              }));
            }}
            onBlur={() => {
              setRate((old) => ({
                ...old,
                payRate: formatDollars(parseFloat(old.payRate)),
                overtimePayRate: formatDollars(parseFloat(old.payRate) * 1.5),
              }));
            }}
          />
          <Input
            label="Bill Rate"
            type="currency"
            value={rate.billRate}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              setRate((old) => ({
                ...old,
                billRate: v,
              }));
            }}
            onBlur={() =>
              setRate((old) => ({
                ...old,
                billRate: formatDollars(parseFloat(old.billRate)),
                overtimeBillRate: formatDollars(parseFloat(old.billRate) * 1.5),
              }))
            }
          />
        </Row>
        <Row>
          <Input
            label="Overtime Pay Rate"
            type="currency"
            value={rate.overtimePayRate}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              setRate((old) => ({ ...old, overtimePayRate: v }));
            }}
            onBlur={() =>
              setRate((old) => ({
                ...old,
                overtimePayRate: formatDollars(parseFloat(old.overtimePayRate)),
              }))
            }
          />
          <Input
            label="Overtime Bill Rate"
            type="currency"
            value={rate.overtimeBillRate}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              setRate((old) => ({ ...old, overtimeBillRate: v }));
            }}
            onBlur={() =>
              setRate((old) => ({
                ...old,
                overtimeBillRate: formatDollars(
                  parseFloat(old.overtimeBillRate)
                ),
              }))
            }
          />
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Button onClick={onDelete} color={theme.NEGATIVE}>
            Delete
          </Button>
          <Button onClick={onSave}>Save</Button>
        </Row>
      </Main>
    </Modal>
  );
};
export default RateModal;
