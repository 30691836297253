import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Button, Error, Selector } from "globalComponents";
import { errorHandler } from "globalResources/util";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

import BugNoteInput from "./components/BugNoteInput";
import BugNote from "./components/BugNote.jsx";

import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";

import { getTheme } from "Theme";
import { Loader } from "semantic-ui-react";
import moment from "moment";
import { getStatusIcon, statusTags } from "./components/BugList";
import { useDispatch } from "react-redux";
import { addNotification } from "store/slices/uiSlice";
const theme = getTheme();

const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 68px);
`;

const StatusBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 5px;
`;

const Title = styled.h1`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  font-size: 28px;
  width: 50%;
  margin-top: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 5px 25px 5px 35px;
  padding: 0 10px;
  border: solid;
  border-radius: 15px;
  border-color: ${theme.SHADOW};
  background-color: ${theme.SECONDARY_COLOR};
  overflow-y: scroll;
  flex-wrap: nowrap;
  height: 100%;
`;

const DescriptionRow = styled.div`
  display: flex;
  border-radius: 0 0 15px 15px;
  padding: 15px;
  min-height: 40px;
  margin: 0;
  justify-content: center;
  background: ${theme.MANILLA};
  width: 80%;
  margin: 0 auto;
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: bolder;
`;

const BackButton = styled(Button)`
  width: 150px;
  right: 5vw;
  height: 40px;
`;
const DisableBtn = styled(Button)`
  align-self: flex-end;
  width: fit-content;
`;

const BugDetails = () => {
  const [bug, setBug] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const fetchBug = () => {
    setLoading(true);
    axios
      .get(`/bug/${id}`)
      .then(async (res) => {
        setBug(res.data);
        setError("");
      })
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        dispatch(addNotification({ message, type: "error" }));
        history.push("/bugs");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeStatus = (status) => {
    setLoading(true);
    axios
      .patch(`bug/${bug._id}/status`, { status })
      .then((res) => fetchBug())
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const changeLabel = (label) => {
    setLoading(true);
    axios
      .patch(`bug/${bug._id}/label`, { label })
      .then((res) => fetchBug())
      .catch(async (err) => {
        const { message } = await errorHandler(err);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchBug();
  }, [id]);

  const archiveBug = () => {
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API_URL}/bug/archive`, { id: bug._id })
      .then(() => fetchBug())
      .catch(async (err) => {
        const { message } = await errorHandler(err);

        setError(message);
      })
      .finally(() => setLoading(false));
  };

  if (!bug || loading)
    return <Loader active size="huge" margin="5% auto 5% auto" />;

  return (
    <Main>
      <StatusBar>
        <BackButton
          color={theme.BUTTON_COLOR_GREY}
          onClick={() =>
            history.push({ pathname: "/bugs", state: location?.state })
          }
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" /> Back to Bugs
        </BackButton>
        <Title>{bug.subject}</Title>
        <div>
          <Text>
            Status: {bug.status}{" "}
            <FontAwesomeIcon {...getStatusIcon(bug.status)} size="lg" />
          </Text>
          <Text>{moment(bug.createdAt).format("l")}</Text>
        </div>
      </StatusBar>
      <Error>{error}</Error>
      <Row style={{ gap: "5px", margin: "auto 35px auto auto" }}>
        <DisableBtn onClick={archiveBug} color={theme.BUTTON_COLOR_GREY}>
          {bug.isArchived ? "Unarchive Bug" : "Archive Bug"}
        </DisableBtn>
        <Selector
          width="200px"
          label="Status"
          value={bug.status}
          options={Object.values(statusTags).map((tag) => ({
            label: tag,
            value: tag,
          }))}
          onSelect={(v) => changeStatus(v)}
        />
        <Selector
          width="200px"
          label="Label"
          value={bug.label}
          options={[
            { label: "Client", value: "Client" },
            { label: "Internal", value: "Internal" },
            { label: "External", value: "External" },
          ]}
          onSelect={(v) => changeLabel(v)}
        />
      </Row>
      <Content>
        {bug.notes?.map((note, idx) => (
          <BugNote
            key={idx}
            note={
              idx === 0 ? { ...note, note: bug.description + note.note } : note
            }
            rightAlign={!note.isCustomer}
          />
        ))}
      </Content>
      <BugNoteInput fetchBug={fetchBug} />
    </Main>
  );
};

export default BugDetails;
