import React, {useEffect, useState} from "react";
import {getTheme} from "Theme";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import axios from "axios";
import {addNotification} from "store/slices/uiSlice";
import {useDispatch} from "react-redux";
import {errorHandler} from "globalResources/util";
import {
	Button,
	EditButton,
	InfiniteTable,
	Row,
	SelectorSearch,
} from "globalComponents";
import GroupEdit from "pages/admin/forms/GroupEdit";
import Notes from "globalComponents/Notes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {notifTags} from "globalComponents/Notification";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
const theme = getTheme();

const Wrapper = styled.div`
	display: flex;
	padding: 0px 5vw;
	gap: 12px;
`;
const Sidebar = styled.div`
	flex: 0 0 330px;
	border-radius: 15px;
	padding: 10px;
`;
const Title = styled.h1`
	margin-bottom: 16px;
`;
const SectionTitle = styled.h3`
	margin: 0px;
`;
const DescriptionWrap = styled.p`
	border-top: 2px solid ${theme.BORDER};
	padding: 8px 0px;
`;
const PermissionsList = styled.ul`
	list-style: none;
	margin: 0px;
	padding: 8px 0px 24px;
	border-top: 2px solid ${theme.BORDER};
`;
const ListItem = styled.li`
	font-weight: 700;
	padding-bottom: 8px;
`;
const PermissionDesc = styled.p`
	font-weight: normal;
	padding-left: 8px;
`;
const Content = styled.div`
	flex: 1;
`;
const BackBtn = styled(Button)`
	margin-left: auto;
	width: fit-content;
	display: block;
`;
const RemoveBtn = styled(Button)`
	padding: 8px 12px;
	width: initial;
`;
const SelectorWrap = styled.div`
	padding-bottom: 16px;
`;

const GroupDetails = () => {
	const location = useLocation();
	const {id} = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [groupInfo, setGroupInfo] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [userSearch, setUserSearch] = useState("");
	const [userOptions, setUserOptions] = useState([]);

	const getGroup = () => {
		if (!loading) setLoading(true);
		axios
			.get("/group", {params: {id}})
			.then(res => setGroupInfo(res.data))
			.catch(async e => {
				const {message} = await errorHandler(e);
				dispatch(addNotification({type: notifTags.error, message}));
				history.push("/groups");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchUsers = () => {
		axios
			.get(`/users`, {
				params: {search: userSearch, searchBy: "name", limit: 20},
			})
			.then(async res => {
				setUserOptions(
					res.data.users.map(user => ({
						label: `${user.firstName} ${user.lastName} (${user.accountType})`,
						value: JSON.stringify(user),
					}))
				);
			})
			.catch(async err => {
				const {message} = await errorHandler(err);
				dispatch(addNotification({type: notifTags.error, message}));
			});
	};

	const updateUsers = (users, successMsg) => {
		if (!loading) setLoading(true);
		axios
			.patch("/group", {
				id: id,
				users: users.map(user => user._id),
			})
			.then(() => {
				dispatch(
					addNotification({
						type: notifTags.success,
						message: successMsg,
					})
				);
				getGroup();
			})
			.catch(async e => {
				const {message} = errorHandler(e);
				dispatch(addNotification({type: notifTags.error, message}));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const addUser = user => {
		const newUserList = [...groupInfo.team, user];
		updateUsers(newUserList, "User added successfully");
	};
	const removeUser = user => {
		const newUserList = groupInfo.team.filter(
			teamUser => teamUser._id !== user._id
		);
		updateUsers(newUserList, "User removed successfully");
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			fetchUsers();
		}, 250);

		return () => clearTimeout(delayDebounceFn);
	}, [userSearch]);

	useEffect(() => {
		getGroup();
		fetchUsers();
	}, []);

	if (groupInfo) {
		const {name, description, permissions, notes, _id} = groupInfo.group;
		const headers = [
			{
				label: "Name",
				render: row => (
					<div>
						{row?.firstName} {row?.lastName}
					</div>
				),
			},
			{label: "Account Type", render: row => <div>{row?.accountType}</div>},
			{
				label: "",
				render: row => (
					<Row style={{justifyContent: "flex-end", paddingRight: "16px"}}>
						<RemoveBtn
							color={theme.ERROR}
							onClick={e => {
								e.stopPropagation();
								removeUser(row);
							}}
						>
							<FontAwesomeIcon icon={faXmark} color="white" size="xl" />
						</RemoveBtn>
					</Row>
				),
			},
		];

		return (
			<Wrapper>
				<Sidebar>
					<Title>{name}</Title>
					<Row>
						<SectionTitle>Description</SectionTitle>
						<EditButton onClick={() => setModalOpen(true)}>Edit</EditButton>
					</Row>
					<DescriptionWrap>{description}</DescriptionWrap>
					<SectionTitle>Permissions</SectionTitle>
					<PermissionsList>
						{permissions.map(perm => (
							<ListItem>
								{perm.name}
								<PermissionDesc>{perm.description}</PermissionDesc>
							</ListItem>
						))}
					</PermissionsList>
					<Notes notes={notes} type="group" id={_id} />
				</Sidebar>
				<Content>
					<BackBtn
						color={theme.BUTTON_COLOR_GREY}
						onClick={() =>
							history.push({pathname: "/groups", state: location?.state})
						}
					>
						Back to Groups
					</BackBtn>
					<SelectorWrap>
						<SelectorSearch
							preventFilter={true}
							width="300px"
							fillSearch={false}
							label="Add User"
							onSearch={s => {
								setUserSearch(s);
							}}
							onSelect={v => {
								const user = JSON.parse(v);
								addUser(user);
							}}
							options={userOptions.filter(
								g =>
									g.label !== "All" &&
									!groupInfo.team
										.map(t => t?._id)
										.includes(JSON.parse(g.value)?._id)
							)}
						/>
					</SelectorWrap>
					<InfiniteTable
						loading={loading}
						height="600px"
						data={groupInfo.team}
						headers={headers}
						onClick={row => history.push(`/user/${row._id}/details`)}
					/>
				</Content>
				{modalOpen && (
					<GroupEdit
						selected={groupInfo.group}
						onClose={() => {
							setModalOpen(null);
							getGroup();
						}}
					/>
				)}
			</Wrapper>
		);
	} else {
		return null;
	}
};

export default GroupDetails;
